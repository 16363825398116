import { styled, Box } from '@material-ui/core';
import Tooltip from 'components/popups/Tooltip';

import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import BlockIcon from '@material-ui/icons/Block';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import CreditCardIcon from '@material-ui/icons/CreditCard';

import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import MessageIcon from '@material-ui/icons/Message';

import mastercardLogo from '../../images/mastercard.svg';
import visaLogo from '../../images/visa-white.png';
import amexLogo from '../../images/amex.png';
import interacLogo from '../../images/interac.jpeg';

import DialpadIcon from '@material-ui/icons/Dialpad';


// ************** Payment Status ************** //

export function PaymentIcon({ status, message }) {
    switch (status) {
        case 0:
            return (
                <Tooltip color='grey' message={message || 'Incomplete'}>
                    <Incomplete fontSize='small' />
                </Tooltip>
            )
        case 1000:
        case 1001:
            return (
                <Tooltip color='green' message={message || 'Approved'}>
                    <Check fontSize='small' />
                </Tooltip>
            )
        case 2000:
            return (
                <Tooltip color='red' message={message ? `Declined: ${message}` : 'Declined'}>
                    <Error fontSize='small' />
                </Tooltip>
            )
        case 2001:
            return (
                <Tooltip color='orange' message='Cancelled'>
                    <Cancelled fontSize='small' />
                </Tooltip>
            )
        case 3000:
            return (
                <Tooltip color='orange' message={message || 'Refunded'}>
                    <Refund fontSize='small' />
                </Tooltip>
            )
        default:
            return (
                <Tooltip color='red' message={message ? `Declined: ${message}` : 'Declined'}>
                    <Error fontSize='small' />
                </Tooltip>
            )
    }
}


// ************** Discount Code ************** //

export function DiscountIcon({ code, compedBy }) {
    if (code) {
        return (
            <Tooltip color='grey' message={code === 'sys_discount' ? `Order comped by ${compedBy}` : `Discount code: ${code}`}>
                {code === 'sys_discount'
                    ? <Comped fontSize='small' />
                    : <Discount fontSize='small' />
                }
            </Tooltip>
        )
    } else {
        return null;
    }
}


// ************** Comment Code ************** //

export function CommentIcon({ count }) {
    if (count) {
        return (
            <Tooltip color='grey' message='This order has comments'>
                <Comment fontSize='small' />
            </Tooltip>
        )
    } else {
        return null;
    }
}


// ************** Custom Order Icon ************** //

export function CustomOrderIcon() {
    return (
        <Tooltip color='grey' message='Custom charge'>
            <CustomOrder fontSize='small' />
        </Tooltip>
    )
}

// ************** Payment Method Icon ************** //

export function PaymentMethodIcon({ type, brand }) {

    if (type === 1000) { // Unknown Card
        return (
            <Tooltip color='grey' message='Unknown payment method'>
                <Card fontSize='small' />
            </Tooltip>
        )
    }
    else if (type === 1001) { // Interac Debit
        return (
            <Tooltip color='grey' message='Debit card'>
                <Interac />
            </Tooltip>
        )
    }
    else if (type === 1002) { // Credit Card
        switch (brand) {
            case 'visa':
                return (
                    <Tooltip color='grey' message='Visa'>
                        <Visa />
                    </Tooltip>
                )
            case 'mastercard':
                return (
                    <Tooltip color='grey' message='Mastercard'>
                        <Mastercard />
                    </Tooltip>
                )
            case 'amex':
                return (
                    <Tooltip color='grey' message='American Express'>
                        <Amex />
                    </Tooltip>
                )
            default:
                return (
                    <Tooltip color='grey' message='Other credit card'>
                        <Card fontSize='small' />
                    </Tooltip>
                )
        }   
    }
    else if (type === 2000) { // Cash
        return (
            <Tooltip color='grey' message='Cash'>
                <Cash fontSize='small' />
            </Tooltip>
        )
    }
    else if (type === 3000) { // Free
        return (
            <Tooltip color='grey' message='Free order'>
                <Comped fontSize='small' />
            </Tooltip>
            )
    }
    else { // Default - Unknown
        return <Box />
    }
}


// ************** Stripe Connect Status ************** //

export function StripeConnectStatus({ connect_id, charges_enabled, payouts_enabled, onboarding_complete, requirements_due }) {
    // These flags can either be Booleans (true/false) or Numbers (0/1)

    if (connect_id) {
        // Using connect
        if (charges_enabled && payouts_enabled && onboarding_complete && !requirements_due) {
            return (
                <Tooltip color='grey' message='Connect is enabled'>
                    <ConnectCheck fontSize='small' />
                </Tooltip>
            )
        } else if (!onboarding_complete) {
            return (
                <Tooltip color='grey' message='This account needs to be onboarded'>
                    <ConnectWarning fontSize='small' />
                </Tooltip>
            )    
        } else if (requirements_due) {
            return (
                <Tooltip color='grey' message='This account needs to submit more info to Stripe'>
                    <ConnectWarning fontSize='small' />
                </Tooltip>
            )    
        } else {
            return (
                <Tooltip color='grey' message='There is a problem with this connected account. Payments and payouts may be affected.'>
                    <ConnectError fontSize='small' />
                </Tooltip>
            )
        }
    } else {
        // Legacy payment system
        return (
            <Tooltip color='grey' message='This host does not use Stripe Connect'>
                <ConnectDisabled fontSize='small' />
            </Tooltip>
        )
    }
}


const Check = styled(CheckCircleOutlinedIcon)(({ theme }) => ({
    color: theme.palette.success.main
}));

const Refund = styled(RotateLeftIcon)(({ theme }) => ({
    color: theme.palette.warning.main
}));

const Error = styled(ErrorOutlineOutlinedIcon)(({ theme }) => ({
    color: theme.palette.error.main
}));

const Cancelled = styled(BlockIcon)(({ theme }) => ({
    color: theme.palette.warning.main
}));

const Incomplete = styled(AccessTimeIcon)(({ theme }) => ({
    color: theme.palette.grey[600]
}));

const Discount = styled(LocalOfferIcon)(({ theme }) => ({
    color: theme.palette.grey[500],
    marginRight: theme.spacing(0.5)
}));

const Comped = styled(CardGiftcardIcon)(({ theme }) => ({
    color: theme.palette.grey[500],
    marginRight: theme.spacing(0.5)
}));

const Comment = styled(MessageIcon)(({ theme }) => ({
    color: theme.palette.grey[500],
    marginRight: theme.spacing(0.5)
}));

const Card = styled(CreditCardIcon)(({ theme }) => ({
    color: theme.palette.grey[500],
    marginRight: theme.spacing(0.5)
}));

const Mastercard = styled('img')(({ theme }) => ({
    height: 18,
    width: 18,
    objectFit: 'contain',
    backgroundColor: '#141413'
}));
Mastercard.defaultProps = { src: mastercardLogo }

const Visa = styled('img')(({ theme }) => ({
    height: 18,
    width: 18,
    objectFit: 'contain',
    backgroundColor: '#1434CB'
}));
Visa.defaultProps = { src: visaLogo }

const Amex = styled('img')(({ theme }) => ({
    height: 18,
    width: 18,
    objectFit: 'contain'
}));
Amex.defaultProps = { src: amexLogo }

const Interac = styled('img')(({ theme }) => ({
    height: 18,
    width: 18,
    objectFit: 'contain'
}));
Interac.defaultProps = { src: interacLogo }

const Cash = styled(LocalAtmIcon)(({ theme }) => ({
    color: theme.palette.grey[500],
    marginRight: theme.spacing(0.5)
}));

const ConnectCheck = styled(CheckCircleOutlinedIcon)(({ theme }) => ({
    color: theme.palette.success.main
}));

const ConnectWarning = styled(ReportProblemOutlinedIcon)(({ theme }) => ({
    color: theme.palette.warning.main
}));

const ConnectError = styled(ErrorOutlineOutlinedIcon)(({ theme }) => ({
    color: theme.palette.error.main
}));

const ConnectDisabled = styled(BlockIcon)(({ theme }) => ({
    color: theme.palette.grey[600]
}));

const CustomOrder = styled(DialpadIcon)(({ theme }) => ({
    color: theme.palette.grey[600],
    marginRight: theme.spacing(0.5)
}));