import React, { useState } from 'react';

// Material UI
import { styled, Grid, Box, Typography, FormControlLabel, Switch, IconButton } from '@material-ui/core';
import { RadioGroup, Radio, Checkbox } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// Components
import { DeleteButton, CancelButton, SubmitButton, Section, Header, Content } from 'components/ui/forms';
import { useTextInput, useToggle } from 'hooks/forms';
import { TextField, TextArea } from 'components/ui/inputs';
import { ActionButton } from 'components/ui/buttons';



export default function QuestionForm(props) {
    const { allowDelete, defaultValues, types, createdAtMsg, updatedAtMsg, onCancel, onSubmit, onDelete, saving } = props;
    
    // *** Question Fields *** //
    
    const description       = useTextInput({ defaultValue: defaultValues.description || '' });
    const [qType, setQType] = useState(defaultValues.type || types[0]);
    const reportDescription = useTextInput({ defaultValue: defaultValues.reportDescription || '' });
    const required          = useToggle({ defaultValue: defaultValues.required ?? false });
    
    const [visibility, setVisibility] = useState(defaultValues.visibility || ''); // visible, limited
    const [products, setProducts] = useState(defaultValues.products);
    const [productsError, setProductsError] = useState(null);
    
    // *** Options Fields *** //

    const [options, setOptions] = useState(defaultValues.options || []);
    const [optionsError, setOptionsError] = useState(null);


    const handleTypeChange = (e) => {
        setQType(
            types.find(t => String(t.value) === e.target.value)
        )
    };

    const handleOptionChange = (values) => {
        setOptions(values);
        setOptionsError(null);
    };

    const handleVisiblityChange = (event) => {
        setVisibility(event.target.value);
        setProductsError(null);
    };

    const handleProductChange = (value) => {
        setProducts(value);
        setProductsError(null);
    };


    const handleSubmit = () => {
        let formError = false;

        if (description.value.trim() === '') {
            description.setError('This field cannot be empty');
            formError = true;
        }

        if (qType.value === '') {
            description.setError('You must pick a question type');
            formError = true;
        }

        // Validate Options if the question type supports

        if (qType.hasOptions === true) {
            if (options.length === 0) {
                setOptionsError('You must create at least one option');
                formError = true
            } else {
                // Check if any of the options provided are empty
                let optionsError = false;
    
                const newOptions = options.map(o => {
                    if (o.description.trim() === '') {
                        optionsError = true;
                        return ({ ...o, error: 'This option cannot be empty' });
                    } else {
                        return o;
                    }
                })
    
                if (optionsError) {
                    setOptions(newOptions);
                    formError = true;
                }
            }
        }

        
        let questionProducts = null;

        if (visibility === 'limited') {
            questionProducts = products.filter(p => p.checked === true).map(p => p.id);

            if (questionProducts.length === 0) {
                setProductsError('You must select at least one ticket from the list. If you want this question to be visible to all customer, please select the "Ask every time" option.')
                formError = true;
            }
        }


        if (formError) return;

        onSubmit({
            description: description.value.trim(),
            type: qType.value,
            reportDescription: reportDescription.value.trim(),
            required: required.checked,
            options: qType.hasOptions ? options : [],
            products: questionProducts
        });
    }

    return (
        <Box display='flex' justifyContent='center' width='100%'>
            <Root>

                <Grid container spacing={4}>

                    <Grid item xs={12} md={6}>
                        <Section>
                            <Header>Question Type</Header>
                            <Typography>Choose the type of question</Typography>
                            <Content>
                                <TextField value={qType.value} onChange={handleTypeChange} select SelectProps={{ native: true }}>
                                    <option value='' disabled>Question Type</option>
                                    {types.map(t => (
                                        <option value={t.value}>{t.text}</option>
                                    ))}
                                </TextField>
                            </Content>
                        </Section>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Section>
                            <Header>Label</Header>
                            <Typography>Give this question a label that you will recognize. It will not be visible to your customers.</Typography>
                            <Content>
                                <TextField {...reportDescription.formProps} label='Question Name' />
                            </Content>
                        </Section>
                    </Grid>
                    <Grid item xs={12}>
                        <QuestionPrompt
                            formProps={description.formProps}
                            qType={qType.value}
                        />
                    </Grid>

                    {(qType.hasOptions === true && options !== null) && (
                        <Grid item xs={12}>
                            <Options
                                options={options}
                                error={optionsError}
                                type={qType.value}
                                onOptionChange={handleOptionChange}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Section>
                            <Header>Question Visibility</Header>
                            {productsError
                                ? <ErrorText>{productsError}</ErrorText>
                                : <Typography>You can ask this question every time, or only when certain tickets are in the customer's cart</Typography>
                            }
                            <Content>
                                <RadioGroup aria-label="Visibility" value={visibility} onChange={handleVisiblityChange}>
                                    <FormControlLabel value="visible" control={<Radio />} label={<Bold>Ask every time</Bold>} />
                                    <FormControlLabel value="limited" control={<Radio />} label={<Typography><Bold>Ask by ticket</Bold> - only ask this question if the customer orders one of the following tickets</Typography>} />
                                </RadioGroup>
                                {visibility === 'limited' && (
                                    <Products
                                        products={products}
                                        onProductChange={handleProductChange}
                                        error={productsError}
                                    />
                                )}
                            </Content>
                        </Section>
                    </Grid>

                    <Grid item xs={12}>
                        <Section>
                            <Header>Required</Header>
                            <Typography>If this question is required, attendees will not be able to purchase their tickets without answering the question. Only use this when necessary.</Typography>
                            <Content>
                                <FormControlLabel
                                    control={<Switch {...required.formProps} />}
                                    label="Required"
                                />
                            </Content>
                        </Section>
                    </Grid>
                </Grid>

                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='flex-end' mb={4} mt={4}>
                            {allowDelete && (
                                <DeleteButton onClick={onDelete} disabled={saving}>Delete</DeleteButton>
                            )}
                            <CancelButton onClick={onCancel} disabled={saving}>Cancel</CancelButton>
                            <SubmitButton onClick={handleSubmit} disabled={saving}>Save</SubmitButton>
                        </Box>
                    </Grid>
                </Grid>

                {createdAtMsg && (
                    <Typography align='right' variant='caption'>{createdAtMsg}</Typography>
                )}
                
                {updatedAtMsg && (
                    <Typography align='right' variant='caption'>{updatedAtMsg}</Typography>
                )}

            </Root>
        </Box>
    )
}


function QuestionPrompt(props) {
    const { formProps, qType } = props;

    let header = 'Your Question';
    let subtitle = 'Ask your attendees a question';
    let multiline = false;
    let label = 'Prompt';

    if (qType === 10) {
        header = 'Terms and Conditions';
        subtitle = "Enter your T&C here. By default, customers will be allowed to purchase their tickets without accepting these terms. To prevent this, make this question required below.";
        multiline = true;
        label = 'Terms';
    }

    return (
        <Section>
            <Header>{header}</Header>
            <Typography>{subtitle}</Typography>
            <Content>
                {multiline
                    ? <TextArea  {...formProps} label={label} />
                    : <TextField {...formProps} label={label} />
                }
            </Content>
        </Section>
    )
}


function Options(props) {
    const { options, error, type, onOptionChange } = props;

    const displayOptions = props.options.filter(o => o.action !== 'DELETE'); // Don't show deleted options

    let header = '';
    let description = '';

    switch (type) {
        case 3:
            header = 'Single Choice Options';
            description = 'Attendees will select one of the following options';
            break;
        case 4:
            header = 'Multiple Choice Options';
            description = 'Attendees will select any options below that apply';
            break;
        case 5:
            header = 'Dropdown Options';
            description = 'Attendees will select one of these options from a dropdown list';
            break;
    
        default:
            break;
    }


    const handleOptionChange = (tempId, value) => {
        const newOptions = [];

        for (const opt of options) {
            if (opt.tempId === tempId) {
                newOptions.push({ ...opt, description: value, error: null });
            } else {
                newOptions.push(opt);
            }
        }

        onOptionChange(newOptions);
    };

    const handleOptionDelete = (tempId) => {
        const newOptions = [];

        for (const opt of options) {
            if (opt.tempId !== tempId) {
                newOptions.push(opt);
            } else if (opt.id) {
                // This option already exists in the DB. We need to push a DELETE action to the API
                newOptions.push({ ...opt, action: 'DELETE' });
            }
        }
        
        onOptionChange(newOptions);
    };

    const addOption = () => {
        const newOptions = Array.from(options);
        
        const tempId = (Math.random() + '-' + Date.now()).slice(2);

        newOptions.push({
            id: null,
            description: '',
            tempId: tempId,
            error: null,
            action: 'CREATE'
        });

        onOptionChange(newOptions);
    };


    return (
        <Section>
            <Header>{header}</Header>
            {error
                ? <ErrorText>{error}</ErrorText>
                : <Typography>{description}</Typography>
            }
            <Content>
                {displayOptions.map(o => (
                    <Box display={'flex'} mb={2}>
                        <TextField
                            value={o.description}
                            onChange={(e) => handleOptionChange(o.tempId, e.target.value)}
                            error={Boolean(o.error)}
                            helperText={o.error || undefined}
                        />
                        <IconButton size='medium' onClick={() => handleOptionDelete(o.tempId)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                ))}
                <ActionButton onClick={addOption}>+ Add option</ActionButton>
            </Content>
        </Section>
    )
}


function Products(props) {
    const { products, onProductChange } = props;

    const handleChange = (event) => {
        const id = Number(event.target.id);
        const checked = event.target.checked;

        onProductChange(
            products.map(p => {
                if (p.id === id) {
                    return ({ ...p, checked: checked });
                } else {
                    return ({ ...p });
                }
            })
        )
    };

    return (
        <Box mt={2} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
            {products.map(p => (
                <FormControlLabel
                    key={p.id}
                    label={p.name}
                    control={
                        <Checkbox
                            id={p.id}
                            checked={p.checked}
                            onChange={handleChange}
                        />
                    }
                />
            ))}
        </Box>
    )
}


const Root = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(2),
    marginTop: 5,
    maxWidth: theme.breakpoints.values.md,
}));

const ErrorText = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main,
    fontWeight: 'bold'
}));

const Bold = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    display: 'inline'
}));