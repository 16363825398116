import React, { useEffect, useState } from 'react';

// Material-UI
import { styled, Typography, Box, Button, CircularProgress } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Card as _Card, CardHeader as _CardHeader, CardContent } from '@material-ui/core';

// Components
import { ActionButton } from 'components/ui/buttons';

// Utility
import fetchExpresso from 'utility/fetchExpresso';
import { useNotification } from 'context/notification';
import ReactGA from 'react-ga'
import InfoTooltip from 'components/popups/InfoTooltip';


export default function ScannerPin(props) {
    const [state, setState] = useState({ pin: null, loading: true, error: false, dialogOpen: false, generatingPin: false });

    const { notify } = useNotification();

    const hostId = props.hostId;


    function handleClick() {
        setState({ ...state, dialogOpen: true });
    }

    function handleClose() {
        setState({ ...state, dialogOpen: false });
    }


    // Get current scanner pin
    useEffect(() => {

        setState(s => ({ ...s, loading: true, error: false }));

        fetchExpresso(`/apiv2/hosts/${hostId}/scanner_pin`)

            .then(async (res) => {
                if (res.status === 200) {
                    const data = await res.json();
                    setState(s => ({ ...s, pin: data.scannerPin, loading: false }));
                } else {
                    setState(s => ({ ...s, loading: false, error: true }));
                }
            })

            .catch(() => {
                setState(s => ({ ...s, loading: false, error: true }));
            })

    }, [hostId]);

    // Generate new scanner pin then update display
    function handleConfirm() {

        setState({ ...state, dialogOpen: false, generatingPin: true });

        fetchExpresso(`/apiv2/hosts/${hostId}/scanner_pin`, { method: 'PUT' })

            .then(async (res) => {
                if (res.status === 200) {
                    const data = await res.json();
                    notify.success('Generated new PIN');
                    setState({ ...state, dialogOpen: false, pin: data.scannerPin, generatingPin: false });
                    ReactGA.event({ category: "Event", action: 'Generate PIN ', label: `${hostId}` });
                } else {
                    notify.error('There was a problem getting your new PIN');
                    setState({ ...state, dialogOpen: false, generatingPin: false });
                }
            })

            .catch(() => {
                notify.error('There was a problem getting your new PIN');
                setState({ ...state, dialogOpen: false, generatingPin: false });    
            })

    }


    // RETURN: Loading
    if (state.loading) {
        return (
            <Card>
                <CardHeader
                    subheader='FD+ App Login PIN'
                    action={<InfoTooltip message='Give this PIN to anyone responsible for checking tickets at your events. They will need this to sign into the FrontDoor+ App.' />}
                />
                <Box flexGrow={1}>
                    <Box display='flex' height='100%' width='100%' alignItems='center' justifyContent='center'>
                        <CircularProgress />
                    </Box>
                </Box>
            </Card>
        );
    }

    // RETURN: Error
    if (state.error) {
        return (
            <Card>
                <CardHeader
                    subheader='FD+ App Login PIN'
                    action={<InfoTooltip message='Give this PIN to anyone responsible for checking tickets at your events. They will need this to sign into the FrontDoor+ App.' />}
                />
                <Box flexGrow={1}>
                    <Box display='flex' height='100%' width='100%' alignItems='center' justifyContent='center'>
                        <p>There was a problem getting your PIN</p>
                    </Box>
                </Box>
            </Card>
        );
    }

    return (

        <Card>

            <CardHeader
                subheader='FD+ App Login PIN'
                action={<InfoTooltip message='Give this PIN to anyone responsible for checking tickets at your events. They will need this to sign into the FrontDoor+ App.' />}
            />

            <CardContent>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                    <Pin variant='h4' gutterBottom>{state.pin || ''}</Pin>
                    <ActionButton size='small' style={{ marginBottom: '11.9px'}} onClick={handleClick}>Reset</ActionButton>
                </Box>
            </CardContent>
            
            
            <Dialog open={state.dialogOpen} onClose={handleClose}>
                <DialogTitle>Are you sure you want to generate a new PIN?</DialogTitle>
                <DialogContent>You will lose access to your current PIN</DialogContent>
                <DialogActions>
                    <Button color='secondary' onClick={handleClose}>Cancel</Button>
                    <Button color='secondary' onClick={handleConfirm}>Yes</Button>
                </DialogActions>
            </Dialog>
        </Card>
    )
}


const Card = styled(_Card)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
}));

const CardHeader = styled(_CardHeader)({
    '& .MuiCardHeader-action': {
        marginTop: 0,
        marginRight: 0
    },
});

const Pin = styled(Typography)({
    fontWeight: 'bold'
});
Pin.defaultProps = { variant: 'h4', gutterBottom: true };