import React, { useState } from 'react';
import { Grid, TextField, Card, CardHeader, CardContent, CardActions } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

// Components
import { ActionButton } from 'components/ui/buttons';

// Utility
import { useNotification } from 'context/notification';
import fetchExpresso from 'utility/fetchExpresso';
import { downloadFile } from 'utility/browser';
import moment from 'moment-timezone';


export default function OrderDownload() {
    const [state, setState] = useState({
        startDate: moment().startOf('year'),
        endDate: new Date(),
        preset: 'yearToDate'
    });

    const [loading, setLoading] = useState(false);


    // Utility
    const { notify } = useNotification();

    const handlePresetChange = (e) => {
        switch (e.target.value) {

            case 'yearToDate':
                setState({ preset: e.target.value, startDate: moment().startOf('year'), endDate: Date.now() });
                break;

            case 'thisMonth':
                setState({ preset: e.target.value, startDate: moment().startOf('month'), endDate: Date.now() });
                break;

            case 'monthsAgo1':
                setState({ preset: e.target.value, startDate: moment().subtract(1, 'month').startOf('month'), endDate: moment().subtract(1, 'month').endOf('month')})
                break;

            case 'monthsAgo2':
                setState({ preset: e.target.value, startDate: moment().subtract(2, 'month').startOf('month'), endDate: moment().subtract(2, 'month').endOf('month')})
                break;

            case 'monthsAgo3':
                setState({ preset: e.target.value, startDate: moment().subtract(3, 'month').startOf('month'), endDate: moment().subtract(3, 'month').endOf('month')})
                break;

            case 'monthsAgo4':
                setState({ preset: e.target.value, startDate: moment().subtract(4, 'month').startOf('month'), endDate: moment().subtract(4, 'month').endOf('month')})
                break;

            case 'custom':
                setState({ preset: e.target.value, startDate: moment().subtract(7, 'days'), endDate: Date.now() });
                break;

            default:
                setState({ ...state, preset: e.target.value });
                break;
        }
    }

    const handleStartDateChange = (value) => setState({ ...state, preset: 'custom', startDate: value });
    const handleEndDateChange = (value) => setState({ ...state, preset: 'custom', endDate: value });

    const handleDownload = () => {
        const { startDate, endDate } = state;

        if (startDate > endDate) {
            setState({ ...state, endDateMessage: 'This date must be later than the start date' });
            return;
        }

        setLoading(true);

        fetchExpresso('/admin/reports/full_report', {
            method: 'POST',
            body: {
                startDate: state.startDate,
                endDate: state.endDate
            }
        })
            .then(response => {
                switch (response.status) {
                    case 200:
                        response.blob()
                            .then(async blob => {
                                await downloadFile(`FrontDoorPlus-Orders-${moment().format('YYYY-MM-DD')}.xlsx`, blob);
                            })
                            .catch(() => {
                                notify.error('There was a problem downloading your orders');
                            });
                        break;

                    case 204:
                        notify.warning('No orders found');
                        break;

                    default:
                        notify.error('There was a problem downloading your orders');
                        break;
                }
            })
            .catch(() => {
                notify.error('There was a problem downloading your orders');
            })
            .finally(() => setLoading(false))
    }

    // Date validation
    const { startDate, endDate } = state;
    let startDateMessage = '';
    let endDateMessage = '';

    if (moment(startDate).isValid() === false) {
        startDateMessage = 'Invalid Date'
    }
    else if (moment(startDate).isAfter(Date.now(), 'day')) {
        startDateMessage = 'This date cannot be later than today'
    }

    if (moment(endDate).isValid() === false) {
        endDateMessage = 'Invalid Date'
    }
    else if (moment(startDate).isValid() && moment(startDate).isAfter(endDate, 'day')) {
        endDateMessage = 'This date must be later than the start date'
    }

    const startDateError = startDateMessage ? true : false;
    const endDateError = endDateMessage ? true : false;

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>

            <Card>

                <CardHeader title='Download Orders' />

                <CardContent>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField fullWidth select value={state.preset} onChange={handlePresetChange} variant='outlined' label='Range' SelectProps={{ native: true }}>
                                <option value='yearToDate'>Year to Date</option>
                                <option value='thisMonth'>This Month</option>
                                <option value='monthsAgo1'>{moment().subtract(1, 'month').format('MMM YYYY')}</option>
                                <option value='monthsAgo2'>{moment().subtract(2, 'month').format('MMM YYYY')}</option>
                                <option value='monthsAgo3'>{moment().subtract(3, 'month').format('MMM YYYY')}</option>
                                <option value='monthsAgo4'>{moment().subtract(4, 'month').format('MMM YYYY')}</option>
                                <option value='custom'>Custom</option>
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <KeyboardDatePicker fullWidth label='Start' value={startDate} onChange={handleStartDateChange} format='yyyy-MM-dd' inputVariant='outlined' helperText={startDateMessage} error={startDateError} />
                        </Grid>
                        <Grid item xs={12}>
                            <KeyboardDatePicker fullWidth label='End' value={endDate} onChange={handleEndDateChange} format='yyyy-MM-dd' inputVariant='outlined' helperText={endDateMessage} error={endDateError} />
                        </Grid>
                    </Grid>

                </CardContent>

                <CardActions>

                    <ActionButton onClick={handleDownload} disabled={loading || startDateError || endDateError}>
                        Download
                    </ActionButton>

                </CardActions>

            </Card>

        </MuiPickersUtilsProvider>
    )
}