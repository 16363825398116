import React from 'react';

// Material UI
import { styled, Box, AppBar, Tabs, Tab, Toolbar } from '@material-ui/core';
import { useRouteMatch, useHistory, useParams } from 'react-router-dom';


/**
 * Mobile Only
 * 
 * This is the top tab menu that displays when the user is viewing an event
 * Handles all navigation within an event
 * 
 * See EventDrawer.js for the desktop version of this menu
 */

export default function EventTabs() {

    const history = useHistory();
    const { id }  = useParams();

    const path = '/events/' + id;

    const dashboard    = useRouteMatch('/events/:id/');
    const details      = useRouteMatch('/events/:id/details');
    const checkout     = useRouteMatch('/events/:id/checkout');
    const checkoutForm = useRouteMatch('/events/:id/checkout-form');
    const tickets      = useRouteMatch('/events/:id/tickets');
    const discounts    = useRouteMatch('/events/:id/discounts');
    const publish      = useRouteMatch('/events/:id/publish');
    const orders       = useRouteMatch('/events/:id/orders');
    const onsite       = useRouteMatch('/events/:id/onsite');
    const payouts      = useRouteMatch('/events/:id/payouts');
    const registration = useRouteMatch('/events/:id/registration');
    const downloads    = useRouteMatch('/events/:id/downloads');
    const promote      = useRouteMatch('/events/:id/promote');

    // Default to dashboard. '/events/:id/' wont match any of the urls above, but should display the dashboard
    let currentPage = 'dashboard';
    
    if (dashboard)    currentPage = '/';
    if (details)      currentPage = '/details';
    if (checkout)     currentPage = '/checkout';
    if (checkoutForm) currentPage = '/checkout-form';
    if (tickets)      currentPage = '/tickets';
    if (discounts)    currentPage = '/discounts';
    if (publish)      currentPage = '/publish';
    if (orders)       currentPage = '/orders';
    if (onsite)       currentPage = '/onsite';
    if (payouts)      currentPage = '/payouts';
    if (registration) currentPage = '/registration';
    if (downloads)    currentPage = '/downloads';
    if (promote)      currentPage = '/promote';


    const handleNavigation = (e, newValue) => {
        if (newValue !== currentPage) {
            history.push(`${path}${newValue}`);
        }
    };
    
    // The TabMenu does not show scroll bars at the xs breakpoint or below. This is intended by the MUI team
    // On mobile, the tab menu can be scrolled by swiping left/right
    // https://github.com/mui/material-ui/issues/22598
    return (
        <Box>
            <Box position='fixed' zIndex={2} displayPrint='none' style={{ left: 0, right: 0, top:0 }}>
                <Toolbar />
                <Box displayPrint='none'>
                    <AppBar position="static">
                        <TabMenu value={currentPage} variant='scrollable' scrollButtons='auto' onChange={handleNavigation} aria-label="simple TabMenu example">
                            <Tab value="/"              label="Dashboard"          {...a11yProps('dashboard')}    />
                            <Tab value="/details"       label="Event Details"      {...a11yProps('details')}      />
                            <Tab value="/tickets"       label="Tickets"            {...a11yProps('tickets')}      />
                            <Tab value="/discounts"     label="Discounts"          {...a11yProps('discounts')}    />
                            <Tab value="/checkout"      label="Customize Checkout" {...a11yProps('checkout')}     />
                            <Tab value="/checkout-form" label="Checkout Form"      {...a11yProps('checkout')}     />
                            <Tab value="/publish"       label="Review & Publish"   {...a11yProps('publish')}      />
                            <Tab value="/payouts"       label="Sales Summary"      {...a11yProps('payouts')}      />
                            <Tab value="/orders"        label="Online Orders"      {...a11yProps('orders')}       />
                            <Tab value="/onsite"        label="In-Person Orders"   {...a11yProps('onsite')}       />
                            <Tab value="/registration"  label="Checkout Form"      {...a11yProps('registration')} />
                            <Tab value="/downloads"     label="Reports"            {...a11yProps('downloads')}    />
                            <Tab value="/promote"       label="Promote"            {...a11yProps('promote')}      />
                        </TabMenu>
                    </AppBar>
                </Box>    
            </Box>
            <Toolbar />
        </Box>

    )
}


function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}


const TabMenu = styled(Tabs)(({ theme }) => ({
    flexGrow: 1,
    width: '100%'
}));