import React, { createContext, useContext, useState } from 'react';

export const NotificationContext = createContext();

export function useNotification() {
  return useContext(NotificationContext);
}

export function NotificationProvider(props) {
    // State
    const [notification, setNotification] = useState({ open: false, message: '', severity: null });


    // Open Notification
    const notify = {
        info    : (message) => setNotification({ open: true, message, severity: 'info'    }),
        warning : (message) => setNotification({ open: true, message, severity: 'warning' }),
        error   : (message) => setNotification({ open: true, message, severity: 'error'   }),
        success : (message) => setNotification({ open: true, message, severity: 'success' }),
    }


    // Close Notification
    const closeNotification   = () => setNotification({ open: false, message: '', severity: null });


    return (
        <NotificationContext.Provider value={{
            notification,
            notify,
            closeNotification
        }}>
            {props.children}
        </NotificationContext.Provider>
    )
}