import React, { useState, useEffect } from 'react';

// Material UI
import { styled } from '@material-ui/core/styles';
import { Box, Typography, CircularProgress, Grid, Card, CardHeader, CardMedia, CardActions, CardContent, Avatar } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';

// Components
import { ControlBar, ControlBarButton, ControlBarHeader, Page } from 'components/ui/layout';
import { ActionButton } from 'components/ui/buttons';
import FullScreenModal from 'components/popups/FullScreenModal';
import EventCreator from 'components/forms/EventCreator';
import EventDuplicator from 'components/forms/EventDuplicator';
import Tooltip from 'components/popups/Tooltip';

// Utility
import { useAuth } from 'context/auth';
import ReactGA from 'react-ga'
import fetchExpresso from 'utility/fetchExpresso';
import { useNotification } from 'context/notification';
import moment from 'moment-timezone';
import { abbreviateNumber } from 'utility/numbers';


export default function Events(props) {
    // State
    const [state, setState] = useState({
        modalOpen: false, // Opens the modal. Used for creating, editing, and copying events
        events: [],
        status: 'loading', // loading, error, success
        copyId: null, // ID of event being copied
        refreshCount: 0 // Increment to refresh events
    });


    // Hooks
    const { auth } = useAuth();
    const { notify } = useNotification();

    const hostId = auth.hostId;


    // Load/Reload list of events
    useEffect(() => {
        fetchExpresso(`/apiv2/events/?host=${hostId}`)
            .then(res => {
                if (res.status !== 200) throw new Error();
                return res.json();
            })
            .then(data => setState((state) => ({ ...state, events: data, status: 'success' })))
            .catch(() => {
                notify.error('Unable to find events. Try refreshing the page.');
                setState((state) => ({ ...state, status: 'error' }))
            })
            // eslint-disable-next-line
    }, [hostId, state.refreshCount]);


    // State Functions
    const handleModalOpen = () => {
        setState({ ...state, modalOpen: true });
    }

    const handleModalClose = () => {
        setState({ ...state, modalOpen: false, copyId: null });
    }

    const handleEventSelect = (id) => {
        props.history.push(`/events/${id}/`)
        ReactGA.event({ category: "Events", action: 'View Event', label: `${hostId}` });
    }
    
    const handleEventCopy = (id) => {
        setState(state => ({ ...state, modalOpen: true, copyId: id }));
    };

    const handleCreate = (eventId) => {
        props.history.push(`/events/${eventId}/`);
        ReactGA.event({ category: "Events", action: 'Create Event', label: `${hostId}` });
    }
    
    const handleCopy = () => {
        setState(state => ({ ...state, modalOpen: false, copyId: null, refreshCount: state.refreshCount + 1 }));
        ReactGA.event({ category: "Events", action: 'Copy Event', label: `${hostId}` });
    }


    let modalHeader = 'Create Event';

    if (state.copyId) modalHeader = 'Copy Event';


    if (state.status === 'loading') {
        return (
            <Page>
                <Status>
                    <CircularProgress disableShrink />
                </Status>
            </Page>
        )
    }

    if (state.status === 'error' || Array.isArray(state.events) === false) {
        return (
            <Page>
                <Status>
                    <Typography>There was a problem loading events</Typography>
                </Status>
            </Page>
        )
    }

    if (state.events.length === 0) {
        // No events have been created for this host. Return early.
        // Make sure to also render the EventCreator modal here so hosts can still create their first event
        return (
            <Page>
                <ControlBar>
                    <ControlBarHeader>Upcoming</ControlBarHeader>
                    <ControlBarButton onClick={handleModalOpen}>New Event</ControlBarButton>
                </ControlBar>
                <Status>
                    <Typography variant='h6'>No upcoming events found</Typography>
                </Status>

                <FullScreenModal open={state.modalOpen} onClose={handleModalClose} title={modalHeader} cancelText='Cancel'>
                    {(!state.copyId) && (
                        <EventCreator
                            hostId={hostId}
                            onCreate={handleCreate}
                            onCancel={handleModalClose}
                        />
                    )}
                </FullScreenModal>
            </Page>
        )
    }


    /**
     * Split events into:
     *  Live: end_date ascending (Is this the right way to order live events?)
     *  Upcoming: end_date ascending
     *  Concluded: end_date descending
     * 
     * Upcoming events show up first
     * Both upcoming/concluded events show most recent events closer to the top
     */
    const pastEvents     = state.events.filter(e => moment(e.end_date).tz(e.time_zone, true) < moment());
    const liveEvents     = state.events.filter(e => moment(e.end_date).tz(e.time_zone, true) >= moment() && moment(e.start_date).tz(e.time_zone, true) <= moment());
    const upcomingEvents = state.events.filter(e => moment(e.start_date).tz(e.time_zone, true) > moment()).reverse();

    // Render the 'New Event' button on the section that appears first on the screen
    // Note: We check each sections length in the reverse order that they appear on screen so that the 'New Event' button shows up on the first section available
    let renderButtonOn;
    if (pastEvents.length) renderButtonOn = 'concluded';
    if (upcomingEvents.length) renderButtonOn = 'upcoming';
    if (liveEvents.length) renderButtonOn = 'live';


    // Table view to display events
    return (
        <Page>
            {liveEvents.length > 0 && (
                <Box mb={8}>
                    <ControlBar>
                        <ControlBarHeader>Live</ControlBarHeader>
                        {renderButtonOn === 'live' && <ControlBarButton onClick={handleModalOpen}>New Event</ControlBarButton>}
                    </ControlBar>

                    <Grid container spacing={5}>
                        {liveEvents.map(event => (
                            <Grid key={event.event_id} item xs={12} md={6} lg={4}>
                                <EventCard
                                    key={event.event_id}
                                    admin={auth.admin}
                                    event={event}
                                    onEventSelect={handleEventSelect}
                                    onCopy={handleEventCopy}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}
            
            {upcomingEvents.length > 0 && (
                <Box mb={8}>
                    <ControlBar>
                        <ControlBarHeader>Upcoming</ControlBarHeader>
                        {renderButtonOn === 'upcoming' && <ControlBarButton onClick={handleModalOpen}>New Event</ControlBarButton>}
                    </ControlBar>

                    <Grid container spacing={5}>
                        {upcomingEvents.map(event => (
                            <Grid key={event.event_id} item xs={12} md={6} lg={4}>
                                <EventCard
                                    key={event.event_id}
                                    admin={auth.admin}
                                    event={event}
                                    onEventSelect={handleEventSelect}
                                    onCopy={handleEventCopy}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}

            {pastEvents.length > 0 && (
                <Box mb={8}>
                    <ControlBar>
                        <ControlBarHeader>Concluded</ControlBarHeader>
                        {renderButtonOn === 'concluded' && <ControlBarButton onClick={handleModalOpen}>New Event</ControlBarButton>}
                    </ControlBar>

                    <Grid container spacing={5}>
                        {pastEvents.map(event => (
                            <Grid key={event.event_id} item xs={12} md={6} lg={4}>
                                <EventCard
                                    key={event.event_id}
                                    concluded
                                    admin={auth.admin}
                                    event={event}
                                    onEventSelect={handleEventSelect}
                                    onCopy={handleEventCopy}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}

            <FullScreenModal open={state.modalOpen} onClose={handleModalClose} title={modalHeader} cancelText='Cancel'>
                {state.copyId && (
                    <EventDuplicator
                        eventId={state.copyId}
                        hostId={hostId}
                        onCopy={handleCopy}
                        onCancel={handleModalClose}
                    />
                )}
                {(!state.copyId) && (
                    <EventCreator
                        hostId={hostId}
                        onCreate={handleCreate}
                        onCancel={handleModalClose}
                    />
                )}
            </FullScreenModal>

        </Page>
    )
}

function EventCard(props) {
    const { admin, onEventSelect, onCopy, concluded } = props;
    const e = props.event;

    const handleCopy = (ev, eventId) => {
        ev.stopPropagation(); // stops click event from triggering the card click handler too
        onCopy(eventId);
    };
    
    return (
        <CardSd onClick={() => onEventSelect(e.event_id)}>

            <CardHeaderSd
                avatar={concluded ? (
                    <Tooltip message='This event is concluded'>
                        <ConcludedAvatar>C</ConcludedAvatar>
                    </Tooltip>
                ) : (<>
                    {e.live_flag === 1 && e.unlisted_flag === 0 && (
                        <Tooltip message='Published: This event is open to the public and listed on the FrontDoor+ website'>
                            <PublishedAvatar>P</PublishedAvatar>
                        </Tooltip>
                    )}
                    {e.live_flag === 1 && e.unlisted_flag === 1 && (
                        <Tooltip message='Unlisted: Anyone with the event link can see this event. This event is not listed on the FD+ website'>
                            <UnlistedAvatar>U</UnlistedAvatar>
                        </Tooltip>
                    )}
                    {e.live_flag === 0 && (
                        <Tooltip message='Draft: This event is not available to the public'>
                            <DraftAvatar>D</DraftAvatar>
                        </Tooltip>
                    )}
                </>)}
                title={e.event_title}
                subheader={moment(e.start_date).format('MMM Do YYYY')}
            />

            <CardMedia
                component='img'
                src={e.image_url}
                title='Event Image'
            />

            <CardContent>
                <Box display='flex' justifyContent={'space-around'}>
                    <Box display={'flex'} flexDirection='column'>
                        <Typography variant='caption' align='center'>Revenue</Typography>
                        <TypographyBold variant='body1' align='center'>{'$' + abbreviateNumber(e.total_revenue + e.pos_total_revenue, 0, 1)}</TypographyBold>
                    </Box>
                    <Box display={'flex'} flexDirection='column'>
                        <Typography variant='caption' align='center'>Orders</Typography>
                        <TypographyBold variant='body1' align='center'>{abbreviateNumber(e.total_orders + e.pos_total_orders, 0, 1)}</TypographyBold>
                    </Box>
                </Box>
            </CardContent>

            <CardActions>
                {admin === true && (
                    <Tooltip message={e.host_pricing_name}>
                        <HostPricingLabel>
                            <Typography variant='button'>{e.host_pricing_id}</Typography>
                        </HostPricingLabel>
                    </Tooltip>
                )}
                <ActionButton size='small' onClick={(ev) => handleCopy(ev, e.event_id)} startIcon={<FileCopyIcon />}>Copy</ActionButton>
            </CardActions>

        </CardSd>
    )
}


const CardSd = styled(Card)(({ theme }) => ({
    '&:hover': {
        cursor: 'pointer'
    }
}));

const CardHeaderSd = styled(CardHeader)(({ theme }) => ({
    height: '60px'
}));

const Status = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 156
}));

const ConcludedAvatar = styled(Avatar)(({ theme }) => ({
    color: theme.palette.getContrastText('#777'),
    backgroundColor: '#777'
}));

const PublishedAvatar = styled(Avatar)(({ theme }) => ({
    color: theme.palette.getContrastText(theme.palette.success.main),
    backgroundColor: theme.palette.success.main
}));

const UnlistedAvatar = styled(Avatar)(({ theme }) => ({
    color: theme.palette.getContrastText(theme.palette.info.main),
    backgroundColor: theme.palette.info.main
}));

const DraftAvatar = styled(Avatar)(({ theme }) => ({
    color: theme.palette.getContrastText(theme.palette.warning.main),
    backgroundColor: theme.palette.warning.main
}));

const TypographyBold = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: 24
}));

const HostPricingLabel = styled(Box)(({ theme }) => ({
    marginRight: 'auto',
    color: theme.palette.grey[600]
}));