import React, { useState, useEffect } from 'react';

// Material UI
import { styled, Box, Typography, CircularProgress, Button, Paper } from '@material-ui/core';

// Components
import AuditChart from 'pages/admin/audit/AuditChart';
import AuditList from 'pages/admin/audit/AuditList';
import AuditEventEditor from 'pages/admin/audit/AuditEventEditor';
import FullScreenModal from 'components/popups/FullScreenModal';
import { AdminPage } from 'components/ui/layout';
import { SearchBar } from 'components/ui/inputs';

// Utility
import { useNotification } from 'context/notification';

// API
import * as AuditAPI from 'pages/admin/audit/AuditAPI';



export default function Audit() {

    const [audit, setAudit] = useState({
        data: [],
        status: 'loading', // loading, error, success
        refresh: 0 // Increment to refresh events
    });

    const [modal, setModal] = useState({
        event: null,
        open: false,
    });

    const [search, setSearch] = useState('');

    const [tab, setTab] = useState(0); // 0 = future, 1 = past


    // Hooks
    const { notify } = useNotification();


    // Load/Reload Audit Data
    useEffect(() => {
        AuditAPI.getAuditData()
            .then(data => {
                setAudit(state => ({
                    ...state,
                    status: 'success',
                    data: data
                }));
            })
            .catch(error => {
                notify.error(String(error)); // This is an admin screen so we're okay showing js errors on screen
                setAudit(state => ({ ...state, status: 'error' }))
            })
    // eslint-disable-next-line
    }, [audit.refresh]);


    // State Functions
    const handleModalOpen = (event) => {
        setModal(m => ({ ...m, open: true, event }));
    }

    const handleModalClose = () => {
        setModal(m => ({ ...m, open: false, event: null }));
    }

    const handleEventUpdate = () => {
        setAudit(a => ({ ...a, refresh: a.refresh + 1 }))
    };


    if (audit.status === 'loading') {
        return (
            <AdminPage>
                <Status>
                    <CircularProgress disableShrink />
                </Status>
            </AdminPage>
        )
    }

    if (audit.status === 'error') {
        return (
            <AdminPage>
                <Status>
                    <Typography>There was a problem loading events</Typography>
                </Status>
            </AdminPage>
        )
    }


    const eventWeeks = audit.data;

    let eventWeeksDisplay = [];

    if (tab === 0 /*Future*/) eventWeeksDisplay = eventWeeks.filter(ew => ew.weeksOut >= 0);
    if (tab === 1 /*Past*/  ) eventWeeksDisplay = eventWeeks.filter(ew => ew.weeksOut < 0).sort((a,b) => b.weeksOut - a.weeksOut);
    

    // Table view to display events
    return (
        <AdminPage>

            <Box mb={2}>
                <AuditChart eventWeeks={eventWeeks} />
            </Box>

            <Toolbar>
                <ToggleContainer>
                    <ToggleButton first active={tab === 0} onClick={() => setTab(0)}>Future</ToggleButton>
                    <ToggleButton last active={tab === 1} onClick={() => setTab(1)}>Past</ToggleButton>
                </ToggleContainer>
                <Box>
                    <SearchBar
                        value={search}
                        onChange={setSearch}
                    />
                </Box>
            </Toolbar>

            {eventWeeksDisplay.map(ew => (
                <AuditList
                    key={ew.weeksOut}
                    weeksOut={ew.weeksOut}
                    weekStart={ew.start}
                    weekEnd={ew.end}
                    totalPhones={ew.totalPhones}
                    totalReaders={ew.totalReaders}
                    totalUnconfirmed={ew.totalUnconfirmed}
                    events={ew.events}
                    eventFilter={search.trim()}
                    onClick={handleModalOpen}
                />
            ))}

            <FullScreenModal open={modal.open} onClose={handleModalClose} title={modal.event?.event} cancelText='Cancel'>
                <AuditEventEditor
                    open={modal.open}
                    event={modal.event}
                    onUpdate={handleEventUpdate}
                    onClose={handleModalClose}
                />
            </FullScreenModal>
        </AdminPage>
    )
}


const Status = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 156
}));

const Toolbar = styled(Paper)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(4),
}));

const ToggleContainer = styled(Box)(({ theme }) => ({
    width: 200,
    display: 'flex',
    flexWrap: 'nowrap'
}));

const ToggleButton = styled(({ first, last, active, ...other }) => <Button {...other} />)({
    width: 100,
    backgroundColor: props => props.active ? '#1976d2' : '#fff',
    color: props => props.active ? '#fff' : '#000',
    borderTopRightRadius:         (props) => props.last ? 4 : 0,
    borderBottomRightRadius:         (props) => props.last ? 4 : 0,
    borderTopLeftRadius:         (props) => props.first ? 4 : 0,
    borderBottomLeftRadius:         (props) => props.first ? 4 : 0,
    '&:hover': {
        backgroundColor: props => props.active ? '#1976d2' : '#fff',
    },
});
ToggleButton.defaultProps = { variant: 'outlined' }