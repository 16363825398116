import React, { useState } from 'react';
import { Box } from '@material-ui/core';

// Components
import { ControlBar, ControlBarButton, ControlBarHeader, AdminPage } from 'components/ui/layout';
import HostList from 'components/HostList';
import FullScreenModal from 'components/popups/FullScreenModal';
import HostForm from 'components/HostForm';


export default function Hosts(props) {
    // State
    const [state, setState] = useState({
        modalOpen: false,
        selectedHost: null,
        dependency: 0 // incrementing this value will cause HostList to fetch all hosts and re render
    });


    function handleModalClose() {
        setState({...state, modalOpen: false, selectedHost: null});
    }

    function handleModalOpen() {
        setState({...state, modalOpen: true});
    }

    const handleHostUpdate = () => {
        const newDependency = state.dependency + 1;
        setState({...state, modalOpen: false, selectedHost: null, dependency: newDependency });
    }

    const handleEditHost = (id) => {
        setState({...state, modalOpen: true, selectedHost: id });
    }

    const handleViewPricing = (id, name) => {
        props.history.push(`/admin/hosts/${id}/host_pricing?host=${encodeURIComponent(name)}`);
    }

    const handleViewConnectDetails = (id, name) => {
        props.history.push(`/admin/hosts/${id}/host_connect?host=${encodeURIComponent(name)}`);
    }

    // Destructure State
    const { modalOpen, selectedHost } = state;


    return (
        <AdminPage>

            <Box display='flex' flexDirection='column' height='100%'>

                    <ControlBar>
                        <ControlBarHeader>Hosts</ControlBarHeader>
                        <ControlBarButton onClick={handleModalOpen}>New Host</ControlBarButton>
                    </ControlBar>

                    <Box flexGrow={1}>
                        <HostList
                            onEditHost={handleEditHost}
                            onViewPricing={handleViewPricing}
                            onViewConnectDetails={handleViewConnectDetails}
                            dependency={state.dependency}
                        />
                    </Box>

            </Box>


            <FullScreenModal open={modalOpen} onClose={handleModalClose} title={selectedHost ? 'Edit Host' : 'Create Host'}>
                <HostForm hostId={selectedHost} onUpdate={handleHostUpdate} onCancel={handleModalClose} />
            </FullScreenModal>

        </AdminPage>
    )
}