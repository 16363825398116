import React, { useState, useEffect, useReducer } from 'react';

import FullScreenModal from 'components/popups/FullScreenModal';
import BundleForm from 'components/bundles/BundleForm';
import ConfirmAction from 'components/popups/ConfirmAction';
import fetchExpresso from 'utility/fetchExpresso';

import { useNotification } from 'context/notification';
import { useEvent } from 'context/event';
import moment from 'moment-timezone';
import { LoadingPage, ErrorPage } from 'components/ui/states';


const initialState = {
    status: 'loading', // loading, error, success, no_products
    submitting: false,
    products: [],
    deletable: false,
    defaultValues: {
        name: '',
        description: '',
        price: '',
        qtyAvailable: '',
        qtyMin: '',
        qtyMax: '',
        saleStartDate: moment(),
        saleEndDate: moment(),
        visible: true,
        accessCode: '',
        createdAtMsg: '',
        updatedAtMsg: '',
    },
}

function reducer(state, action) {
    switch (action.type) {
        case 'setForm':
            return { ...state, status: 'success', products: action.products, defaultValues: { ...state.defaultValues, ...action.form }, deletable: action.deletable };

        case 'setNoProducts':
            return { ...state, status: 'no_productcs' };

        case 'setSubmitting':
            return { ...state, submitting: action.value };

        case 'defaultState':
            return initialState;

        default:
            throw new Error();
    }
}


export default function BundleEditModal(props) {
    const { open, onClose } = props;

    return (
        <FullScreenModal open={open} onClose={onClose} title='Edit Bundle' cancelText='Cancel'>
            {open === true && (
               <BundleModal {...props} /> 
            )}
        </FullScreenModal>
    )
}

function BundleModal(props) {
    const { onClose, eventId, bundleId, onUpdate } = props;

    const [state, dispatch] = useReducer(reducer, initialState);

    const [confirmDelete, setConfirmDelete] = useState(false);

    const event = useEvent();
    const { notify } = useNotification();


    useEffect(() => {
        Promise.all([
            fetchExpresso(`/apiv2/bundles/${bundleId}`),
            fetchExpresso(`/apiv2/products/?event=${eventId}`)    
        ])
            .then(async res => {
                if (res[0].status !== 200 || res[1].status !== 200) {
                    throw new Error();
                }

                const [b, p] = await Promise.all([
                    res[0].json(),
                    res[1].json()
                ]);

                if (p.length > 0) {
                    const products = p.map(i => {
                        let status = 'on_sale';
        
                        if (moment(i.sale_start_date).tz(event.time_zone, true) > moment()) status = 'sale_not_started';
                        if (moment(i.sale_end_date).tz(event.time_zone, true) < moment()) status = 'sale_concluded';
                        if (i.qty_left <= 0) status = 'sold_out';
                        if (i.visible === 0) status = 'hidden';

                        let quantity = b.products.reduce((previous, next) => {
                            if (next.product_id === i.product_id) {
                                return next.qty;
                            } else {
                                return previous;
                            }
                        }, 0)

                        return {
                            id: i.product_id,
                            name: i.prod_name,
                            type: i.prod_type_id,
                            sold: i.qty_sold,
                            total: i.total_available,
                            price: i.price,
                            status: status,
                            quantity: quantity
                        }
                    });

                    let audience;

                    if (b.access_code) {
                        audience = 'accessCode';
                    } else if (b.visible === 1) {
                        audience = 'visible';
                    } else {
                        audience = 'hidden'
                    }

                    const form = {
                        name: b.bundle_name,
                        description: b.bundle_desc,
                        price: b.price,
                        qtyAvailable: b.qty_total_available,
                        qtyMin: b.qty_min_purchase,
                        qtyMax: b.qty_max_purchase,
                        saleStartDate: moment(b.sale_start_date),
                        saleEndDate: moment(b.sale_end_date),
                        accessCode: b.access_code,
                        audience: audience,
                        createdAtMsg: `Created ${moment(b.created_at).fromNow()} by ${b.created_by}`,
                        updatedAtMsg: `Updated ${moment(b.updated_at).fromNow()}` + (b.updated_by ? ` by ${b.updated_by}` : '')
                    }

                    const deletable = b.qty_sold === 0;

                    dispatch({ type: 'setForm', products, form, deletable });
                } else {
                    dispatch({ type: 'setNoProducts' });
                }
            })
    }, [bundleId, eventId, event.end_date, event.time_zone]);


    const handleSubmit = (bundleInfo, bundleProducts) => {

        dispatch({ type: 'setSubmitting', value: true });

        fetchExpresso(`/apiv2/bundles/${bundleId}`, {
            method: 'PUT',
            body: {
                bundleInfo: bundleInfo,
                bundleProducts: bundleProducts
            }
        })
            .then(async res => {
                if (res.status !== 200) {
                    throw new Error();
                }

                notify.success('Bundle Updated');

                dispatch({ type: 'defaultState' });
                onUpdate(); // Notifies parent component that the bundle was edited.
            })
            .catch(() => {
                notify.error('There was a problem creating your bundle');
                dispatch({ type: 'setSubmitting', value: false });
            })
    };

    const handleDelete = () => {
        setConfirmDelete(false);
        dispatch({ type: 'setSubmitting', value: true });

        fetchExpresso(`/apiv2/bundles/${bundleId}`, {
            method: 'DELETE'
        })
            .then(async res => {
                if (res.status !== 200) {
                    throw new Error();
                }

                notify.success('Bundle Deleted');

                dispatch({ type: 'defaultState' });
                onUpdate();
            })
            .catch(() => {
                notify.error('There was a problem deleting your bundle');
                dispatch({ type: 'setSubmitting', value: false });
            })
    }

    if (state.status === 'loading') {
        return <LoadingPage />
    }
    
    if (state.status === 'error') {
        return <ErrorPage message='Unable to load bundle creator' />
    }
    
    if (state.status === 'no_tickets') {
        return <ErrorPage message='Unable to find any tickets to bundle.' />
    }


    return (
        <>
            <BundleForm
                editMode
                deletable={state.deletable}
                defaultValues={state.defaultValues}
                eventId={eventId}
                hostId={event.host_id}
                products={state.products}
                onSubmit={handleSubmit}
                onCancel={onClose}
                onDelete={() => setConfirmDelete(true)}
                loading={state.submitting}
            />

            <ConfirmAction
                open={confirmDelete} onConfirm={handleDelete} onCancel={() => setConfirmDelete(false)}
                destructive
                title='Are you sure you want to delete this bundle?'
                description='You cannot recover a bundle once it is deleted'
                confirmText='Delete'
            />
        </>
    )
}