import React, { useState, useEffect } from 'react';

// Material-UI
import { Card, CardHeader, CardContent, CircularProgress, CardActions } from '@material-ui/core';
import { styled, Box, Typography } from '@material-ui/core';

// Components
import { ActionButton } from 'components/ui/buttons';

// Utility
import fetchExpresso from 'utility/fetchExpresso';
import moment from 'moment-timezone';
import { useNotification } from 'context/notification';



export default function SitemapStatus() {

    const [loading, setLoading] = useState(false);
    const [updatedAt, setUpdatedAt] = useState(null);

    const { notify } = useNotification();


    const getLastUpdate = () => {
        fetchExpresso(`/external/sitemap/last_updated`)
            .then(res => res.json())
            .then(data => setUpdatedAt(data.last_updated || null))
            .catch(() => null)
    };


    useEffect(() => {
        getLastUpdate();
    }, []);


    const handleUpdate = () => {
        if (loading === true) return;
        setLoading(true);

        fetchExpresso(`/external/sitemap/`, { method: 'PUT' })
            .then(res => {
                if (res.status === 200) {
                    getLastUpdate();
                    notify.success('Updated sitemap');
                } else {
                    throw new Error();
                }
            })
            .catch(e => {
                notify.error('Unable to update sitemap');
            })
            .finally(() => setLoading(false))
    };


    let updatedAtText = moment(updatedAt).fromNow();

    // Bugfix: Immediately after updating the sitemap, the new updatedAt value generated on the server is a couple seconds ahead of the current time on the client.
    //         Override the updatedAtText value when this happens
    if (moment(updatedAt) > moment()) {
        updatedAtText = 'a few seconds ago'
    }


    return (
        <Root>

            <CardHeader title='Sitemap' />

                <CardContent>
                    {updatedAt ? (
                        <Box display={'flex'} alignItems={'center'}>
                            <Primary>Updated</Primary>
                            <Secondary>{updatedAtText}</Secondary>
                        </Box>
                    ) : (
                        <CircularProgress />
                    )}
                </CardContent>
                <CardActions>
                    <ActionButton onClick={handleUpdate} disabled={loading}>Regenerate Sitemap</ActionButton>
                </CardActions>
        </Root>
    )
}

const Root = styled(Card)({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width :'100%'
});

const Primary = styled(Typography)(({ theme }) => ({
    display: 'inline',
    marginRight: theme.spacing(1)
}));

const Secondary = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    display: 'inline'
}));