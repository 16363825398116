import React, { useState, useEffect } from 'react';
import { useNotification } from 'context/notification';
import fetchExpresso from 'utility/fetchExpresso';
import QuestionForm from 'components/forms/QuestionForm';
import { ErrorState, LoadingState } from 'components/ui/states';


export default function QuestionCreator(props) {
    const { eventId, onCreate, onCancel } = props;

    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(false);

    const [defaultValues, setDefaultValues] = useState(null);
    const [types, setTypes] = useState(null);

    const { notify } = useNotification();


    useEffect(() => {
        async function init() {
            try {
                const results = await Promise.all([
                    fetchExpresso('/apiv2/registration/question_types'),
                    fetchExpresso(`/apiv2/events/${eventId}/products`)
                ]);

                if (results[0].status !== 200 || results[1].status !== 200) {
                    return setError(true);
                }

                const types    = await results[0].json().then(data => data.types)
                const products = await results[1].json()

                setTypes(
                    types.map(t => ({
                        value: t.q_type_id,
                        text: t.q_type_desc,
                        hasOptions: Boolean(t.has_options)
                    }))
                )

                setDefaultValues({
                    options: [{
                        id: null,
                        description: '',
                        tempId: (Math.random() + '-' + Date.now()).slice(2),
                        error: null,
                        action: 'CREATE'
                    }],
                    visibility: 'visible', // visible, limited
                    products: products.map(p => ({
                        id: p.product_id,
                        name: p.prod_name,
                        checked: false
                    }))
                })
            } catch(e) {
                setError(true);
            }
        }
        init();
    }, [eventId]);


    const handleSubmit = (formData) => {
        setSaving(true);
        fetchExpresso(`/apiv2/events/${eventId}/registration/questions`, {
            method: 'POST',
            body: formData
        })
            .then(res => {
                if (res.status === 200) {
                    notify.success('Question Created');
                    onCreate();
                } else {
                    throw new Error();
                }
            })
            .catch(() => notify.error('There was a problem creating your question'))
            .finally(() => setSaving(false))
    }


    if (error) {
        return <ErrorState message='We encountered an issue. Please close and reopen the window to try again.' />
    }

    if (defaultValues === null || types === null) {
        return <LoadingState />
    }


    return (
        <QuestionForm
            defaultValues={defaultValues}
            types={types}
            onSubmit={handleSubmit}
            onCancel={onCancel}
            saving={saving}
        />
    );
}