import React, { useState, useEffect } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { useNotification } from 'context/notification';
import fetchExpresso from 'utility/fetchExpresso';
import EventCopyForm from 'components/forms/EventCopyForm';
import moment from 'moment-timezone';


export default function EventDuplicator(props) {
    const { eventId, hostId, onCopy, onCancel } = props;

    const [loading, setLoading] = useState(false);
    const [defaultValues, setDefaultValues] = useState(null);

    const { notify } = useNotification();


    useEffect(() => {
        fetchExpresso(`/apiv2/events/${eventId}`)
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    throw new Error();
                }
            })
            .then(data => {
                setDefaultValues({
                    title: data.event_title + ' (copy)',
                    description: data.event_desc,
                    startDate: moment(data.start_date),
                    endDate: moment(data.end_date),
                    timezone: data.time_zone
                });
            })
            .catch(() => {
                // TODO: Show error when unable to load default values. User should not be shown a blank form when editing an event
            });
    }, [eventId]);

    
    const handleCopy = (formData) => {
        if (loading) return;
        setLoading(true);

        fetchExpresso('/apiv2/events/copy_event', {
            method: 'POST',
            body: { ...formData, hostId: hostId, sourceId: eventId }
        })
            .then(async (res) => {
                if (res.status !== 200) {
                    notify.error('There was a problem copying your event');
                    setLoading(false);
                } else {
                    const { copyResults } = await res.json();

                    let message = 'Copied event and tickets';

                    if (copyResults.products === false) {
                        message = 'Copied event, but failed to copy your tickets';
                    }
                    
                    notify.success(message);
                    onCopy();
                }
            })
            .catch(() => {
                notify.error('There was a problem copying your event');
                setLoading(false);
            })
    }

    // Waiting for default values to load
    if (defaultValues === null) {
        return (
            <Box display='flex' justifyContent='center' alignItems='center' width={1} height={1}>
                <CircularProgress />
            </Box>
        )
    }
    
    return (
        <EventCopyForm
            defaultValues={defaultValues}
            loading={loading}
            onSubmit={handleCopy}
            onCancel={onCancel}
        />
    );
}