import React, { useState, useEffect } from 'react';
import { styled, Step, StepContent, StepLabel as _StepLabel, Stepper, Box, CircularProgress, Typography } from '@material-ui/core';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import moment from 'moment-timezone';
import fetchExpresso from 'utility/fetchExpresso';

import ReceiptIcon from '@material-ui/icons/Receipt';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import PhonelinkRingIcon from '@material-ui/icons/PhonelinkRing';
import EmailIcon from '@material-ui/icons/Email';


export default function ActivityLog(props) {
    const { tranId } = props;

    const [activity, setActivity] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        getActivityLog(tranId)
            .then(data => setActivity(data))
            .catch((error) => setError(error))
            .finally(() => setLoading(false))
    }, [tranId]);

    return (
        <ActivityLogCard>
            <CardHeader disableTypography
                title={(<Typography variant='h5'>Timeline</Typography>)}
                subheader={(<Typography variant='caption'>*Times listed in the local time zone of your event</Typography>)}
            />
            <CardContent>
                {activity && (
                    <Stepper style={{ padding: 0 }} orientation='vertical'>
                        {activity.map((a, index) => (
                            <Activity
                                key={index}
                                activityNumber={index + 1}
                                action={a.action}
                                timestamp={a.action_time_stamp}
                                statusLevel={a.status_level}
                                statusArea={a.status_area}
                                ticket={a.ticket_name}
                            />
                        ))}
                    </Stepper>
                )}
                {loading && (
                    <CircularProgress />
                )}
                {error && (
                    <Typography>{error}</Typography>
                )}
            </CardContent>
        </ActivityLogCard>
    );
}


function Activity(props) {
    const { action, timestamp, statusLevel, statusArea, ticket } = props;

    const when = moment(timestamp).format('ddd MMM D, YYYY @ h:mm a');

    return (
        <Step active={true} color='secondary' style={{ marginBottom: '8px'}}>
            <StepLabel StepIconComponent={LabelComponent} StepIconProps={{ statusLevel, statusArea }}>
                {when}
            </StepLabel>
            <StepContent>
                <Box mb={4}>
                    <Box mb={1}>
                        <Typography variant='body2'>{action}</Typography>
                    </Box>
                    {ticket && <Typography variant='body2'>{ticket}</Typography>}
                </Box>
            </StepContent>
        </Step>
    )
}

// order ticket wallet refund scan email
function LabelComponent(props) {
    
    let Label = null;
    let Icon = null;

    switch (props.statusLevel) {
        case 'info':
            Label = InfoLabel;
            break;
        case 'warning':
            Label = WarningLabel;
            break;
        case 'fail':
            Label = ErrorLabel;
            break;
        case 'success':
            Label = SuccessLabel;
            break;
        default:
            return null;
    }

    switch (props.statusArea) {
        case 'order':
            Icon = ReceiptIcon;
            break;
        case 'tickets':
            Icon = ConfirmationNumberIcon;
            break;
        case 'wallet':
            Icon = AccountBalanceWalletIcon;
            break;
        case 'refund':
            Icon = RotateLeftIcon;
            break;
        case 'scan':
            Icon = PhonelinkRingIcon;
            break;
        case 'email':
            Icon = EmailIcon;
            break;
        default:
            return null;
    }

    return (
        <Label fontSize={'14px'} color={'white'} width={24} height={24} borderRadius={12} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Icon fontSize='inherit' color='inherit' />
        </Label>
    )
}

const InfoLabel = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
}));

const SuccessLabel = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.success.main,
}));

const WarningLabel = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.warning.main,
}));

const ErrorLabel = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.error.main,
}));


const StepLabel = styled(_StepLabel)(({ theme }) => ({
    "& .MuiStepLabel-label" : {
        color: theme.palette.grey[700],
    }
}));

const ActivityLogCard = styled(Card)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        width: 420
    }
}));


// ***** API ***** //

async function getActivityLog(tranId) {

    const response = await fetchExpresso(`/apiv2/orders/${tranId}/activity`);

    if (response.status === 200) {
        const activity = await response.json();
        return activity;
    }
    else if (response.status === 403) {
        throw new Error('You do not have permission to view logs for this order')
    }
    else {
        throw new Error(`[${response.status}] Something went wrong`)
    }

}