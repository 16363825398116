import React, { useState, useEffect } from 'react';
import { useNotification } from 'context/notification';
import fetchExpresso from 'utility/fetchExpresso';
import QuestionForm from 'components/forms/QuestionForm';
import { ErrorState, LoadingState } from 'components/ui/states';
import moment from 'moment-timezone';


export default function QuestionEditor(props) {
    const { eventId, questionId, onEdit, onCancel } = props;

    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(false);

    const [init, setInit] = useState(null);

    const { notify } = useNotification();


    useEffect(() => {
        async function init() {
            const results = await Promise.all([
                fetchExpresso('/apiv2/registration/question_types'),
                fetchExpresso(`/apiv2/events/${eventId}/registration/questions/${questionId}`)
            ]);

            const { types } = await results[0].json();
            const { question, question_type, options, question_products } = await results[1].json();

            setInit({
                types: types.map(t => ({
                    value:      t.q_type_id,
                    text:       t.q_type_desc,
                    hasOptions: Boolean(t.has_options)
                })),
                defaultValues: {
                    description: question.q_desc,
                    reportDescription: question.report_desc,
                    required: Boolean(question.required),
                    type: {
                        value: question_type.q_type_id,
                        text: question_type.q_type_desc,
                        hasOptions: Boolean(question_type.has_options)
                    },
                    options: options.map((o, i) => ({
                        id: o.q_option_id,
                        description: o.option_desc,
                        tempId: o.q_option_id + '-' + i,
                        error: null,
                        action: 'UPDATE'
                    })),
                    visibility: question_products.some(p => p.question_product_flag === 1) ? 'limited' : 'visible',
                    products: question_products.map(p => ({
                        id: p.product_id,
                        name: p.prod_name,
                        checked: p.question_product_flag === 1
                    }))    
                },
                createdAtMsg: `Created ${moment(question.created_at).fromNow()} by ${question.created_by}`,
                updatedAtMsg: `Updated ${moment(question.updated_at).fromNow()}` + (question.updated_by ? ` by ${question.updated_by}` : '')
            });
        }

        init().catch((e) => {
            console.log(e);
            
            setError(true)
        });
    }, [eventId, questionId]);


    const handleSubmit = (formData) => {
        setSaving(true);
        fetchExpresso(`/apiv2/events/${eventId}/registration/questions/${questionId}`, {
            method: 'PUT',
            body: formData
        })
            .then(res => {
                if (res.status === 200) {
                    notify.success('Question Updated');
                    onEdit();
                } else {
                    throw new Error();
                }
            })
            .catch(() => notify.error('Unable to update question'))
            .finally(() => setSaving(false))
    }

    const handleDelete = () => {
        setSaving(true);
        fetchExpresso(`/apiv2/events/${eventId}/registration/questions/${questionId}`, {
            method: 'DELETE'
        })
            .then(res => {
                if (res.status === 200 || res.status === 409) {
                    // 409 - Conflict: Means the question has already been deleted. We'll just call onEdit() which will reload the question list for the user.
                    notify.success('Question Deleted');
                    onEdit();
                } else {
                    throw new Error();
                }
            })
            .catch(() => notify.error('Unable to delete question'))
            .finally(() => setSaving(false))
    }


    if (error) {
        return <ErrorState message='We encountered an issue. Please close and reopen the window to try again.' />
    }

    if (init === null) {
        return <LoadingState />
    }

    return (
        <QuestionForm
            allowDelete={true}
            defaultValues={init.defaultValues}
            types={init.types}
            createdAtMsg={init.createdAtMsg}
            updatedAtMsg={init.updatedAtMsg}
            onSubmit={handleSubmit}
            onCancel={onCancel}
            onDelete={handleDelete}
            saving={saving}
        />
    );
}