import React, { useState } from 'react';
import { styled, Box, Typography, CardActions, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { ActionButton } from 'components/ui/buttons';
import { downloadFile } from 'utility/browser';
import { useNotification } from 'context/notification';
import fetchExpresso from 'utility/fetchExpresso';
import GetAppIcon from '@material-ui/icons/GetApp';
import moment from 'moment-timezone';


export default function TicketDownloader(props) {
    const { multiTicket, tranId, lastName, email, onDownload } = props;

    const [delivery, setDelivery] = useState('merge'); // merge, zip
    const [loading, setLoading] = useState(false);

    const { notify } = useNotification();


    const handleDownload = () => {
        setLoading(true);

        fetchExpresso(`/apiv2/orders/${tranId}/tickets?delivery=${delivery}&tz=${moment.tz.guess()}`)
            .then(async res => {
                if (res.status === 200) {
                    const pdf = await res.text();
                    await downloadFile(`tickets-${tranId}-${lastName}`, pdf);

                    // Create a log on successful downloads
                    fetchExpresso(`/apiv2/ticket_activity/`, {
                        method: 'POST',
                        body: { tranId, typeId: 200, email }
                    })
                    .then(() => {
                        if (onDownload) {
                            onDownload()
                        }
                    })
                    .catch(() => null);

                    setLoading(false);
                } else {
                    throw new Error();
                }
            })
            .catch(() => {
                notify.error('Unable to download tickets');
                setLoading(false);
            });
    }


    return (
        <Card>
            <CardHeader
                title={<Box display='flex' alignItems='center'><GetAppIcon /><Title>Download Tickets</Title></Box>}
                subheader='Download these tickets as PDF files'
            />
            <CardContent>
                <Box minHeight={multiTicket ? '160px' : '60px'}>
                    {multiTicket && (
                        <Box mt={4}>
                            <Box mb={2}>
                                <Typography style={{ fontWeight: 'bold' }}>How do you want the tickets downloaded?</Typography>
                            </Box>
                            <RadioGroup aria-label="bundle" value={delivery} onChange={e => setDelivery(e.target.value)}>
                                <FormControlLabel value="merge" control={<Radio />} label={'Combine tickets into one file'} />
                                <Box mt={2} />
                                <FormControlLabel value="zip" control={<Radio />} label={<>
                                    <Typography>Download each ticket as a separate file</Typography>
                                    <Typography variant='caption'>Best for distributing tickets to multiple people</Typography>
                                </>} />
                            </RadioGroup>
                        </Box>
                    )}
                </Box>
            </CardContent>
            <CardActions>
                <ActionButton disabled={loading} onClick={handleDownload} startIcon={<GetAppIcon />}>{loading ? 'Downloading...' : 'Download'}</ActionButton>
            </CardActions>
        </Card>
    )
}

const Title = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
    display: 'inline'
}));
Title.defaultProps = { variant: 'h6' }