import React, { useState, useEffect } from 'react';
import 'App.css';
import { Hidden, Button, useMediaQuery, useTheme } from '@material-ui/core';

// Components
import { BrowserRouter, Route, useRouteMatch, useLocation, Switch } from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute';
import RootAppBar from 'components/navigation/RootAppBar';
import RootTabs from 'components/navigation/RootTabs';

// Utility
import { useAuth } from 'context/auth';
import ReactGA from 'react-ga';
import { useNotification } from 'context/notification';

// Pages
import Login from 'pages/Login';
import HostSelect from 'pages/HostSelect';
import Home from 'pages/Home';
import Events from 'pages/Events';
import Resources from 'pages/Resources';
import Settings from 'pages/Settings';
import Notification from 'components/Notification';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import PageNotFound from 'pages/PageNotFound';

// Navigators
import EventNavigator from 'components/navigation/EventNavigator';
import AdminNavigator from 'components/navigation/AdminNavigator';


export default function RootNavigator() {

    // Initialize ReactGA (Google Analytics)
    const [ready, setReady] = useState(false);

    useEffect(() => {
        // Enable Google Analytics in Production
        if (process.env.REACT_APP_ENV === 'production') {
            ReactGA.initialize('UA-37733531-9');
        } else {
            ReactGA.initialize('UA-37733531-9', { testMode: true });
        }
        setReady(true);
    }, []);

    if (ready === false) return null;


    return (
        <BrowserRouter>

            <Navigation />

                <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/forgotpassword" component={ForgotPassword} />
                    <Route path="/resetpassword/:userId/:passwordReset" component={ResetPassword} />
                    <PrivateRoute path="/host_select" component={HostSelect} />
                    <PrivateRoute hostRequired exact path="/" component={Home} />
                    <PrivateRoute hostRequired exact path="/events" component={Events} />
                    <PrivateRoute hostRequired path="/resources" component={Resources} />
                    <PrivateRoute hostRequired path="/settings" component={Settings} />

                    <PrivateRoute hostRequired path="/events/:id" component={EventNavigator} />
                    <PrivateRoute hostRequired path="/admin" component={AdminNavigator} />

                    <Route path="*" component={PageNotFound} />
                </Switch>


            <Notification />

        </BrowserRouter>
    );
}


/**
 * Renders top level navigation
 * 
 * Desktop: AppBar
 * Mobile: BottomNavigation
 */

function Navigation() {
    const { auth, logout, setHost } = useAuth();

    const eventView = useRouteMatch({ path: '/events/:id' });
    const adminView = useRouteMatch({ path: '/admin' });
    const authenticated = Boolean(auth?.hostId);

    const showNavigator = Boolean(!eventView && !adminView && authenticated);
    const showHostSwitcher = Boolean(auth?.admin && authenticated);


    const { notify } = useNotification();
    const location = useLocation();

    // Current User/Host
    const userId = auth?.userId;
    const hostId = auth?.hostId;
    const hostName = auth?.hostName;
    const currentPage = location.pathname.replace(/\//g, '') || ''; // Regex: Remove all instances of '/' character in the pathname


    // Check jwt whenever user navigates and log them out if their token is expired
    useEffect(() => {
        if (userId) {
            try {
                const token = JSON.parse(localStorage.getItem(process.env.REACT_APP_JWT_KEY)).token;
                const expires = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
                const currentTimestamp = new Date();

                // Expired JWT
                if (currentTimestamp > expires) {
                    notify.warning('Your session has expired. Please login again.');
                    logout();
                }
            }
            catch(e) { return null; }
        }
        // eslint-disable-next-line
    }, [currentPage, userId])


    // GA Log whenever the page changes
    useEffect(() => {
        if (hostId && hostName) {
            ReactGA.set({ hostId, hostName, userId, page: currentPage || '/' });
            ReactGA.pageview(currentPage || '/');
        }
    }, [currentPage, userId, hostId, hostName]);


    useEffect(() => {
        window.scrollTo(0,0);
    }, [currentPage]);


    const handleChangeHost = () => {
        setHost(null, null);
    }


    return (
        <>
            {showNavigator && (
                <>
                    <Hidden smDown>
                        <RootAppBar admin={auth?.admin} hostId={auth?.hostId} />
                    </Hidden>

                    <Hidden mdUp>
                        <RootTabs admin={auth?.admin} hostId={auth?.hostId} />
                    </Hidden>

                </>
            )}

            <HostSwitcher
                visible={showHostSwitcher}
                host={hostName}
                showNavigator={showNavigator}
                onClick={handleChangeHost}
            />
        </>
    )
}


function HostSwitcher(props) {
    const { visible, showNavigator, host, onClick } = props;

    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('sm'));


    if (visible !== true) return null;


    const buttonStyle = {
        position: 'fixed',
        bottom: 10,
        right: 10,
        color: theme.palette.grey[500],
        zIndex: 1
    }

    if (mobileView && showNavigator) {
        buttonStyle.bottom = 65
    }

    return (
        <Button variant='contained' size='small' disableElevation style={buttonStyle} onClick={onClick}>{host}</Button>
    )

}