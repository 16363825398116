import React from 'react';

// Material UI
import { styled } from '@material-ui/core';
import { Alert as _Alert } from '@material-ui/lab';

// Components
import { AlertButton } from 'components/ui/alerts';

// Utility
import { useHistory } from 'react-router-dom';



export default function ConnectAlert(props) {
    const { connect_id, charges_enabled, payouts_enabled, onboarding_complete, requirements_due } = props;

    const history = useHistory();


    // Host did not complete their Connect Onboarding

    if (connect_id && !onboarding_complete) {
        return (
            <Alert action={<AlertButton severity='warning' onClick={() => history.push(`/settings`)}>Connect</AlertButton>}>
                Please complete your FrontDoor+ Payments onboarding before publishing an event
            </Alert>
        )
    }


    // There is a problem with the host's Connected Account

    if (connect_id && (requirements_due || !charges_enabled || !payouts_enabled)) {
        return (
            <Alert action={<AlertButton severity='warning' onClick={() => history.push(`/settings`)}>View Account</AlertButton>}>
                Action is required on your FrontDoor+ Payments account (powered by Stripe). Your ability to sell tickets, and collect payments may be impacted.
            </Alert>
        )
    }


    // No issues found
    // Either host has a completed connect account, or they are on legacy payments

    return null;

}


const Alert = styled(_Alert)(({ theme }) => ({
    marginBottom: theme.spacing(2)
}));
Alert.defaultProps = { severity: 'warning', variant: 'filled' }