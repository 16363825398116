import React, { useState, useEffect } from 'react';

// Material-UI
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { styled, Box, Button, CircularProgress, Typography } from '@material-ui/core';

// Utility
import { abbreviateNumber } from 'utility/numbers';
import fetchExpresso from 'utility/fetchExpresso';
import moment from 'moment-timezone';



export default function AdminStats() {

    const [state, setState] = useState({
        data: null,
        loading: true,
        error: false
    });

    const [range, setRange] = useState('ytd'); // ytd, alltime

    const { data, loading, error } = state;


    useEffect(() => {
        const tz = moment.tz.guess();

        // Default: Year to Date (YTD)
        let start = moment().startOf('year').format('YYYY-MM-DDTHH:mm:ss');
        let end = moment().format('YYYY-MM-DDTHH:mm:ss');

        if (range === 'alltime') {
            start = '2020-01-01T00:00:00';
            end = moment().format('YYYY-MM-DDTHH:mm:ss');
        }

        fetchExpresso(`/admin/reports/totals?tz=${tz}&start=${start}&end=${end}`)
            .then(async res => {
                if (res.status !== 200) {
                    setState(s => ({ ...s, loading: false, error: true }));
                    return;
                }
                const data = await res.json();
                setState(s => ({ ...s, data: data, loading: false, error: false }));
            })
            .catch(e => {
                setState(s => ({ ...s, loading: false, error: true }));
            })
    }, [range]);

    if (loading) {
        return (
            <Root>
                <Status>
                    <CircularProgress disableShrink />
                </Status>
            </Root>
        );
    }

    if (error) {
        return (
            <Root>
                <Status>
                    <Typography>Unable to load stats</Typography>
                </Status>
            </Root>
        );
    }

    return (
        <Root>
            <CardHeader
                title='Stats'
                action={(
                    <Box>
                        <Button style={range === 'ytd' ? {textDecoration: 'underline'} : undefined} onClick={() => setRange('ytd')}>YTD</Button>
                        <Button style={range === 'alltime' ? {textDecoration: 'underline'} : undefined} onClick={() => setRange('alltime')}>ALL TIME</Button>
                    </Box>
                )}
            />

            <CardContent>

                <Table>
                    <TableBody>

                        <TableRow>
                            <Cell>Events</Cell>
                            <Cell align='right'>{abbreviateNumber(data.event_count, 0, 1)}</Cell>
                        </TableRow>

                        <TableRow>
                            <BorderCell><b>ONLINE</b></BorderCell>
                            <BorderCell></BorderCell>
                        </TableRow>
                        <TableRow>
                            <Cell>Orders</Cell>
                            <Cell align='right'>{abbreviateNumber(data.online.order_count, 0, 1)}</Cell>
                        </TableRow>
                        <TableRow>
                            <Cell>Tickets</Cell>
                            <Cell align='right'>{abbreviateNumber(data.online.ticket_count, 0, 1)}</Cell>
                        </TableRow>
                        <TableRow>
                            <Cell>Processed</Cell>
                            <Cell align='right'>${abbreviateNumber(data.online.gross_total, 0, 1)}</Cell>
                        </TableRow>
                        <TableRow>
                            <Cell><b>FD Fees</b></Cell>
                            <Cell align='right'><b>${abbreviateNumber(data.online.our_fee_total, 0, 1)}</b></Cell>
                        </TableRow>

                        <TableRow>
                            <BorderCell><b>TATG</b></BorderCell>
                            <BorderCell></BorderCell>
                        </TableRow>
                        <TableRow>
                            <Cell>Orders</Cell>
                            <Cell align='right'>{abbreviateNumber(data.pos.order_count, 0, 1)}</Cell>
                        </TableRow>
                        <TableRow>
                            <Cell>Tickets</Cell>
                            <Cell align='right'>{abbreviateNumber(data.pos.ticket_count, 0, 1)}</Cell>
                        </TableRow>
                        <TableRow>
                            <Cell>Processed</Cell>
                            <Cell align='right'>${abbreviateNumber(data.pos.gross_total, 0, 1)}</Cell>
                        </TableRow>
                        <TableRow>
                            <Cell><b>FD Fees</b></Cell>
                            <Cell align='right'><b>${abbreviateNumber(data.pos.our_fee_total, 0, 1)}</b></Cell>
                        </TableRow>

                        <TableRow>
                            <BorderCell><b>FD INC</b></BorderCell>
                            <BorderCell align='right'><b>${abbreviateNumber(data.inc_total, 0, 1)}</b></BorderCell>
                        </TableRow>

                    </TableBody>
                </Table>
            </CardContent>
        </Root>
    )
}

const Root = styled(Card)(({ theme }) => ({
    height: '100%'
}));

const Status = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
});

const Cell = styled(TableCell)(({ theme }) => ({
    border: 'none',
    paddingTop: 0
}));

const BorderCell = styled(TableCell)(({ theme }) => ({
    border: 'none',
    borderTop: '1px solid'
}));