import React, { useState, useEffect } from 'react';

// Material UI
import { styled, Box, Typography, CircularProgress, Chip, Paper, Button } from '@material-ui/core';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';

// Utility
import fetchExpresso from 'utility/fetchExpresso';
import { useAuth } from 'context/auth';


export default function StripeConnect() {

    const [state, setState] = useState({
        status: 'loading', // loading, error, success
        account: null,
        accountLink: null,
        loginLink: null
    });

    const { auth } = useAuth();


    useEffect(() => {
        (async () => {
            try {

                const accountRequest = await fetchExpresso(`/apiv2/hosts/${auth.hostId}/connect/`);

                // ***** Found a connected account ***** //
                if (accountRequest.status === 200) {
                    const account = await accountRequest.json();

                    // ** Connected account is setup ** //
                    if (account.charges_enabled === true) {

                        let loginLink = null;
                        
                        // ** Admin User: view connected account in our platform dashboard ** //
                        if (auth.admin === true) {
                            loginLink = process.env.REACT_APP_ENV === 'production'
                                ? `https://dashboard.stripe.com/connect/accounts/${account.id}`
                                : `https://dashboard.stripe.com/test/connect/accounts/${account.id}`;
                        }

                        // ** Regular User: view express dashboard ** //
                        else {
                            const loginLinkRequest = await fetchExpresso(`/apiv2/hosts/${auth.hostId}/connect/login_link`);
    
                            if (loginLinkRequest.status === 200) {
                                const data = await loginLinkRequest.json();
                                loginLink = data.url;
                            }
                        }

                        setState(s => ({
                            ...s,
                            status: 'success',
                            account: account,
                            accountLink: null,
                            loginLink: loginLink
                        }));
                    }
                    
                    // ** Connected account needs to be completed ** //
                    else {
                        let accountLink = null;

                        const accountLinkRequest = await fetchExpresso(`/apiv2/hosts/${auth.hostId}/connect/account_link`);

                        if (accountLinkRequest.status === 200) {
                            const data = await accountLinkRequest.json();
                            accountLink = data.url;
                        }

                        else {
                            throw new Error('Received non 200 response code');
                        }

                        setState(s => ({
                            ...s,
                            status: 'success',
                            account: account,
                            accountLink: accountLink,
                            loginLink: null
                        }));
                    }
                }

                // ***** No connected account found ***** //
                else if (accountRequest.status === 404) {
                    setState(s => ({
                        ...s,
                        status: 'success',
                        account: null,
                        accountLink: null,
                        loginLink: null
                    }));
                }
                
                // ***** Unknown error code ***** //
                else {
                    throw new Error();
                }

            } catch(error) {
                setState(s => ({
                    ...s,
                    status: 'error',
                    account: null,
                    accountLink: null,
                    loginLink: null
                }));
            }
        })()
    }, [auth.hostId, auth.admin]);


    if (state.status === 'loading') {
        return (
            <Card>
                <CardHeader title='Payment Processing' />
                <CardContent>
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={150}>
                        <CircularProgress />
                    </Box>
                </CardContent>
            </Card>
        )
    }
    
    if (state.status === 'error') {
        return (
            <Card>
                <CardHeader title='Payment Processing' />
                <CardContent>
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={150}>
                        <Typography>Something went wrong. Please try refreshing the page.</Typography>
                    </Box>
                </CardContent>
            </Card>
        )
    }


    // ***** User is on Legacy Payments System ***** //

    if (state.account === null) {
        return (
            <Card>
                <CardHeader title='Payment Processing' />
                <CardContent>
                    <Option>
                        <Box display='flex' alignItems={'center'}>
                            <OptionName>FrontDoor+ Payments</OptionName>
                            <OptionCaption>(powered by Stripe)</OptionCaption>
                        </Box>
                        <Box ml={2} mt={1}>
                            <Typography>• Accept on-site payments</Typography>
                            <Typography>• More payout options</Typography>
                            <Box height={4} />
                            <ContactMessage>Contact us to learn more</ContactMessage>
                        </Box>
                    </Option>

                    <Box height={12} />

                    <Option>
                        <Box display='flex' alignItems={'center'}>
                            <Check />
                            <OptionName>Legacy Payments</OptionName>
                        </Box>
                        <Box ml={2} mt={1}>
                            <Typography>• On-site payments not supported</Typography>
                            <Typography>• Payouts processed manually</Typography>
                        </Box>
                    </Option>
                </CardContent>
            </Card>
        )
    }


    // ***** User Requires Connect Onboarding ***** //

    if (state.accountLink) {
        return (
            <Card>
                <CardHeader title='Payment Processing'/>
                <CardContent>
                    <Option>
                        <Box display='flex' alignItems={'center'}>
                            <Check />
                            <OptionName>FrontDoor+ Payments</OptionName>
                            <OptionCaption>(powered by Stripe)</OptionCaption>
                            <WarningChip label='Not Connected' />
                        </Box>
                        <Box ml={2} mt={1} mb={3}>
                            <Typography>• Accept on-site payments</Typography>
                            <Typography>• More payout options</Typography>
                        </Box>
                        <Button variant='contained' color='secondary' disableElevation href={state.accountLink} target='_blank' rel='noopener noreferrer'>Complete Setup</Button>
                    </Option>
                </CardContent>
            </Card>
        )
    }


    // ***** User is Connected ***** //

    if (state.loginLink) {
        return (
            <Card>
                <CardHeader title='Payment Processing'/>
                <CardContent>
                    <Option>
                        <Box display='flex' alignItems={'center'}>
                            <Check />
                            <OptionName>FrontDoor+ Payments</OptionName>
                            <OptionCaption>(powered by Stripe)</OptionCaption>
                            <SuccessChip label='Connected' />
                        </Box>
                        <Box ml={2} mt={1} mb={3}>
                            <Typography>• Accept on-site payments</Typography>
                            <Typography>• More payout options</Typography>
                        </Box>
                        <Button variant='contained' color='secondary' disableElevation href={state.loginLink} target='_blank' rel='noopener noreferrer'>View Dashboard</Button>
                    </Option>
                </CardContent>
            </Card>
        )
    }

    return null;
}


const Option = styled(Paper)(({ theme }) => ({
    border: '1px solid',
    borderRadius: theme.spacing(2),
    borderColor: theme.palette.grey[400],
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(2)
}));
Option.defaultProps = { elevation: 0 }

const OptionName = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: 18
}));

const OptionCaption = styled(Typography)(({ theme }) => ({
    fontSize: 14,
    marginLeft: theme.spacing(1),
    paddingBottom: 2
}));

const ContactMessage = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold'
}));

const SuccessChip = styled(Chip)(({ theme }) => ({
    backgroundColor: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    color: theme.palette.common.white,
    marginLeft: 'auto'
}));
SuccessChip.defaultProps = { size: 'small' }

const WarningChip = styled(Chip)(({ theme }) => ({
    backgroundColor: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
    color: theme.palette.common.white,
    marginLeft: 'auto'
}));
WarningChip.defaultProps = { size: 'small' }

const Check = styled(CheckCircleOutlinedIcon)(({ theme }) => ({
    color: theme.palette.success.main,
    marginRight: 4
}));    