import React, { useState, useEffect } from 'react';
import { useNotification } from 'context/notification';
import fetchExpresso from 'utility/fetchExpresso';
import ConfirmAction from 'components/popups/ConfirmAction';
import { LoadingState, ErrorState } from 'components/ui/states';
import HostPricingForm from 'components/forms/HostPricingForm';


export default function HostPricignEditor(props) {
    const { hostId, hostName, pricingId, onUpdate, onCancel } = props;

    const [loading, setLoading] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    
    const [init, setInit] = useState({
        status: 'loading', // loading, error, success
        defaultValues: null
    });
    
    const { notify } = useNotification();


    useEffect(() => {
        (async () => {
            try {
                const res = await fetchExpresso(`/apiv2/hosts/${hostId}/host_pricing/${pricingId}`)

                if (res.status !== 200) throw new Error();

                const data = await res.json();

                const enableFeeCaps = Boolean(data.b4t_fee_min_amt || data.b4t_fee_max_amt);
                const enableScheduledPayouts = Boolean(data.scheduled_payout_flag);

                const allowEdit = data.sale_count === 0;
                const allowDelete = data.status === 0 && data.sale_count === 0 && data.event_count === 0;

                setInit({
                    status: 'success',
                    allowEdit: allowEdit,
                    allowDelete: allowDelete,
                    defaultValues: {
                        name: data.name,
                        b4tPercent: Number((data.b4t_fee_order_pct * 100).toPrecision(6)),
                        b4tFlat: data.b4t_fee_line_item_amt,
                        feeMin: enableFeeCaps ? data.b4t_fee_min_amt : undefined,
                        feeMax: enableFeeCaps ? data.b4t_fee_max_amt : undefined,
                        ccPercent: Number((data.cc_fee_order_pct * 100).toPrecision(6)),
                        ccFlatFee: data.cc_fee_order_amt,
                        reservePercent: enableScheduledPayouts ? Number((data.payout_reserve_percent * 100).toFixed(4)) : undefined,
                        absorbsB4tFees: data.host_absorbs_b4t_fees ? 'host' : 'customer',
                        enableFeeCaps: enableFeeCaps,
                        absorbsCcFees: data.host_absorbs_cc_fees ? 'host' : 'customer',
                        enableScheduledPayouts: enableScheduledPayouts
                    }
                })
            }
            catch(e) {
                setInit({
                    status: 'error',
                    defaultValues: null
                })
            }
        })()
    }, [hostId, pricingId]);

    
    const handleEdit = (formData) => {
        if (loading) return;
        setLoading(true);

        fetchExpresso(`/apiv2/hosts/${hostId}/host_pricing/${pricingId}`, {
            method: 'PUT',
            body: {
                setDefault: false,
                fields: formData,
            }
        })
            .then(res => {
                if (res.status === 200) {
                    notify.success('Host Pricing Updated');
                    onUpdate();
                } else if (res.status === 409) {
                    notify.warning('This host pricing is currently in use and cannot be edited');
                    setLoading(false);
                } else {
                    notify.error('There was a problem updating the host pricing record');
                    setLoading(false);
                }
            })
            .catch((e) => {
                notify.error('There was a problem updating the host pricing record');
                setLoading(false);
            })
    }

    const handleNameUpdate = (formData) => {
        if (loading) return;
        setLoading(true);

        fetchExpresso(`/apiv2/hosts/${hostId}/host_pricing/${pricingId}/name`, {
            method: 'PATCH',
            body: { name: formData.name }
        })
            .then(res => {
                if (res.status === 200) {
                    notify.success('Host Pricing Updated');
                    onUpdate();
                } else {
                    notify.error('There was a problem updating the host pricing record');
                    setLoading(false);
                }
            })
            .catch(() => {
                notify.error('There was a problem updating the host pricing record');
                setLoading(false);
            })
    }

    const handleDelete = () => {
        setConfirmDelete(false);
        setLoading(true);

        fetchExpresso(`/apiv2/hosts/${hostId}/host_pricing/${pricingId}`, {
            method: 'DELETE'
        })
            .then(async (res) => {
                if (res.status === 200) {
                    notify.success('Deleted host pricing');
                    onUpdate();
                } else if (res.status === 409) {
                    notify.warning('This host pricing is currently in use and cannot be deleted');
                    setLoading(false);
                } else {
                    notify.error('There was a problem deleting your host pricing');
                    setLoading(false);
                }
            })
            .catch(() => {
                notify.error('There was a problem deleting your host pricing');
                setLoading(false);
            })
    }


    // Waiting for default values to load
    if (init.status === 'loading') {
        return (
            <LoadingState />
        )
    }
    
    // Error loading data for product form
    if (init.status === 'error') {
        return (
            <ErrorState message='There was a problem loading your host pricing record' />
        )
    }

    return (
        <>
            <HostPricingForm
                allowEdit={init.allowEdit}
                allowDelete={init.allowDelete}
                defaultValues={init.defaultValues}
                onSubmit={init.allowEdit ? handleEdit : handleNameUpdate}
                onDelete={() => setConfirmDelete(true)}
                onCancel={onCancel}
                hostName={hostName}
                loading={loading}
            />

            <ConfirmAction
                open={confirmDelete} onConfirm={handleDelete} onCancel={() => setConfirmDelete(false)}
                destructive
                title='Are you sure you want to delete this pricing record?'
                description='You cannot recover this record after deleting it.'
                confirmText='Delete'
            />
        </>
    );
}