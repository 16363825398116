import React, { useState } from 'react';
import { styled } from '@material-ui/core/styles';
import { Button, Box } from '@material-ui/core';
import ImageCropper from 'components/ImageCropper';
import FullScreenModal from 'components/popups/FullScreenModal';
import { useNotification } from 'context/notification';


function readFile(file) {
    return new Promise((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}


export default function SponsorImagePicker(props) {
    const onChange = props.onChange;
    const croppedImage = props.value;

    const [rawImage, setRawImage] = useState(null);

    const { notify } = useNotification();


    const onFileChange = async (e) => {
        if (e.target.files?.length > 0) {
            const file = e.target.files[0];
            let imageDataUrl = await readFile(file);
            setRawImage(imageDataUrl);
        }
    }

    const handleCropSave = (crop) => {
        // Get size of image in base64 string
        // Multiply length by 6 to get # of bits
        // Divide by 8 to get the bytes
        const imgFileSize = crop.length * 6 / 8;

        if (imgFileSize > 800000 /*roughly 800kb*/) {
            notify.warning('Image is too large');
            setRawImage(null);
            return;
        }

        setRawImage(null)
        onChange(crop)
    };

    return (
        <>

            {croppedImage !== null && (
                <ImageContainer>
                    <Image
                        alt='Storefront Preview'
                        src={croppedImage}
                    />
                </ImageContainer>
            )}
            

            <ButtonContainer>

                <DeleteButton variant='outlined' onClick={() => onChange(null)} disabled={croppedImage === null}>
                    Remove
                </DeleteButton>

                <label htmlFor="contained-button-file">
                    <Button variant='contained' color='secondary' component="span">
                        Upload
                    </Button>
                </label>

            </ButtonContainer>

            
            {rawImage === null && (
                <input
                    onChange={onFileChange}
                    style={{ display: 'none' }}
                    accept=".jpeg,.jpg,.png"
                    id="contained-button-file"
                    multiple
                    type="file"
                />
            )}


            <FullScreenModal
                open={Boolean(rawImage)}
                onClose={() => setRawImage(null)}
                title='Upload Image'>

                <ImageCropper
                    image={rawImage}
                    aspect={2/1}
                    onSave={handleCropSave}
                    onClose={() => setRawImage(null)}
                />
            </FullScreenModal>

        </>
    )
}


const ImageContainer = styled(Box)(({ theme }) => ({
    flex: 1,
    border: '1px solid #aaa',
    backgroundColor: theme.palette.background.default,
    width: '100%',
    aspectRatio: 2/1,
    [theme.breakpoints.up('sm')]: {
        height: 220,
        width: 440,
    },
    [theme.breakpoints.down('xs')]: {
        marginBottom: '16px'
    }
}));

const Image = styled('img')({
    height: '100%',
    width: '100%',
    objectFit: 'contain'
})

const ButtonContainer = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(2),
    display: 'flex'
}));

const DeleteButton = styled(Button)(({ theme }) => ({
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    marginRight: '16px'
}));