import React, { useState, useEffect } from 'react';
import { styled, Typography, Grid, Box, IconButton } from '@material-ui/core';
import { Card, CardHeader, CardContent, CardActions } from '@material-ui/core';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { ActionButton } from 'components/ui/buttons';
import { AdminPage } from 'components/ui/layout';
import { LoadingState, ErrorState } from 'components/ui/states';
import fetchExpresso from 'utility/fetchExpresso';
import moment from 'moment-timezone';
import Tooltip from 'components/popups/Tooltip'
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';


export default function HostPricing(props) {

    const [state, setState] = useState({
        data: null,
        status: 'loading', // loading, error, success, initialized (onboarding not started yet)
        error: null
    });

    const hostId = props.match.params.hostId;


    let hostName = '';

    // We initialize the host name via url params if available
    if (props.location.search.startsWith('?host=')) {
        hostName = decodeURIComponent(props.location.search.split('&')[0].slice(6));
    }

    // When the API call completes, we can get the host name from the results
    if (state.data?.host_name) {
        hostName = state.data.host_name;
    }


    const getConnectInfo = (logId) => {
        if (state.status !== 'loading') {
            setState(s => ({ ...s, status: 'loading' }));
        };

        fetchExpresso(`/admin/hosts/${hostId}/connect${logId ? '?log='+logId : ''}`)
            .then(async res => {
                if (res.status === 200) {
                    const data = await res.json();
                    
                    setState((state) => ({ ...state, data: data, status: 'success' }));
                } else if (res.status === 404) {
                    // 404 means we don't yet have an event log for this account
                    // Most likely means they didn't start the onboarding yet, so we'll just display as 'not started'
                    setState((state) => ({ ...state, status: 'initialized' }));
                }
            })
            .catch(() => setState((state) => ({ ...state, data: null, status: 'error' })))
    };

    useEffect(() => {
        getConnectInfo();
    // eslint-disable-next-line
    }, [hostId]);


    if (state.status === 'loading') {
        return <LoadingState />
    }

    if (state.status === 'error') {
        return <ErrorState message={state.error || 'Unable to load connect account'} />
    }

    if (state.status === 'initialized') {
        return (
            <AdminPage>
                <Card elevation={0}>
                    <CardHeader title={hostName} />
                    <CardContent>
                        <Grid container>
                            <Grid container item xs={12} md={6} lg={5} xl={4} spacing={1}>
                                <Grid item xs={6}><Typography>Onboarding</Typography></Grid>
                                <Grid item xs={6}><GreyText>Not Started</GreyText></Grid>
                                <Grid item xs={6}><Typography>Charges</Typography></Grid>
                                <Grid item xs={6}><ErrorText>Disabled</ErrorText></Grid>
                                <Grid item xs={6}><Typography>Payouts</Typography></Grid>
                                <Grid item xs={6}><ErrorText>Disabled</ErrorText></Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </AdminPage>
        )
    }


    const { connect_id, last_updated_unix_timestamp, charges_enabled, payouts_enabled, onboarding_complete, requirements, future_requirements } = state.data;
    const { page_count, current_page, last_page_id, next_page_id } = state.data;
    const { disabled_reason } = state.data.requirements;


    let connectAccountLink = null;

    if (connect_id) {
        connectAccountLink = process.env.REACT_APP_ENV === 'production'
            ? `https://dashboard.stripe.com/connect/accounts/${connect_id}`
            : `https://dashboard.stripe.com/test/connect/accounts/${connect_id}`;
    }

    const lastUpdated = moment(last_updated_unix_timestamp);


    return (
        <AdminPage>

            <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                <IconButton disabled={!last_page_id} onClick={() => getConnectInfo(last_page_id)}>
                    <NavigateBeforeIcon />
                </IconButton>
                <Box mx={2}>
                    <Typography>{current_page}/{page_count}</Typography>
                </Box>
                <IconButton disabled={!next_page_id} onClick={() => getConnectInfo(next_page_id)}>
                    <NavigateNextIcon />
                </IconButton>
            </Box>

            <Card elevation={0}>
                <CardHeader
                    disableTypography
                    title={(
                        <Box display={'flex'} justifyContent={'space-between'}>
                            <Typography variant='h5'>{hostName}</Typography>
                            <Tooltip message={lastUpdated.format('MMM DD YYYY, h:mma')}>
                                <Typography>{lastUpdated.fromNow()}</Typography>
                            </Tooltip>
                        </Box>
                    )}
                    subheader={<Typography>{connect_id}</Typography>}
                />
                <CardContent>
                    <Grid container>
                        <Grid container item xs={12} md={6} lg={5} xl={4} spacing={1}>
                            <Grid item xs={6}>
                                <Typography>Onboarding</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                {onboarding_complete ? <SuccessText>Complete</SuccessText> : <GreyText>In Progress</GreyText>}
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Charges</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                {charges_enabled ? <SuccessText>Enabled</SuccessText> : <ErrorText>Disabled</ErrorText>}
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>Payouts</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                {payouts_enabled ? <SuccessText>Enabled</SuccessText> : <ErrorText>Disabled</ErrorText>}
                            </Grid>
                            {disabled_reason !== null && (<>
                                <Grid item xs={6}>Disabled Reason</Grid>
                                <Grid item xs={6}>{disabled_reason}</Grid>
                            </>)}
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <ActionButton href={connectAccountLink} target='_blank' rel='noopener noreferrer'>View Stripe Dashboard</ActionButton>
                </CardActions>
            </Card>

            <Box mb={2} />

            <RequirementList
                title='Required Documents'
                requirements={requirements}
            />

            <Box mb={2} />

            <RequirementList
                title='Future Requirements'
                requirements={future_requirements}
            />

        </AdminPage>
    )
}


function RequirementList(props) {
    const { title, requirements } = props;

    let upToDate = false;

    const {
        errors,
        past_due,
        alternatives,
        currently_due,
        eventually_due,
        disabled_reason,
        current_deadline,
        pending_verification,
    } = requirements;

    const subheader = current_deadline ? `Due ${moment(requirements.current_deadline * 1000).format('MMM DD, YYYY')}` : null;

    if (
        errors.length === 0 &&
        past_due.length === 0 &&
        alternatives.length === 0 &&
        currently_due.length === 0 &&
        eventually_due.length === 0 &&
        disabled_reason === null &&
        current_deadline === null &&
        pending_verification.length === 0
    ) {
        upToDate = true;
    }

    if (upToDate === true) {
        return null;
    }

    return (
        <Card elevation={0}>
            <CardHeader title={title} subheader={subheader || undefined}/>
            <CardContent>
                {upToDate === true && (
                    <Typography>This host is up to date on all requirements</Typography>
                )}
                <Grid container spacing={2}>
                    <RequirementGroup
                        title='Past Due'
                        subheader='These requirements are overdue and should be submitted as soon as possible'
                        requirements={past_due}
                    />
                    <RequirementGroup
                        title='Currently Due'
                        subheader='Host must meet these requirements or their account may be disabled'
                        requirements={currently_due}
                    />
                    <RequirementGroup
                        title='Eventually Due'
                        subheader='These items will be required eventually'
                        requirements={eventually_due}
                    />
                    <RequirementGroup
                        title='Pending Verification'
                        subheader='These changes are being processed by Stripe'
                        requirements={pending_verification}
                    />
                    <ErrorGroup
                        errors={errors}
                    />
                </Grid>
            </CardContent>
        </Card>
    )
}


function RequirementGroup(props) {
    const { title, requirements } = props;

    if (requirements.length === 0) {
        return null;
    }

    return (
        <Grid item xs={12} md={6}>
            <Typography><b>{title}</b></Typography>
            {requirements.map(r => <Typography>{r}</Typography>)}
        </Grid>
    )
}

function ErrorGroup(props) {
    const { errors } = props;

    if (errors.length === 0) {
        return null;
    }

    return (
        <Grid item xs={12}>
            <TableContainer component={Box}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Requirement</TableCell>
                            <TableCell>Error Message</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {errors.map(e => (
                            <TableRow>
                                <TableCell>{e.requirement}</TableCell>
                                <TableCell>{e.reason}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
}

const ErrorText = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main,
    fontWeight: 'bold'
}));

const SuccessText = styled(Typography)(({ theme }) => ({
    color: theme.palette.success.main,
    fontWeight: 'bold'
}));

const GreyText = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[500],
    fontWeight: 'bold',
    textDecoration: 'italic'
}));