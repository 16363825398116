import React, { useState } from 'react';

// Material UI
import { styled, useTheme } from '@material-ui/core/styles';
import { Box, MenuItem, Paper } from '@material-ui/core';
import { TextField, CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';

// Utility
import { useNotification } from 'context/notification';
import fetchExpresso from 'utility/fetchExpresso';
import useExpresso from 'hooks/useExpresso';


export default function UserFormContainer(props) {
    if (props.userId) {
        return <UserFormEditMode {...props} editMode={true} />
    }
    else {
        return <UserForm {...props} editMode={false} />
    }
}

// Wrapper that sets default form values when editing an existing user
function UserFormEditMode(props) {
    const [user, loading, error]  = useExpresso(`/apiv2/users/${props.userId}`, 'GET', null, null, []);

    if (loading) {
        return (
            <Box display='flex' flex={1} flexDirection='column' justifyContent='center' alignItems='center'>
                <CircularProgress />
            </Box>
        )
    }
    
    if (error) {
        return (
            <Box display='flex' flex={1} flexDirection='column' justifyContent='center' alignItems='center'>
                <p>Error loading user</p>
            </Box>
        )
    }

    if (user) {
        return <UserForm {...props} name={user.name} email={user.email} hostId={user.host_id} />
    }
}


function UserForm(props) {
    // Props
    const { editMode, onUpdate, onCancel, userId } = props;

    const [hosts]  = useExpresso(`/api/auths/host_select`, 'GET', null, null);

    // State
    const [loading, setLoading] = useState(false);

    // State - Form Values
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [name, setName] = useState(props.name || '');
    const [email, setEmail] = useState(props.email || '');
    const [hostId, setHostId] = useState(props.hostId || '');

    // State - Form Errors
    const [passwordError, setPasswordError] = useState(false);
    const [passwordConfirmError, setPasswordConfirmError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [hostIdError, setHostIdError] = useState(false);

    // Hooks
    const { notify } = useNotification();
    const theme = useTheme();


    function handleSubmit() {
        if (loading) return null;

        // Error Handling
        let formHasError = false;


        if (editMode === false) { // only validate passwords when creating new user
            if (password.length === 0) {
                setPasswordError(true);
                formHasError = true;
            } else {
                setPasswordError(false)
            };
    
            if (password !== passwordConfirm || passwordConfirm.length === 0) {
                setPasswordConfirmError(true);
                formHasError = true;
            } else {
                setPasswordConfirmError(false)
            };
        }

        if (name.trim().length === 0) {
            setNameError(true);
            formHasError = true;
        } else {
            setNameError(false)
        };

        if (/.+@.+\..+/.test(email.trim()) === false) {
            setEmailError(true);
            formHasError = true;
        } else {
            setEmailError(false)
        };

        if (hostId === '') {
            setHostIdError(true);
            formHasError = true;
        } else {
            setHostIdError(false)
        };

        if (formHasError) {
            return null;
        }

        setLoading(true);

        const endpoint = editMode
            ? `/apiv2/users/${userId}`
            : '/apiv2/users/'

        fetchExpresso(endpoint, {
            method: editMode ? 'PUT' : 'POST',
            body: {
                password, // ignored by api when editing user
                name,
                email,
                hostId
            }
        })
            .then(res => {
                if (res.status === 200) {
                    editMode
                        ? notify.success('Updated user')
                        : notify.success('Created new user');
                    onUpdate();
                }
                
                else if (res.status === 409) {
                    notify.warning('Email already in use');
                    setLoading(false);
                }
                
                else {
                    throw new Error();
                }
            })
            .catch(e => {
                editMode
                    ? notify.error('There was a problem updating your user')
                    : notify.error('There was a problem creating your user');
                setLoading(false);
            })
    }

    const copyIdToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(userId);
            notify.info('Copied to clipboard!')
        } catch(e) {
            notify.error('Unable to copy to clipboard')
        }
    }

    const hostsLoaded = (hosts && hosts.length > 0);

    return (
        <Box display='flex' justifyContent='center' width='100%'>
            <Box display='flex' flexDirection='column' flexGrow={1} p={[2, 4]} maxWidth={theme.breakpoints.values.sm}>

                <FormContainer>
                    <TextField
                        required
                        variant='outlined'
                        margin='normal'
                        label='Name'
                        value={name}
                        error={nameError}
                        onChange={e => setName(e.target.value)}
                    />

                    <TextField
                        required
                        variant='outlined'
                        margin='normal'
                        label='Email Address'
                        value={email}
                        error={emailError}
                        onChange={e => setEmail(e.target.value.replace(/ /g, ''))}
                    />

                    {editMode === false && (
                        <TextField
                            required
                            variant='outlined'
                            margin='normal'
                            label='Password'
                            disabled={editMode}
                            value={password}
                            error={passwordError}
                            onChange={e => setPassword(e.target.value)}
                        />
                    )}

                    {editMode === false && (
                        <TextField
                            required
                            variant='outlined'
                            margin='normal'
                            label='Confirm Password'
                            disabled={editMode}
                            value={passwordConfirm}
                            error={passwordConfirmError}
                            onChange={e => setPasswordConfirm(e.target.value)}
                        />
                    )}


                    {hostsLoaded ? (
                        <TextField
                            required
                            select
                            variant='outlined'
                            margin='normal'
                            label='Host'
                            value={hostId}
                            error={hostIdError}
                            onChange={e => setHostId(e.target.value)}>
                                {hosts.map(host => (
                                    <MenuItem key={host.host_id} value={host.host_id}>
                                        {host.host_name}
                                    </MenuItem>
                                ))}
                        </TextField>
                    ) : (
                        <TextField
                            variant='outlined'
                            margin='normal'
                            label='Host'
                            disabled
                        />
                    )}

                    <Box display='flex' justifyContent='flex-end'>
                        <CancelButton variant='outlined' onClick={onCancel} disabled={loading}>Cancel</CancelButton>
                        <SaveButton variant='outlined' onClick={handleSubmit} disabled={loading}>Save</SaveButton>
                    </Box>


                    {editMode && ('clipboard' in navigator) && (
                        <Box display='flex' flexDirection='column' alignItems='flex-end' mt={4}>
                            <UserIdDisplay
                                variant='outlined'
                                fullWidth
                                margin='normal'
                                label='User ID'
                                value={userId}
                                disabled
                            />
                            <CopyButton variant='outlined' onClick={copyIdToClipboard}>Copy</CopyButton>
                        </Box>
                    )}

                </FormContainer>

            </Box>
        </Box>
    )
}

const FormContainer = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
}));

const SaveButton = styled(Button)(({ theme }) => ({
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main
}));

const CancelButton = styled(Button)({
    marginRight: '1rem'
});

const UserIdDisplay = styled(TextField)({
    flex: 1
})

const CopyButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    marginLeft: 16
}));
