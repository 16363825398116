import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';

// Components
import { Page } from 'components/ui/layout';
import BundleList from 'components/bundles/BundleList';
import ProductList from 'components/products/ProductList';
import AddonList from 'components/addons/AddonList';
import { LoadingState, ErrorState } from 'components/ui/states';

// Utility
import fetchExpresso from 'utility/fetchExpresso';
import { useNotification } from 'context/notification';


export default function Products(props) {
    const eventId = props.match.params.id;

    const { notify } = useNotification();

    // State
    const [refresh, setRefresh] = useState(0); // Increment this number to reload products and bundles

    const [state, setState] = useState({
        bundles: {
            originalValues: [],
            displayValues: [],
            enableSort: false
        },
        products: {
            originalValues: [],
            displayValues: [],
            enableSort: false
        },
        addons: {
            originalValues: [],
            displayValues: [],
            enableSort: false
        },
        copyId: null,
        status: 'loading', // loading, error, success
    });


    // Load/Reload list of products
    useEffect(() => {

        Promise.all([
            fetchExpresso(`/apiv2/events/${eventId}/products`),
            fetchExpresso(`/apiv2/events/${eventId}/bundles`),
        ])
            .then(async res => {
                if (res[0].status !== 200 || res[1].status !== 200) {
                    throw new Error();
                }

                const [p, b] = await Promise.all([
                    res[0].json(),
                    res[1].json()
                ]);

                const bundles = b;
                const products = p.filter(p => p.prod_type_id === 1);
                const addons = p.filter(p => p.prod_type_id === 2);

                setState((state) => ({
                    ...state,
                    bundles: {
                        ...state.bundles,
                        enableSort: false,
                        originalValues: bundles,
                        displayValues: bundles,
                    },
                    products: {
                        ...state.products,
                        enableSort: false,
                        originalValues: products,
                        displayValues: products,
                    },
                    addons: {
                        ...state.addons,
                        enableSort: false,
                        originalValues: addons,
                        displayValues: addons,
                    },
                    status: 'success'
                }))
            })
            .catch(() => {
                setState((state) => ({ ...state, status: 'error' }))
            })
    }, [eventId, refresh]);


    // ***** SORTING (Bundles) ***** //
    
    function toggleBundleSorting() {
        if (state.bundles.enableSort) {
            setState(s => ({ ...s, bundles: { ...s.bundles, enableSort: false, displayValues: s.bundles.originalValues } }));
        } else {
            setState(s => ({ ...s, bundles: { ...s.bundles, enableSort: true } }));
        }
    }

    function handleBundleSort(id, direction) {
        const bundles = state.bundles.displayValues;

        const targetIndex = bundles.findIndex(bundle => bundle.bundle_id === id); // Get index of bundle to move
        const destinationIndex = direction === 'up' ? targetIndex - 1 : targetIndex + 1; // Get index of bundle to swap with
        
        const targetBundle = bundles[targetIndex];
        const destinationBundle = bundles[destinationIndex];

        const bundleList = JSON.parse(JSON.stringify(bundles)); // Deep clone

        bundleList[targetIndex] = destinationBundle;
        bundleList[destinationIndex] = targetBundle;

        setState(s => ({ ...s, bundles: { ...s.bundles, displayValues: bundleList }}));
    }

    function handleBundleSortSubmit() {
        fetchExpresso(`/apiv2/events/${eventId}/bundles/sort`, {
            method: 'PUT',
            body: { sort: state.bundles.displayValues.map(bundle => bundle.bundle_id) }
        })
            .then(res => {
                if (res.status === 200) {
                    setState(s => ({ ...s, bundles: { ...s.bundles, enableSort: false, originalValues: s.bundles.displayValues } }));
                    setRefresh(s => s + 1);
                    notify.success('Saved Order');
                } else {
                    notify.error('Unable to change order');
                }
            })
            .catch(() => {
                notify.error('Unable to change order');
            });
    }


    // ***** SORTING (Products) ***** //

    function toggleProductSorting() {
        if (state.products.enableSort) {
            setState(s => ({ ...s, products: { ...s.products, enableSort: false, displayValues: s.products.originalValues } }));
        } else {
            setState(s => ({ ...s, products: { ...s.products, enableSort: true } }));
        }
    }

    function handleProductSort(id, direction) {
        const products = state.products.displayValues;

        const targetIndex = products.findIndex(product => product.product_id === id); // Get index of product to move
        const destinationIndex = direction === 'up' ? targetIndex - 1 : targetIndex + 1; // Get index of product to swap with
        
        const targetProduct = products[targetIndex];
        const destinationProduct = products[destinationIndex];

        const productList = JSON.parse(JSON.stringify(products)); // Deep clone

        productList[targetIndex] = destinationProduct;
        productList[destinationIndex] = targetProduct;

        setState(s => ({ ...s, products: { ...s.products, displayValues: productList }}));
    }

    function handleProductSortSubmit() {
        fetchExpresso(`/apiv2/events/${eventId}/products/sort`, {
            method: 'PUT',
            body: { type: 'ticket', sort: state.products.displayValues.map(product => product.product_id) }
        })
            .then(res => {
                if (res.status === 200) {
                    setState(s => ({ ...s, products: { ...s.products, enableSort: false, originalValues: s.products.displayValues } }));
                    setRefresh(s => s + 1);
                    notify.success('Saved Order');
                } else {
                    notify.error('Unable to change order');
                }
            })
            .catch(() => {
                notify.error('Unable to change order');
            });
    }


    // ***** SORTING (Add-ons) ***** //

    function toggleAddonSorting() {
        if (state.addons.enableSort) {
            setState(s => ({ ...s, addons: { ...s.addons, enableSort: false, displayValues: s.addons.originalValues } }));
        } else {
            setState(s => ({ ...s, addons: { ...s.addons, enableSort: true } }));
        }
    }

    function handleAddonSort(id, direction) {
        const addons = state.addons.displayValues;

        const targetIndex = addons.findIndex(addon => addon.product_id === id); // Get index of addon to move
        const destinationIndex = direction === 'up' ? targetIndex - 1 : targetIndex + 1; // Get index of addon to swap with
        
        const targetAddon = addons[targetIndex];
        const destinationAddon = addons[destinationIndex];

        const addonList = JSON.parse(JSON.stringify(addons)); // Deep clone

        addonList[targetIndex] = destinationAddon;
        addonList[destinationIndex] = targetAddon;

        setState(s => ({ ...s, addons: { ...s.addons, displayValues: addonList }}));
    }

    function handleAddonSortSubmit() {
        fetchExpresso(`/apiv2/events/${eventId}/products/sort`, {
            method: 'PUT',
            body: { type: 'addon', sort: state.addons.displayValues.map(product => product.product_id) }
        })
            .then(res => {
                if (res.status === 200) {
                    setState(s => ({ ...s, addons: { ...s.addons, enableSort: false, originalValues: s.addons.displayValues } }));
                    setRefresh(s => s + 1);
                    notify.success('Saved Order');
                } else {
                    notify.error('Unable to change order');
                }
            })
            .catch(() => {
                notify.error('Unable to change order');
            });
    }


    if (state.status === 'loading') {
        return (
            <Page>
                <LoadingState />
            </Page>
        )
    }
    
    if (state.status === 'error') {
        return (
            <Page>
                <ErrorState message='Unable to find tickets' />
            </Page>
        )
    }


    return (
        <Page>

            <Box mb={8}>
                <ProductList
                    products={state.products.displayValues}
                    eventId={eventId}
                    onUpdate={() => setRefresh(refresh + 1)}
                    onProductSort={handleProductSort}
                    toggleProductSorting={toggleProductSorting}
                    onProductSortSubmit={handleProductSortSubmit}
                    enableSort={state.products.enableSort}
                />
            </Box>

            <Box mb={8}>
                <AddonList
                    products={state.addons.displayValues}
                    eventId={eventId}
                    onUpdate={() => setRefresh(refresh + 1)}
                    onProductSort={handleAddonSort}
                    toggleProductSorting={toggleAddonSorting}
                    onProductSortSubmit={handleAddonSortSubmit}
                    enableSort={state.addons.enableSort}
                />
            </Box>

            <Box mb={8}>
                <BundleList
                    bundles={state.bundles.displayValues}
                    enableBundles={state.products.displayValues.length > 0 || state.addons.displayValues.length > 0}
                    eventId={eventId}
                    onUpdate={() => setRefresh(refresh + 1)}
                    onBundleSort={handleBundleSort}
                    toggleBundleSorting={toggleBundleSorting}
                    onBundleSortSubmit={handleBundleSortSubmit}
                    enableSort={state.bundles.enableSort}
                />
            </Box>

        </Page>
    )
}