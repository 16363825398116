import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, CardHeader, CardContent } from '@material-ui/core';
import { LoadingState, ErrorState } from 'components/ui/states';
import fetchExpresso from 'utility/fetchExpresso';


export default function RegistrationAnswers(props) {
    const { tranId } = props;

    const [state, setState] = useState({
        attendees: null,
        loading: true,
        error: false
    });


    useEffect(() => {
        fetchExpresso(`/apiv2/orders/${tranId}/checkout-responses`)
            .then(async res => {
                if (res.status === 404) {
                    // There are no checkout responses to display
                    setState({ attendees: [], loading: false, error: false });    
                    return;
                }

                if (res.status !== 200) {
                    // Something went wrong
                    throw new Error();
                }

                const { attendees } = await res.json();

                setState({ attendees, loading: false, error: false });
            })
            .catch(e => {
                setState(s => ({ ...s, loading: false, error: true }));
            })
    }, [tranId]);


    if (state.loading) {
        return (
            <Card>
                <CardHeader title='Checkout Form Responses' />
                <CardContent>
                    <LoadingState />
                </CardContent>
            </Card>
        )
    }

    if (state.error) {
        return (
            <Card>
                <CardHeader title='Checkout Form Responses' />
                <CardContent>
                    <ErrorState message='Unable to load checkout answers' />
                </CardContent>
            </Card>
        )
    }


    return (
        <Card>
            <CardHeader title='Checkout Form Responses' />
            <CardContent>
                {state.attendees.length === 0 && (
                    <Typography>There are no checkout responses for this order</Typography>
                )}
                {state.attendees.map((attendee, index) => (
                    <Box mb={4}>
                        {attendee.name && (
                            <Typography>Attendee #{index+1}: {attendee.name}</Typography>
                        )}
                        {attendee.response.map((r,i) => (
                            <Box key={i} mt={2}>
                                <Typography variant='caption' gutterBottom>{r.question}</Typography>
                                <Answer
                                    type={r.type}
                                    answer={r.answer}
                                />
                            </Box>
                        ))}
                    </Box>
                ))}
            </CardContent>
        </Card>
    )
}


function Answer(props) {
    const { type, answer } = props;

    if (answer === null) {
        return <Typography>-</Typography>
    }

    if (type === 8) {
        // Yes or No answer. Convert 1/0 to text
        return answer === '1'
            ? <Typography>Yes</Typography>
            : <Typography>No</Typography>;
    }

    if (type === 10) {
        // Terms and Conditions answer. Convert 1/0 to text
        return answer === '1'
            ? <Typography>Accepted</Typography>
            : <Typography>Rejected</Typography>;
    }

    else {
        return <Typography>{answer}</Typography>
    }
}