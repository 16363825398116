import React, { useState, useEffect } from 'react';

// Material UI
import { Grid } from '@material-ui/core';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { Card, CardHeader, CardContent, CardActions } from '@material-ui/core';

// Components
import { useTextInput, useNumberInput, useToggle } from 'hooks/forms';
import { TextField, TextArea } from 'components/ui/inputs';
import { ActionButton } from 'components/ui/buttons';


// Utility
import { useNotification } from 'context/notification';

// API
import * as AuditAPI from 'pages/admin/audit/AuditAPI';



export default function AuditDevices(props) {
    const { eventId, onUpdate, onClose } = props;

    const [loading, setLoading] = useState(true);

    const { notify } = useNotification();

    const phones   = useNumberInput();
    const readers  = useNumberInput();
    const note     = useTextInput();
    const audited  = useToggle();
    const returned = useToggle();

    const returnsEnabled = phones.value > 0 || readers.value > 0;


    useEffect(() => {
        AuditAPI.getAuditDataForEvent(eventId)
            .then(event => {
                phones.setValue(event.admin_phone_count ?? '');
                readers.setValue(event.admin_reader_count ?? '');
                note.setValue(event.admin_note ?? '');
                audited.setValue(Boolean(event.admin_audit_flag));
                returned.setValue(Boolean(event.admin_audit_return_flag));
            })
            .catch(e => notify.error(String(e)))
            .finally(() => setLoading(false));
    // eslint-disable-next-line
    }, []);


    const handleSubmit = (options) => {
        const { closeModal } = options;

        setLoading(true);

        const payload = {
            phoneCount:  phones.value === '' ? null : Number(phones.value),
            readerCount: readers.value === '' ? null : Number(readers.value),
            note:        note.value.trim() || null,
            audited:     audited.checked,
            returned:    returned.checked,
        }

        AuditAPI.editEvent(eventId, payload)
            .then(() => {
                if (closeModal) {
                    onUpdate();
                    onClose();
                } else {
                    onUpdate();
                    setLoading(false);
                    notify.success('Hardware Details Updated');
                }
            })
            .catch((error) => {
                notify.error(String(error)); // This is an admin screen so we're okay showing js errors on screen
                setLoading(false);
            })
    };

    return (
        <Card>
            <CardHeader title='Hardware Tracker' />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            label='Phones'
                            onFocus={e => e.target.select()}
                            {...phones.formProps}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label='Readers'
                            onFocus={e => e.target.select()}
                            {...readers.formProps}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextArea
                            label='Note'
                            maxLength={200}
                            minRows={3}
                            maxRows={3}
                            {...note.formProps}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox {...audited.formProps} />}
                            label='Audited'
                        />
                        <FormControlLabel
                            disabled={!returnsEnabled}
                            control={<Checkbox {...returned.formProps} />}
                            label='Devices Returned'
                        />
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <ActionButton onClick={() => handleSubmit({ closeModal: false })} disabled={loading}>Save</ActionButton>
                <ActionButton onClick={() => handleSubmit({ closeModal: true })} disabled={loading}>Save + Close</ActionButton>
            </CardActions>
        </Card>
    )
}