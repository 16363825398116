import React, { useState, useEffect } from 'react';

// Material UI
import { styled, Box, TextField, Grid, Typography, InputAdornment, IconButton, useMediaQuery } from '@material-ui/core';
import { Card, CardHeader, CardContent, CardActions } from '@material-ui/core';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';

// Components
import { Page } from 'components/ui/layout';
import { LoadingPage, ErrorPage } from 'components/ui/states';
import ScannerPin from 'components/widgets/ScannerPin';
import SocialMediaLinks from 'components/widgets/SocialMediaLinks';
import RecentOrdersByHost from 'components/widgets/RecentOrdersByHost';
import HostPayouts from 'components/payouts/HostPayouts';
import ConnectAlert from 'components/ConnectAlert';

// Utility
import { useAuth } from 'context/auth';
import { useNotification } from 'context/notification';
import fetchExpresso from 'utility/fetchExpresso';
import { abbreviateNumber, formatPrice } from 'utility/numbers';
import moment from 'moment-timezone';
import { EventShortcut } from 'components/ui/buttons';


export default function Home(props) {
    const [state, setState] = useState({
        info: null,
        status: 'loading'
    });

    const [linkCopied, setLinkCopied] = useState(false);

    const { auth } = useAuth();

    const { notify } = useNotification();


    useEffect(() => {
        fetchExpresso(`/apiv2/hosts/${auth.hostId}/dashboard?tz=${moment.tz.guess(true)}`)
            .then(async res => {
                if (res.status !== 200) { throw new Error() }
                
                const info = await res.json();
                setState(s => ({ ...s, status: 'success', info: info }));
            })
            .catch(e => {
                setState(s => ({ ...s, status: 'error' }));
            })
    }, [auth.hostId]);


    // Generate Storefront Link
    const storefrontLink = `${process.env.REACT_APP_STORE_URL}/host/${auth.hostId}`;


    const copyLinkToClipboard = async () => {
        setLinkCopied(true);
        try {
            await navigator.clipboard.writeText(storefrontLink);
        } catch(e) {
            notify.error('Unable to copy to clipboard')
        }
    }


    if (state.status === 'loading') {
        return <LoadingPage />
    }

    if (state.status === 'error') {
        return <ErrorPage message='Unable to load dashboard' />
    }

    const totalEvents = state.info.total_events;
    const upcomingEvents = state.info.upcoming_events;
    const ongoingEvents = state.info.ongoing_events;

    const totalRevenue = `$${abbreviateNumber(state.info.total_revenue + state.info.pos_total_revenue, 2, 1)}`;

    const weeklyRevenue = `$${abbreviateNumber(state.info.weekly_revenue + state.info.pos_weekly_revenue, 2, 1)}`;

    const totalOrders = abbreviateNumber(state.info.total_orders + state.info.pos_total_orders, 0, 1);

    const weeklyOrders = abbreviateNumber(state.info.weekly_orders + state.info.pos_weekly_orders, 0, 1);

    const totalTickets = abbreviateNumber(state.info.total_tickets + state.info.pos_total_tickets, 0, 1);

    const weeklyTickets = abbreviateNumber(state.info.weekly_tickets + state.info.pos_weekly_tickets, 0, 1);

    const totalCustomers = abbreviateNumber(state.info.total_customers, 0, 1);

    const returnCustomerPercent = +parseFloat(((state.info.repeat_customers / state.info.total_customers) * 100) || 0).toFixed(1);

    const draftCount = state.info.upcoming_drafts;


    return (
        <Page>
            
            <ConnectAlert
                connect_id={state.info.connect_id}
                charges_enabled={state.info.charges_enabled}
                payouts_enabled={state.info.payouts_enabled}
                onboarding_complete={state.info.onboarding_complete}
                requirements_due={state.info.requirements_due}
            />

            <Box mb={8}>
                <Greeting
                    name={state.info.user_name}
                    upcomingCount={upcomingEvents}
                    ongoingCount={ongoingEvents}
                    draftCount={draftCount}
                />

                <ShortcutSection
                    events={state.info.events}
                    onSelect={id => props.history.push(`/events/${id}/`)}
                />
            </Box>


            <Grid container spacing={4}>

                <Grid item xs={12} sm={6} lg={4}>
                    <Card>
                        <CardHeader subheader='Events' />
                        <CardContent>
                            <PrimaryStat>{totalEvents}</PrimaryStat>
                            <SecondaryStat>{upcomingEvents}</SecondaryStat>
                            <SecondaryText>upcoming</SecondaryText>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <Card>
                        <CardHeader subheader='Revenue' />
                        <CardContent>
                            <PrimaryStat>{totalRevenue}</PrimaryStat>
                            <SecondaryStat>{weeklyRevenue}</SecondaryStat>
                            <SecondaryText>in the last 7 days</SecondaryText>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <ScannerPin hostId={auth.hostId} />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <Card>
                        <CardHeader subheader='Orders' />
                        <CardContent>
                            <PrimaryStat>{totalOrders}</PrimaryStat>
                            <SecondaryStat>{weeklyOrders}</SecondaryStat>
                            <SecondaryText>in the last 7 days</SecondaryText>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <Card>
                        <CardHeader subheader='Tickets' />
                        <CardContent>
                            <PrimaryStat>{totalTickets}</PrimaryStat>
                            <SecondaryStat>{weeklyTickets}</SecondaryStat>
                            <SecondaryText>in the last 7 days</SecondaryText>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <Card>
                        <CardHeader subheader='Customers' />
                        <CardContent>
                            <PrimaryStat>{totalCustomers}</PrimaryStat>
                            <SecondaryStat>{returnCustomerPercent}%</SecondaryStat>
                            <SecondaryText>are repeat customers</SecondaryText>
                        </CardContent>
                    </Card>
                </Grid>
                

                <Grid item xs={12} md={6}>
                    <Card>
                        <CardHeader title='Top Cities' />
                        <CardContent>
                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableHeadCell>CITY</TableHeadCell>
                                        <TableHeadCell align='right'>ORDERS</TableHeadCell>
                                        <TableHeadCell align='right'>TOTAL</TableHeadCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {state.info.topCities.length === 0 && (
                                        <TableRow>
                                            <TableCell align='center' colSpan={3}>
                                                <Typography variant='subtitle2'>No orders found</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {state.info.topCities.map(c => (
                                        <TableRow key={`${c.city}-${c.province}`}>
                                            <TableCell>{c.city}, {c.province}</TableCell>
                                            <TableCell align='right'>{c.order_count}</TableCell>
                                            <TableCell align='right'>{formatPrice(c.revenue)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </Grid>
                
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardHeader title='📢 Event Listings Page' />
                        <CardContent>
                            <Typography>Share this link with your customers to direct them to a page displaying all of your events. Great when you have multiple events on sale at the same time.</Typography>
                        </CardContent>
                        <CardActions>
                            <HostPageLink
                                helperText={linkCopied ? 'Copied to clipboard' : ' '}
                                value={storefrontLink}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={copyLinkToClipboard}>
                                                <FileCopyIcon color='secondary' />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </CardActions>
                    </Card>
                </Grid>
            
                <Grid item xs={12} md={6}>
                    <SocialMediaLinks hostId={auth.hostId} />
                </Grid>

            </Grid>

            <Box mt={4}>
                <RecentOrdersByHost hostId={auth.hostId} />
            </Box>
            
            <Box mt={4}>
                <HostPayouts hostId={auth.hostId} />
            </Box>


        </Page>
    )
}

function Greeting(props) {
    const { name, draftCount, upcomingCount, ongoingCount } = props;


    // ============== Greeting Message ============== //

    let greeting = '';
    const hour = new Date().getHours();

    if (hour < 12) {
        greeting = `Good morning, ${name.split(' ')[0]}`;
    } else if (hour < 18) {
        greeting = `Good afternoon, ${name.split(' ')[0]}`;
    } else {
        greeting = `Good evening, ${name.split(' ')[0]}`;
    }


    // ============== Event Status Message ============== //

    const eventMsg = [];
    let eventCount = 0;

    if (ongoingCount) {
        eventMsg.push(<GreenText key='live'>{ongoingCount} live</GreenText>);
        eventCount += ongoingCount;
    }
    if (upcomingCount) {
        eventMsg.push(<BlueText key='upcoming'>{upcomingCount} upcoming</BlueText>);
        eventCount += upcomingCount;
    }
    if (draftCount) {
        eventMsg.push(<OrangeText key='draft'>{draftCount} draft</OrangeText>);
        eventCount += draftCount;
    }

    let message = [];

    switch (eventMsg.length) {
        case 0:
            message = [<BlackText key={0}>You have no upcoming events</BlackText>];
            break;
        case 1:
            message = [
                <BlackText key={0}>You have </BlackText>,
                eventMsg[0],
                <BlackText key={1}>{eventCount === 1 ? ' event' : ' events'}</BlackText>
            ];
            break;
        case 2:
            message = [
                <BlackText key={0}>You have </BlackText>,
                eventMsg[0],
                <BlackText key={1}> and </BlackText>,
                eventMsg[1],
                <BlackText key={2}> {eventCount === 1 ? 'event' : 'events'}</BlackText>
            ];
            break;
        case 3:
            message = [
                <BlackText key={0}>You have </BlackText>,
                eventMsg[0],
                <BlackText key={1}>, </BlackText>,
                eventMsg[1],
                <BlackText key={2}>, and </BlackText>,
                eventMsg[2],
                <BlackText key={3}> {eventCount === 1 ? 'event' : 'events'}</BlackText>
            ];
            break;
        default:
            message = [<BlackText key={0}></BlackText>];
            break;
    }


    return (
        <Box mb={3} display='flex' flexDirection='column'>
            <Header display='inline'>{greeting}</Header>
            <Box mb={1}>
                {message.map(m => m)}
            </Box>
        </Box>
    )
}


function ShortcutSection(props) {
    const tablet = useMediaQuery(theme => theme.breakpoints.between('sm', 'md'));
    const mobile = useMediaQuery(theme => theme.breakpoints.down('xs'));

    const onSelect = props.onSelect;
    let events = props.events;

    if (tablet) {
        events = events.slice(0,2);
    }

    if (mobile) {
        events = events.slice(0,1)
    }
    
    return (
        <Grid container spacing={4}>
            {events.map(e => (
                <Grid key={e.event_id} item xs={12} sm={6} lg={4}>
                    <EventShortcut
                        id={e.event_id}
                        name={e.event_title}
                        image={e.image_url}
                        status={e.event_status}
                        onClick={() => onSelect(e.event_id)}
                    />
                </Grid>
            ))}
        </Grid>
    )
}


const Header = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: '2.4rem',
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.8rem',
    },
}));
Greeting.defaultProps = { variant: 'h1' }

const BlackText = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[900],
    display: 'inline'
}));

const BlueText = styled(Typography)(({ theme }) => ({
    color: theme.palette.info.main,
    fontWeight: 'bold',
    display: 'inline'
}));

const GreenText = styled(Typography)(({ theme }) => ({
    color: theme.palette.success.main,
    fontWeight: 'bold',
    display: 'inline'
}));

const OrangeText = styled(Typography)(({ theme }) => ({
    color: theme.palette.warning.main,
    fontWeight: 'bold',
    display: 'inline'
}));

const PrimaryStat = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold'
}));
PrimaryStat.defaultProps = { variant: 'h4', gutterBottom: true }

const SecondaryStat = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    display: 'inline',
    marginRight: theme.spacing(0.5),
}));

const SecondaryText = styled(Typography)(({ theme }) => ({
    display: 'inline',
    color: theme.palette.grey[600]
}));

const TableHeadCell = styled(TableCell)(({ theme }) => ({
    ...theme.typography.subHeader
}));

const HostPageLink = styled(TextField)(({ theme }) => ({
    borderColor: theme.palette.secondary.main,
    marginHorizontal: theme.spacing(2),
}));
HostPageLink.defaultProps = { variant: 'outlined', fullWidth: true, }