import React, { useState } from 'react';

// Material-UI
import { styled } from '@material-ui/core/styles';
import { TextField, Box, Grid, Typography, Switch, InputAdornment, FormControlLabel, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

// Material Date Picker
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';

// Components
import { TextArea } from 'components/ui/inputs';
import { Section, Header, Content, CancelButton, SubmitButton } from 'components/ui/forms';
import ProvinceSelect from 'components/forms/ProvinceSelect';
import ImagePicker from 'components/ImagePicker';
import FormSubmitBar from 'components/forms/FormSubmitBar';

// Utility
import useExpresso from 'hooks/useExpresso';
import { validatePostalCode } from 'utility/validation';
import moment from 'moment-timezone';
import { getMomentFromDateAndTime } from 'utility/validation';
import { useNotification } from 'context/notification';
import { CA_TIMEZONES } from 'utility/dates';
//import { useTextInput, useNumberInput, useToggle, useDatePicker } from 'hooks/forms';



// TODO: Refactor this form to use form hooks
//       Starter code is below

// const type          = useTextInput ({ defaultValue: defaultValues?.type         ?? ''                               });
// const title         = useTextInput ({ defaultValue: defaultValues?.title        ?? ''                               });
// const description   = useTextInput ({ defaultValue: defaultValues?.description  ?? ''                               });
// const venueName     = useTextInput ({ defaultValue: defaultValues?.venueName    ?? ''                               });
// const venueAddress  = useTextInput ({ defaultValue: defaultValues?.venueAddress ?? ''                               });
// const city          = useTextInput ({ defaultValue: defaultValues?.city         ?? ''                               });
// const province      = useTextInput ({ defaultValue: defaultValues?.province     ?? ''                               });
// const postalCode    = useTextInput ({ defaultValue: defaultValues?.postalCode   ?? ''                               });
// const country       = useTextInput ({ defaultValue: defaultValues?.country      ?? 'Canada'                         });
// const timezone      = useTextInput ({ defaultValue: defaultValues?.timezone     ?? ''                               });
// const startDate     = useDatePicker({ defaultValue: defaultValues?.startDate    ?? moment({ hour: 12, minute: 0 })  });
// const startTime     = useDatePicker({ defaultValue: defaultValues?.startDate    ?? moment({ hour: 12, minute: 0 })  });
// const endDate       = useDatePicker({ defaultValue: defaultValues?.endDate      ?? moment({ hour: 23, minute: 45 }) });
// const endTime       = useDatePicker({ defaultValue: defaultValues?.endDate      ?? moment({ hour: 23, minute: 45 }) });
// const taxName       = useTextInput ({ defaultValue: defaultValues?.taxName      ?? ''                               });
// const taxRate       = useTextInput ({ defaultValue: defaultValues?.taxRate      ?? ''                               });
// const posTaxRate    = useTextInput ({ defaultValue: defaultValues?.posTaxRate   ?? ''                               });

// const collectTax    = useToggle    ({ defaultValue: Boolean(defaultValues?.taxName && defaultValues?.taxRate)       });
// const collectPosTax = useToggle    ({ defaultValue: Boolean(defaultValues?.taxName && defaultValues?.posTaxRate)    });


/**
 * This function returns the state object containing all of the default values to use
 * Used when the component renders, and when the user wants to undo their changes
 * 
 * NOTE: image, collectTax flags are not set in this function
 */

function initializeFormState(defaultValues) {
    const defaultState = {
        type: { value: defaultValues?.type ?? '', error: false, message: '' },
        title: { value: defaultValues?.title ?? '', error: false, message: '' },
        description: { value: defaultValues?.description ?? '', error: false, message: '' },
        venueName: { value: defaultValues?.venueName ?? '', error: false, message: '' },
        venueAddress: { value: defaultValues?.venueAddress ?? '', error: false, message: '' },
        city: { value: defaultValues?.city ?? '', error: false, message: '' },
        province: { value: defaultValues?.province ?? '', error: false, message: '' },
        postalCode: { value: defaultValues?.postalCode ?? '', error: false, message: '' },
        country: { value: defaultValues?.country ?? 'Canada', error: false, message: '' },
        timezone: { value: defaultValues?.timezone ?? '', error: false, message: ''},
        startDate: { value: defaultValues?.startDate ?? moment({ hour: 12, minute: 0 }), error: false, message: '' },
        startTime: { value: defaultValues?.startDate ?? moment({ hour: 12, minute: 0 }), error: false, message: '' },
        endDate: { value: defaultValues?.endDate ?? moment({ hour: 23, minute: 45 }), error: false, message: '' },
        endTime: { value: defaultValues?.endDate ?? moment({ hour: 23, minute: 45 }), error: false, message: '' },
        taxName: { value: defaultValues?.taxName ?? '', error: false, message: ''},
        taxRate: { value: defaultValues?.taxRate ?? '', error: false, message: ''},
        posTaxRate: { value: defaultValues?.posTaxRate ?? '', error: false, message: ''},
    };

    return defaultState;
}


/**
 * Base form component
 * Can be used for creating events, or editing existing ones
 * Handles all form logic including validation
 */

export default function EventForm(props) {
    // Props
    const { defaultValues, onSubmit, onCancel, loading, editMode, readOnly, enableTaxEditor, enablePosTaxEditor, onFormChange, onFormReset, unsavedChanges } = props;

    // State
    const [image, setImage] = useState(defaultValues?.image ?? null);

    const [values, setValues] = useState(() => initializeFormState(defaultValues));

    const [collectTax, setCollectTax] = useState(Boolean(defaultValues?.taxName && defaultValues?.taxRate));
    const [collectPosTax, setCollectPosTax] = useState(Boolean(defaultValues?.posTaxRate));


    const { type, title, description, venueName, venueAddress, city, province, postalCode, country, startDate, startTime, endDate, endTime, timezone, taxName, taxRate, posTaxRate } = values;

    // Hooks
    const [eventTypes] = useExpresso('/api/event_types'); // TODO: Fetch this data from parent components
    const { notify } = useNotification();


    let rangeError = false;

    // Check if end date comes after start date
    if (!values.startDate.error && !values.endDate.error && !values.startTime.error && !values.endTime.error) {
        const fullStartDate = getMomentFromDateAndTime(values.startDate.value, values.startTime.value)
        const fullEndDate = getMomentFromDateAndTime(values.endDate.value, values.endTime.value)

        if (fullEndDate < fullStartDate) {
            rangeError = true;
        }
    }


    function handleFormReset() {
        setValues(initializeFormState(defaultValues));
        setImage(defaultValues?.image ?? null);
        setCollectTax(Boolean(defaultValues?.taxName && defaultValues?.taxRate));
        setCollectPosTax(Boolean(defaultValues?.posTaxRate));
        onFormReset(); // Turns off unsaved changes flag in parent component
    }


    function handleFormChange(e) {
        if (onFormChange) onFormChange();

        const { id, value } = e.target;

        switch (id) {
            case 'country':
                setValues({
                    ...values,
                    country: { value, error: false, message: '' },
                    province: { value: '', error: false, message: '' },
                    postalCode: { value: '', error: false, message: '' }
                });
                break;

            case 'postalCode':
                const newValue = (values.country.value === 'Canada')
                    ? value.replace(/[^0-9a-zA-Z]/g, '').slice(0, 6).toUpperCase()
                    : value.replace(/[^0-9]/g, '').slice(0, 9);
                setValues({ ...values, postalCode: { value: newValue, error: false, message: '' } });
                break;

            case 'timezone':
                setValues({ ...values, timezone: { ...timezone, value, error: false, message: '' } });
                break;

            default:
                setValues({ ...values, [id]: { value, error: false, message: '' } });
        }
    }

    function handleImageChange(value) {
        if (onFormChange) onFormChange();
        setImage(value);
    }

    function handleSwitchChange(e) {
        if (onFormChange) onFormChange();

        const { id, checked } = e.target;

        if (id === 'collectTax') {
            setCollectTax(checked);
            
            if (checked === false) {
                setValues({ ...values,
                    taxName: { ...values.taxName, error: false, message: '' },
                    taxRate: { ...values.taxRate, error: false, message: '' },
                })
            }
        }
        
        if (id === 'collectPosTax') {
            setCollectPosTax(checked);
            
            if (checked === false) {
                setValues({ ...values,
                    posTaxRate: { ...values.posTaxRate, error: false, message: '' },
                })
            }
        }
    }

    function handlePercentChange(e) {
        if (onFormChange) onFormChange();

        let value = e.target.value.replace(/[^0-9.]/g, '');
        let [whole, decimal] = value.split('.');

        if (whole?.length > 8) {
            whole = whole.slice(0, 8)
        }

        if (decimal?.length > 4) {
            decimal = decimal.slice(0, 4)
        }

        let finalValue = whole;

        if (value.includes('.')) {
            finalValue += '.';
        }

        if (decimal) {
            finalValue += decimal;
        }

        if (e.target.id === 'taxRate') {
            setValues({ ...values, taxRate: { value: finalValue, error: false, message: '' } });
        }

        if (e.target.id === 'posTaxRate') {
            setValues({ ...values, posTaxRate: { value: finalValue, error: false, message: '' } });
        }

    }

    function handleDateChange(id, value) {
        if (onFormChange) onFormChange();

        if (id === 'startDate' && moment(values.endDate.value).isBefore(moment(value))) {
            setValues({...values, startDate: { value, error: false, message: '' }, endDate: { value, error: false, message: '' }});
        } else {
            setValues({ ...values, [id]: { value, error: false, message: '' } });
        }
    }

    function handleSubmit() {
        let newState = { ...values };

        const fieldsToValidate = ['type', 'title', 'description', 'venueName', 'venueAddress', 'city', 'province', 'postalCode', 'timezone'];

        if (collectTax) {
            fieldsToValidate.push('taxName', 'taxRate');
        } else {
            newState.taxName = { ...values.taxName, error: false, message: '' };
            newState.taxRate = { ...values.taxRate, error: false, message: '' };
        }

        if (collectPosTax) {
            fieldsToValidate.push('posTaxRate');
        } else {
            newState.posTaxRate = { ...values.posTaxRate, error: false, message: '' };
        }

        // Check these values for empty strings
        fieldsToValidate.forEach(key => {
            (String(values[key].value).trim() === '')
                ? newState[key] = { ...values[key], error: true, message: 'This field cannot be empty' }
                : newState[key] = { ...values[key] }
        })

        // Check if tax rate is between 0 and 30
        if (collectTax && newState.taxRate.error === false) {
            const taxRate = values.taxRate.value;

            if (taxRate <= 0 || taxRate > 30) {
                newState.taxRate = { ...values.taxRate, error: true, message: 'Please enter a valid tax rate' }
            }
        }

        // Check if tax rate is between 0 and 30
        if (collectPosTax && newState.posTaxRate.error === false) {
            const posTaxRate = values.posTaxRate.value;

            if (posTaxRate <= 0 || posTaxRate > 30) {
                newState.posTaxRate = { ...values.posTaxRate, error: true, message: 'Please enter a valid tax rate' }
            }
        }

        // Check for valid start and end dates
        ['startDate', 'endDate'].forEach((key) => {
            if (!moment(values[key].value).isValid()) {
                newState[key] = { ...values[key], error: true, message: 'Please enter a valid date' }
            } else {
                newState[key] = { ...values[key] }
            }
        });

        // Check for valid start and end times
        ['startTime', 'endTime'].forEach((key) => {
            if (!moment(values[key].value).isValid()) {
                newState[key] = { ...values[key], error: true, message: 'Please enter a valid time' }
            } else {
                newState[key] = { ...values[key] }
            }
        });

        // Check for valid postal code
        if (newState.postalCode.value !== '') {
            if (validatePostalCode(values.postalCode.value, values.country.value) === false) {
                newState.postalCode = { ...values.postalCode, error: true, message: `Please enter a valid ${values.country.value === 'Canada' ? 'postal code' : 'ZIP code'}` }
            } else {
                newState.postalCode = { ...values.postalCode, error: false, message: '' }
            }
        }

        // Check if end date comes after start date
        let  startDateAfterEndDate = false;
        if (!newState.startDate.error && !newState.endDate.error && !newState.startTime.error && !newState.endTime.error) {
            const fullStartDate = getMomentFromDateAndTime(newState.startDate.value, newState.startTime.value)
            const fullEndDate = getMomentFromDateAndTime(newState.endDate.value, newState.endTime.value)

            if (fullEndDate < fullStartDate) {
                startDateAfterEndDate = true;
            }
        }

        // Return if any fields are invalid
        if (Object.keys(newState).some((key) => newState[key].error) || startDateAfterEndDate) {
            setValues(newState);
            notify.warning('Please make sure every field is filled in correctly')
            return null;
        }
        const startDate = getMomentFromDateAndTime(values.startDate.value, values.startTime.value).format('YYYY-MM-DD HH:mm:00');
        const endDate = getMomentFromDateAndTime(values.endDate.value, values.endTime.value).format('YYYY-MM-DD HH:mm:59');

        const formData = {
            eventTypeId: values.type.value,
            title: values.title.value,
            description: values.description.value,
            venue: values.venueName.value,
            address: values.venueAddress.value,
            city: values.city.value,
            province: values.province.value,
            postalCode: values.postalCode.value,
            country: values.country.value,
            startDate: startDate,
            endDate: endDate,
            timezone: values.timezone.value,
            taxName: collectTax ? values.taxName.value : null,
            taxRate: collectTax ? values.taxRate.value : null,
            posTaxRate: collectPosTax ? values.posTaxRate.value : null
        }

        // Edit Mode
        if (editMode) {
            if (image?.includes('https://')) {
                // Preview image hasn't changed. Don't update eventImage
            } else {
                formData.eventImage = image;
            }
        // Create Mode
        } else { 
            formData.eventImage = image;
        }

        onSubmit(formData)
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Box display='flex' flexDirection='column' alignItems='center' width='100%'>

                {readOnly === true && (
                    <Box width='100%' mb={2}>
                        <Alert severity='info'>
                            This event has concluded and cannot be edited
                        </Alert>
                    </Box>
                )}

                <FormContainer>

                    <Grid container spacing={4}>

                        <Grid item xs={12}>
                            <Section>
                                <Header>About</Header>
                                <Typography>
                                    Provide essential details for your event.
                                    Set the <Bold>Title</Bold> to grab purchasers attention.
                                    Use the <Bold>Description</Bold> to convey important details, and select a relevant <Bold>Category</Bold> to help purchasers discover your event easily.
                                </Typography>
                                <Content>
                                    <SdTextField id='title' disabled={readOnly} value={title.value} error={title.error} helperText={title.message} onChange={handleFormChange} label='Event Title' />
                                    <SdTextArea id='description' maxLength={5000} disabled={readOnly} minRows={10} maxRows={10} value={description.value} error={description.error} helperText={description.message} onChange={handleFormChange} label='Event Description' />
                                    <SdTextField id='type' disabled={readOnly} value={type.value} error={type.error} helperText={type.message} onChange={handleFormChange} select InputLabelProps={{ shrink: true }} SelectProps={{ native: true }} label='Event Category'>
                                        <option disabled value=''></option>
                                        {eventTypes && eventTypes.rows.map((e) => (
                                            <option key={e.event_type_id} value={e.event_type_id}>{e.type_name}</option>
                                        ))}
                                    </SdTextField>
                                </Content>
                            </Section>
                        </Grid>

                        <Grid item xs={12}>
                            <Section>
                                <Header>Event Location</Header>
                                <Typography>Input the venue name and its complete address. This information is displayed on the event purchase page, customer receipts, and tickets.</Typography>
                                <Content>
                                    <SdTextField id='venueName' disabled={readOnly} value={venueName.value} error={venueName.error} helperText={venueName.message} onChange={handleFormChange} label='Venue Name' />
                                    <SdTextField id='venueAddress' disabled={readOnly} value={venueAddress.value} error={venueAddress.error} helperText={venueAddress.message} onChange={handleFormChange} label='Venue Address' />
                                    <Grid container>
                                        <Grid item xs={12} sm={6}>
                                            <Box mr={[0, '1rem']}>
                                                <SdTextField id='city' disabled={readOnly} value={city.value} error={city.error} helperText={city.message} onChange={handleFormChange} label='City' />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Box ml={[0, '1rem']}>
                                                <ProvinceSelect id='province' disabled={readOnly} region={country.value} value={province.value} error={province.error} helperText={province.message} onChange={handleFormChange} />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Box mr={[0, '1rem']}>
                                                <SdTextField value={postalCode.value} disabled={readOnly} error={postalCode.error} helperText={postalCode.message} id='postalCode' onChange={handleFormChange} label={country.value === 'Canada' ? 'Postal Code' : 'ZIP'} />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Box ml={[0, '1rem']}>
                                                <SdTextField select id='country' disabled={readOnly} value={country.value} error={country.error} helperText={country.message} onChange={handleFormChange} SelectProps={{ native: true }} label='Country'>
                                                    <option value='Canada'>Canada</option>
                                                    <option value='United States'>United States</option>
                                                </SdTextField>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Content>
                            </Section>
                        </Grid>

                        <Grid item xs={12}>
                            <Section>
                                <Header>Event Date & Time</Header>
                                <Typography>Specify the date and time of your event. This information is displayed on the event purchase page, customer receipts, and tickets.</Typography>
                                <Content>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Box mr={[0, '1rem']}>
                                                <SdDatePicker id='startDate' disabled={readOnly} value={startDate.value} error={startDate.error || rangeError} helperText={startDate.message} onChange={value => handleDateChange('startDate', value)} label='Start Date' />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box ml={[0, '1rem']}>
                                                <SdTimePicker id='startTime' disabled={readOnly} value={startTime.value} error={startTime.error || rangeError} helperText={startTime.message} onChange={value => handleDateChange('startTime', value)} label='Start Time' />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box mr={[0, '1rem']}>
                                                <SdDatePicker id='endDate' disabled={readOnly} value={endDate.value} error={endDate.error || rangeError} helperText={endDate.message} onChange={value => handleDateChange('endDate', value)} label='End Date' />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box ml={[0, '1rem']}>
                                                <SdTimePicker id='endTime' disabled={readOnly} value={endTime.value} error={endTime.error || rangeError} helperText={endTime.message} onChange={value => handleDateChange('endTime', value)} label='End Time' />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    {rangeError && <ErrorMessage>End date cannot come before start date</ErrorMessage>}
                                    <SdTextField id='timezone' disabled={readOnly} value={timezone.value} error={timezone.error} helperText={timezone.message} onChange={handleFormChange} select InputLabelProps={{ shrink: true }} SelectProps={{ native: true }} label='Timezone'>
                                        {timezone.value === '' && <option value=''></option>}
                                        {CA_TIMEZONES.map((tz) => <option key={tz.name} value={tz.name}>{tz.fullName}</option>)}
                                    </SdTextField>
                                    <DateSummary
                                        startDate={startDate.value}
                                        startTime={startTime.value}
                                        endDate={endDate.value}
                                        endTime={endTime.value}
                                    />
                                </Content>
                            </Section>
                        </Grid>

                        <Grid item xs={12}>
                            <Section>
                                <Header>Event Image</Header>
                                <Typography>Upload an image for your event. It will be showcased on the event purchase page.</Typography>
                                <Content mb={2}>
                                    <ImagePicker
                                        disabled={readOnly}
                                        value={image}
                                        onChange={handleImageChange}
                                    />
                                </Content>
                            </Section>
                        </Grid>

                        <Grid item xs={12}>
                            <Section>
                                <Header>Do you Need to Charge Tax</Header>
                                {(enableTaxEditor && enablePosTaxEditor)
                                    ? <Typography>Do you need to charge tax on top of your ticket prices? If yes, enter the tax name and tax rate below. Taxes will be calculated during checkout, and added to the final total. Leave this off if you are not required to collect tax, or if taxes are included in the advertised price of your tickets.</Typography>
                                    : <Typography>Tax information cannot be updated after tickets have been sold. If you need to change this, contact us at hello@frontdoorplus.com</Typography>
                                }
                                <Content>
                                    <Box width='100%' mb={2}>
                                        <FormControlLabel disabled={!enableTaxEditor || readOnly} control={<Switch id='collectTax' checked={collectTax} onChange={handleSwitchChange} />} label="Add Tax Line to Online Orders" />
                                    </Box>
                                    {collectTax && (
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Box mr={[0, '1rem']}>
                                                    <SdTextField
                                                        id='taxName'
                                                        label='Tax Name e.g. HST'
                                                        placeholder='Ex. HST, GST, QST'
                                                        value={collectTax ? values.taxName.value : ''}
                                                        error={values.taxName.error && collectTax}
                                                        helperText={taxName.message}
                                                        disabled={!collectTax || !enableTaxEditor || readOnly}
                                                        onChange={handleFormChange}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box ml={[0, '1rem']}>
                                                    <SdTextField
                                                        id='taxRate'
                                                        label='Tax Rate'
                                                        value={collectTax ? values.taxRate.value : ''}
                                                        error={values.taxRate.error && collectTax}
                                                        helperText={taxRate.message}
                                                        disabled={!collectTax || !enableTaxEditor || readOnly}
                                                        onChange={handlePercentChange}
                                                        InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )}

                                    <Box width='100%' mb={2}>
                                        <FormControlLabel disabled={!enablePosTaxEditor || readOnly} control={<Switch id='collectPosTax' checked={collectPosTax} onChange={handleSwitchChange} />} label="Add Tax Line to In-Person Orders" />
                                    </Box>
                                    {collectPosTax && (
                                        <Grid container>
                                            <Grid item xs={6}>
                                                <Box mr={[0, '1rem']}>
                                                    <SdTextField
                                                        id='posTaxRate'
                                                        label='POS Tax Rate'
                                                        value={collectPosTax ? values.posTaxRate.value : ''}
                                                        error={values.posTaxRate.error && collectPosTax}
                                                        helperText={posTaxRate.message}
                                                        disabled={!collectPosTax || !enablePosTaxEditor || readOnly}
                                                        onChange={handlePercentChange}
                                                        InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Content>
                            </Section>
                        </Grid>
                    </Grid>

                    <Box mt={4} mb={4}>
                        {defaultValues.createdAtMsg && (
                            <Typography display='block' align='right' variant='caption'>{defaultValues.createdAtMsg}</Typography>
                        )}
                        
                        {defaultValues.updatedAtMsg && (
                            <Typography display='block' align='right' variant='caption'>{defaultValues.updatedAtMsg}</Typography>
                        )}
                        {editMode !== true && (
                            <Box display='flex' justifyContent='flex-end'>
                                <CancelButton onClick={onCancel} disabled={loading || readOnly}>Cancel</CancelButton>
                                <SubmitButton onClick={handleSubmit} disabled={loading || readOnly}>
                                    {loading ? <CircularProgress color='secondary' size={18} /> : 'Save'}
                                </SubmitButton>
                            </Box>
                        )}
                        {editMode === true && (
                            <FormSubmitBar
                                disabled={readOnly}
                                unsavedChanges={unsavedChanges}
                                loading={loading}
                                onCancel={handleFormReset}
                                onSubmit={handleSubmit}
                            />
                        )}
                    </Box>

                </FormContainer>

            </Box>


        </MuiPickersUtilsProvider>
    )
}


function DateSummary(props) {
    const { startDate, startTime, endDate, endTime } = props;

    if (
        moment(startDate).isValid() === false ||
        moment(endDate).isValid()   === false ||
        moment(startTime).isValid() === false ||
        moment(endTime).isValid()   === false
    ) {
        return null;
    }

    const start = getMomentFromDateAndTime(startDate, startTime);
    const end = getMomentFromDateAndTime(endDate, endTime);
    const text = `${start.format('dddd, MMM Do @ h:mma')} -to- ${end.format('dddd, MMM Do @ h:mma')}`;

    if (start > end) {
        return <DateSummaryTextError>{text}</DateSummaryTextError>
    } else {
        return <DateSummaryText>{text}</DateSummaryText>
    }

}

const FormContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginTop: 4,
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
    }
}));

const SdTextField = styled(TextField)({
    marginBottom: '24px',
    width: '100%'
})
SdTextField.defaultProps = { variant: 'outlined' }

const SdTextArea = styled(TextArea)({
    marginBottom: '24px',
    width: '100%'
})

const SdDatePicker = styled(KeyboardDatePicker)({
    marginBottom: '24px',
    width: '100%'
});
SdDatePicker.defaultProps = { format: 'yyyy-MM-dd', inputVariant: 'outlined' }

const SdTimePicker = styled(KeyboardTimePicker)({
    marginBottom: '24px',
    width: '100%'
});
SdTimePicker.defaultProps = { format: 'hh:mm a', inputVariant: 'outlined' }

const ErrorMessage = styled('p')(({ theme }) => ({
    color: theme.palette.error.main,
    marginTop: 0,
    marginBottom: theme.spacing(4)
}));

const DateSummaryText = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.palette.info.dark
}));

const DateSummaryTextError = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.palette.error.main
}));

const Bold = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    display: 'inline'
}));