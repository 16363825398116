import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useNotification } from 'context/notification';
import fetchExpresso from 'utility/fetchExpresso';
import ProductForm from 'components/forms/ProductForm';
import moment from 'moment-timezone';
import FullScreenModal from 'components/popups/FullScreenModal';


export default function AddonCreateModal(props) {
    const { open, onClose } = props;

    return (
        <FullScreenModal open={open} onClose={onClose} title='Create Add-on' cancelText='Cancel'>
            {open === true && (
               <AddonCreate {...props} /> 
            )}
        </FullScreenModal>
    )
}


function AddonCreate(props) {
    const { hostId, eventId, onCreate, onCancel } = props;

    const [loading, setLoading] = useState(false);

    const [init, setInit] = useState({
        status: 'loading', // loading, error, success
        eventName: '',
        defaultValues: null
    });


    const { notify } = useNotification();


    // Fetch event start/end dates
    // Fetch latest product types
    useEffect(() => {
        (async () => {
            try {
                const eventRequest = await fetchExpresso(`/apiv2/events/${eventId}`);
    
                if (eventRequest.status !== 200) {
                    throw new Error();
                }
    
                const event = await eventRequest.json();
    
                setInit({
                    status: 'success',
                    eventName: event.event_title,
                    enableSponsor: event.sponsor_module_flag === 1,
                    defaultValues: {
                        type: 2, // Add-on
                        startDate: moment(event.start_date),
                        endDate: moment(event.end_date),
                        eventStartDate: moment(event.start_date),
                        eventEndDate: moment(event.end_date),
                        saleStartDate:moment({ hour: 0, minute: 0 }),
                        saleEndDate: moment(event.end_date)
                    }
                })
            }
            catch(e) {
                setInit({
                    status: 'error',
                    eventName: '',
                    defaultValues: null
                })
            }
        })()
    }, [eventId]);

    
    const handleSubmit = (formData) => {
        if (loading) return;
        setLoading(true);

        fetchExpresso(`/apiv2/products/`, {
            method: 'POST',
            body: {
                ...formData,
                eventId: eventId
            }
        })
            .then(res => {
                if (res.status === 200) {
                    notify.success('Ticket Created');
                    onCreate();
                } else {
                    notify.error('There was a problem creating your ticket');
                    setLoading(false);
                }
            })
            .catch((e) => {
                notify.error('There was a problem creating your ticket');
                setLoading(false);
            })
    }


    // Waiting for default values to load
    if (init.status === 'loading') {
        return (
            <Box display='flex' justifyContent='center' alignItems='center' width={1} height={1}>
                <CircularProgress />
            </Box>
        )
    }
    
    // Error loading data for product form
    if (init.status === 'error') {
        return (
            <Box display='flex' justifyContent='center' alignItems='center' width={1} height={1}>
                <Typography align='center'>There was a problem loading your form</Typography>
            </Box>
        )
    }
    
    return (
        <ProductForm
            hostId={hostId}
            eventId={eventId}
            autoFocus
            defaultValues={init.defaultValues}
            eventName={init.eventName}
            enableSponsor={init.enableSponsor}
            onSubmit={handleSubmit}
            onCancel={onCancel}
            loading={loading}
        />
    );
}