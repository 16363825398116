import React, { useState, useEffect } from 'react';

// Material UI
import { Page } from 'components/ui/layout';
import { Box, Button, styled, IconButton, Typography, CircularProgress, Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import PersonIcon from '@material-ui/icons/Person';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

// Components
import { Section, Header, Content, SubmitButton } from 'components/ui/forms';
import { ActionButton } from 'components/ui/buttons';
import QuestionCreator from 'components/forms/QuestionCreator';
import QuestionEditor from 'components/forms/QuestionEditor';
import FullScreenModal from 'components/popups/FullScreenModal';
import Tooltip from 'components/popups/Tooltip';
import { LoadingPage, ErrorPage } from 'components/ui/states';

// Utility
import fetchExpresso from 'utility/fetchExpresso';
import { useNotification } from 'context/notification';


export default function CheckoutForm(props) {
    const eventId = props.match.params.id;

    const [modal, setModal] = useState({
        open: false,
        questionId: null
    });

    const [questions, setQuestions] = useState({
        status: 'loading', // loading, error, success
        original: [],
        display: [],
        sort: false,
        refresh: 0
    });

    const [status, setStatus] = useState({
        current: null, // 0 (disabled), 1 (order), 2 (attendee)
        next: null, // 0 (disabled), 1 (order), 2 (attendee)
        loading: false
    });

    const { notify } = useNotification();

    useEffect(() => {
        async function init() {
            try {
                const results = await Promise.all([
                    fetchExpresso(`/apiv2/events/${eventId}/registration`),
                    fetchExpresso(`/apiv2/events/${eventId}/registration/questions`)
                ]);
    
                if (results[0].status !== 200 || results[1].status !== 200) {
                    throw new Error();
                }
    
                const { event }     = await results[0].json();
                const { questions } = await results[1].json();

                setStatus(s => ({ ...s, current: event.checkout_question_mode }));
    
                setQuestions(q => ({
                    ...q,
                    status:   'success',
                    original: questions.map((q,i) => ({ ...q, order: i + 1, highlight: false })),
                    display:  questions.map((q,i) => ({ ...q, order: i + 1, highlight: false })),
                }))
            } catch(error) {
                setQuestions(q => ({ ...q, status: 'error' }))
            }
        }
        init();
    }, [eventId, questions.refresh]);


    const updateQuestionStatus = (next) => {
        if (status.loading) return;
        setStatus(s => ({ ...s, next: next, loading: true }));

        fetchExpresso(`/apiv2/events/${eventId}/registration`, {
            method: 'PUT',
            body: { mode: next }
        })
            .then(res => {
                if (res.status !== 200) throw new Error();
                notify.success('Event Updated');
                setStatus(s => ({
                    ...s,
                    current: next,
                    next: null,
                    loading: false
                }))
            })
            .catch(() => {
                setStatus(s => ({ ...s, next: null, loading: false }))
                notify.error('Something went wrong');
            })
    };


    const createQuestion = () => setModal({ open: true,  questionId: null });
    const editQuestion = (id) => setModal({ open: true,  questionId: id   });

    const closeQuestion  = () => setModal({ open: false, questionId: null });
    

    const handleQuestionUpdate = () => {
        closeQuestion();
        setQuestions(q => ({ ...q, refresh: q.refresh + 1 }));
    };

    function handleSort(id, direction) {
        const display = JSON.parse(JSON.stringify(questions.display)); // Deep clone

        const targetIndex = display.findIndex(q => q.q_id === id); // Get index of question to move
        const destinationIndex = direction === 'up' ? targetIndex - 1 : targetIndex + 1; // Get index of question to swap with
        
        const targetQuestion = display[targetIndex];
        const destinationQuestion = display[destinationIndex];

        const questionList = JSON.parse(JSON.stringify(display)); // Deep clone

        questionList[targetIndex] = destinationQuestion;
        questionList[destinationIndex] = targetQuestion;

        // Highlight the question that was just moved
        for (let i = 0; i < questionList.length; i++) {
            if (i === destinationIndex) {
                questionList[i].highlight = true;
            } else {
                questionList[i].highlight = false;
            }
        }

        setQuestions(q => ({ ...q, display: questionList }));
    }


    function handleSortSubmit() {
        fetchExpresso(`/apiv2/events/${eventId}/registration/questions`, {
            method: 'PUT',
            body: { sort: questions.display.map(q => q.q_id) }
        })
            .then(res => {
                if (res.status === 200) {
                    setQuestions(q => ({ ...q, sort: false, refresh: q.refresh + 1 }));
                    notify.success('Questions have been re-ordered');
                } else {
                    notify.error('Unable to change order');
                }
            })
            .catch(() => {
                notify.error('Unable to change order');
            });
    }

    function handleSortStart() {
        setQuestions(q => ({ ...q, sort: !q.sort }));
    }

    function handleSortCancel() {
        setQuestions(q => ({ ...q, display: q.original, sort: !q.sort }));
    }


    if (questions.status === 'loading') {
        return <LoadingPage />
    }

    if (questions.status === 'error') {
        return <ErrorPage message='Unable to load checkout questions' />
    }


    return (
        <Page>

            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Section>
                        <Header>Ask Questions at Checkout</Header>
                        <Typography>You can require users to fill in a form when they purchase tickets.</Typography>
                        <Content>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6} lg={4}>
                                    <OptionPickerItem
                                        title='Disabled'
                                        description="Your customers won't be asked any questions during checkout"
                                        icon={NotInterestedIcon}
                                        checked={status.current === 0}
                                        pending={status.next === 0}
                                        disabled={status.loading}
                                        onClick={() => updateQuestionStatus(0)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <OptionPickerItem
                                        title='Ticket Order'
                                        description="The customer making the order will be asked to fill out your checkout questions"
                                        icon={PersonIcon}
                                        checked={status.current === 1}
                                        pending={status.next === 1}
                                        disabled={status.loading}
                                        onClick={() => updateQuestionStatus(1)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <OptionPickerItem
                                        title='Attendee'
                                        description="Each attendee of the order will be asked to fill our your checkout questions"
                                        icon={GroupAddIcon}
                                        checked={status.current === 2}
                                        pending={status.next === 2}
                                        disabled={status.loading}
                                        onClick={() => updateQuestionStatus(2)}
                                    />
                                </Grid>
                            </Grid>

                            {status.current === 2 && (
                                <ProductPicker eventId={eventId} />
                            )}
                        </Content>
                    </Section>
                </Grid>
                <Grid item xs={12}>
                    <Section>
                        <Header>Checkout Questions</Header>
                        <Typography>Enter your questions here. We recommend to keep the questions to a minimum. The longer the checkout process takes, the less likely customers will be to complete their purchase.</Typography>
                        <Content>
                            <QuestionTable
                                questions={questions.display}
                                onNewQuestion={createQuestion}
                                onQuestionSelect={editQuestion}
                                onSort={handleSort}
                                enableSort={questions.sort}
                                onSortStart={handleSortStart}
                                onSortCancel={handleSortCancel}
                                onSortSubmit={handleSortSubmit}
                            />
                        </Content>
                    </Section>
                </Grid>
            </Grid>

            <FullScreenModal open={modal.open} onClose={closeQuestion} title={modal.questionId ? 'Edit Question' : 'Create Question'} cancelText='Cancel'>
                {modal.questionId ? (
                    <QuestionEditor
                        questionId={modal.questionId}
                        eventId={eventId}
                        onEdit={handleQuestionUpdate}
                        onCancel={closeQuestion}
                    />
                ) : (
                    <QuestionCreator
                        eventId={eventId}
                        onCreate={handleQuestionUpdate}
                        onCancel={closeQuestion}
                    />
                )}
            </FullScreenModal>
        </Page>
    )
}


const ProductPicker = (props) => {
    const { eventId } = props;

    const [products, setProducts] = useState([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        fetchExpresso(`/apiv2/events/${eventId}/registration/attendee-products`)
            .then(res => res.json())
            .then(data => {
                if (Array.isArray(data.products)) {
                    setProducts(
                        data.products.map(p => ({
                            id: p.product_id,
                            name: p.prod_name,
                            checked: p.is_attendee === 1
                        }))
                    )
                }
            })
            .catch(() => setError(true))
            .finally(() => setLoading(false))
    }, []);

    const handleChange = (event) => {
        const id = Number(event.target.id);
        const checked = event.target.checked;

        setProducts(products.map(p => {
            if (p.id === id) {
                return ({ ...p, checked: checked });
            } else {
                return p;
            }
        }))
    };

    const handleSave = () => {
        if (saving) return;
        setSaving(true);

        fetchExpresso(`/apiv2/events/${eventId}/registration/attendee-products`, {
            method: 'PUT',
            body: {
                products: products
                    .filter(p => p.checked)
                    .map(p => p.id)
            }
        })
            .then(res => {
                if (res.status !== 200) throw new Error();
            })
            .catch(() => setError(true))
            .finally(() => setSaving(false))
    };

    return (
        <Box mt={4}>
            <Header>Attendee Tickets</Header>
            <Typography>What tickets do you want to apply the order form to?</Typography>

            <Box mt={2} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                {products.map(p => (
                    <FormControlLabel
                        key={p.id}
                        label={p.name}
                        control={
                            <Checkbox
                                id={p.id}
                                checked={p.checked}
                                onChange={handleChange}
                            />
                        }
                    />
                ))}
            </Box>

            {products.length > 0 && (
                <ButtonContainer>
                    <SubmitButton onClick={handleSave}>Save</SubmitButton>
                </ButtonContainer>
            )}
        </Box>
    )
};


const OptionPickerItem = (props) => {
    const Container   = props.checked ? OptionActive : OptionDisabled;
    const Title       = props.checked ? TitleActive : TitleDisabled;
    const Description = props.checked ? DescriptionActive : DescriptionDisabled;
    const Icon = props.icon;


    if (props.pending) {
        return (
            <Container>
                <Box height='100%' display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <CircularProgress color='secondary' />
                </Box>
            </Container>
        )
    }

    return (
        <Container onClick={props.disabled ? undefined : props.onClick}>
            <Box display={'flex'}>
                <Icon style={{ color: props.checked ? '#fff' : '#000'}} />
                <Title>{props.title}</Title>
            </Box>
            <Description>{props.description}</Description>
        </Container>
    )
};


const QuestionTable = ({ questions, onNewQuestion, onQuestionSelect, onSort, enableSort, onSortStart, onSortCancel, onSortSubmit }) => {

    if (questions.length === 0) {
        return (
            <Box display='flex' justifyContent={'center'} mt={2}>
                <ActionButton onClick={onNewQuestion}>New Question</ActionButton>
            </Box>
        )
    }

    return (<>
        <Table>
            <TableBody>
                {questions.length === 0 && (
                    <TableRow>
                        <TableCell align='center' colSpan={3}>
                            <ActionButton onClick={onNewQuestion}>New Question</ActionButton>
                        </TableCell>
                    </TableRow>
                )}

                {questions.map((question, index) => (
                    <TableRow key={question.q_id} style={{ backgroundColor: question.highlight ? '#eee' : 'white'}}>
                        <TableCell>#{question.order}</TableCell>
                        <TableCell>{question.q_desc}</TableCell>
                        {enableSort === true ? (
                            <TableCell align='right' width={180}>
                                <ArrowButton disabled={index === 0} onClick={() => onSort(question.q_id, 'up')}>
                                    <ArrowDropUpIcon />
                                </ArrowButton>
                                <ArrowButton disabled={index === questions.length - 1} onClick={() => onSort(question.q_id, 'down')}>
                                    <ArrowDropDownIcon />
                                </ArrowButton>
                            </TableCell>
                        ) : (
                            <TableCell align='right' width={180}>
                                <ActionButton style={{ marginLeft: 8 }} onClick={() => onQuestionSelect(question.q_id)}>Edit</ActionButton>
                            </TableCell>
                        )}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        {enableSort === true ? (
            <Box display='flex' justifyContent={'flex-end'} mt={2}>
                <CancelButton onClick={onSortCancel}>Cancel</CancelButton>
                <SaveButton onClick={onSortSubmit}>Save</SaveButton>
            </Box>
        ) : (
            <Box display='flex' justifyContent={'flex-end'} mt={2}>
                <ActionButton onClick={onNewQuestion}>New Question</ActionButton>
                <Tooltip message='Your customers will see your questions in the order listed above. You can change the Display Order here.'>
                    <span style={{ marginLeft: 8 }}>
                        <ActionButton startIcon={<SwapVertIcon />} onClick={onSortStart}>Set Display Order</ActionButton>
                    </span>
                </Tooltip>
            </Box>
        )}
    </>)
}


const OptionActive = styled(Box)(({ theme }) => ({
    height: '114px',
    border: '1.5px solid',
    borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 12,
    padding: theme.spacing(1)
}));

const OptionDisabled = styled(Box)(({ theme }) => ({
    height: '114px',
    border: '1.5px solid',
    borderColor: theme.palette.grey[400],
    borderRadius: 12,
    padding: theme.spacing(1)
}));

const TitleActive = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1)
}));

const TitleDisabled = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.palette.common.black,
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1)
}));

const DescriptionActive = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.white
}));

const DescriptionDisabled = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.black
}));


const ButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end'
}));

const SaveButton = styled(Button)(({ theme }) => ({
    color: theme.palette.success.main,
    marginRight: theme.spacing(1)
}));

const CancelButton = styled(Button)(({ theme }) => ({
    color: theme.palette.warning.main,
    marginRight: theme.spacing(1)
}));

const ArrowButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.grey[200],
    marginLeft: theme.spacing(1)
}));
ArrowButton.defaultProps = { size: 'small', disableRipple: true };

const Loading = styled(CircularProgress)(({ theme }) => ({
    color: theme.palette.secondary.main
}));
Loading.defaultProps = { size: 16 }
