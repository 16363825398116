import React, { useState, useEffect } from 'react';

// Material-UI
import { Box, Grid, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

// Components
import PerMinuteOrders from 'components/admin/PerMinuteOrders';
import PerMinuteTaps from 'components/admin/PerMinuteTaps';
import PerMinuteScans from 'components/admin/PerMinuteScans';
import TopHosts from 'components/admin/TopHosts';
import TopEvents from 'components/admin/TopEvents';
import ScanStatus from 'components/admin/ScanStatus';
import EmailStatus from 'components/admin/EmailStatus';
import SitemapStatus from 'components/admin/SitemapStatus';
import RecentOrders from 'components/admin/RecentOrders';
import RecentScans from 'components/admin/RecentScans';
import TicketsByDay from 'components/admin/TicketsByDay';
import ScansByEvent from 'components/admin/ScansByEvent';
import TapsByEvent from 'components/admin/TapsByEvent';

// Utility
import fetchExpresso from 'utility/fetchExpresso';
import { AdminPage } from 'components/ui/layout';


export default function Admin(props) {

    const [warnings, setWarnings] = useState({
        payout: null,
        connect: null
    });

    // *** Generate warning messages where applicable *** //
    useEffect(() => {
        Promise.all([
            fetchExpresso('/admin/reports/payout_count').then(res => res.json()),
            fetchExpresso('/admin/reports/connect_stats').then(res => res.json()),
        ])
            .then(results => {
                const payouts = results[0].unpaid_events_count;
                const attention = results[1].counts.needs_attention;
                const onboarding = results[1].counts.onboarding_incomplete;

                let payoutWarning = null;
                let connectWarning = null;

                if (payouts === 1) {
                    payoutWarning = 'PAYOUT: 1 event needs to be paid out';
                }
                if (payouts > 1) {
                    payoutWarning = `PAYOUT: ${payouts} events need to be paid out`;
                }

                if (attention > 0 || onboarding > 0) connectWarning = 'CONNECT: ';

                if (attention === 1)  connectWarning += '1 account needs attention'
                if (attention > 1)    connectWarning += `${attention} accounts need attention`;

                if (attention > 0 && onboarding > 0) connectWarning += ' | ';

                if (onboarding === 1) connectWarning += '1 account needs onboarding'
                if (onboarding > 1)   connectWarning += `${onboarding} accounts need onboarding`;

                setWarnings({
                    payout: payoutWarning,
                    connect: connectWarning,
                })

            })
            .catch((e) => console.log(e))
    }, []);


    return (
        <AdminPage>

            {warnings.payout ? (
                <Box mb={2}>
                    <Alert
                        severity='error'
                        action={<Button color="inherit" size="small" onClick={() => props.history.push('/admin/events?tab=unpaid')}>View</Button>}
                    >
                        {warnings.payout}
                    </Alert>
                </Box>
            ) : null}
            
            {warnings.connect ? (
                <Box mb={2}>
                    <Alert
                        severity='warning'
                        action={<Button color="inherit" size="small" onClick={() => props.history.push('/admin/hosts?tab=warning')}>View</Button>}
                    >
                        {warnings.connect}
                    </Alert>
                </Box>
            ) : null}

            <Grid container spacing={4}>
                <Grid item xs={6} md={4}>
                    <PerMinuteOrders />
                </Grid>
                <Grid item xs={6} md={4}>
                    <PerMinuteTaps />
                </Grid>
                <Grid item xs={6} md={4}>
                    <PerMinuteScans />
                </Grid>
                <Grid item xs={12}>
                    <TicketsByDay />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TopEvents />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TopHosts />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ScansByEvent />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ScanStatus />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TapsByEvent />
                </Grid>
                <Grid item xs={12} md={6}>
                    <EmailStatus />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SitemapStatus />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <RecentOrders />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <RecentScans />
                </Grid>
            </Grid>

        </AdminPage>
    )
}