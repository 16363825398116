import React, { useState, useEffect } from 'react';

// Material-UI
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { styled, CircularProgress, Typography, Box, Button } from '@material-ui/core';

import Tooltip from 'components/popups/Tooltip';

// Utility
import { abbreviateNumber } from 'utility/numbers';
import fetchExpresso from 'utility/fetchExpresso';
import moment from 'moment-timezone';



export default function TopEvents() {

    const [range, setRange] = useState('day'); // day, month, year

    const [state, setState] = useState({
        data: null,
        loading: true,
        error: false
    });

    const { data, loading, error } = state;


    useEffect(() => {
        fetchExpresso(`/admin/reports/top_events?tz=${moment.tz.guess()}&start=${moment().startOf(range).format('YYYY-MM-DDTHH:mm:ss')}&end=${moment().endOf(range).format('YYYY-MM-DDTHH:mm:ss')}`)
            .then(async res => {
                if (res.status !== 200) {
                    setState(s => ({ ...s, loading: false, error: true }));
                    return;
                }
                const data = await res.json();
                setState(s => ({ ...s, data: data, loading: false, error: false }));
            })
            .catch(e => {
                setState(s => ({ ...s, loading: false, error: true }));
            })
    }, [range]);

    if (loading) {
        return (
            <Root>
                <Status>
                    <CircularProgress disableShrink />
                </Status>
            </Root>
        );
    }

    if (error) {
        return (
            <Root>
                <Status>
                    <Typography>Unable to load stats</Typography>
                </Status>
            </Root>
        );
    }


    return (
        <Root>

            <CardHeader
                title='Top Events'
                action={(
                    <Box>
                        <Button style={range === 'day' ? {textDecoration: 'underline'} : undefined} onClick={() => setRange('day')}>Today</Button>
                        <Button style={range === 'month' ? {textDecoration: 'underline'} : undefined} onClick={() => setRange('month')}>This Month</Button>
                        <Button style={range === 'year' ? {textDecoration: 'underline'} : undefined} onClick={() => setRange('year')}>This Year</Button>
                    </Box>
                )}
            />

            <CardContent>

                <TableContainer component={Box}>

                    <Table size='small'>

                        <TableHead>

                            <TableRow>
                                <TableCellBold colSpan={3} align='right'>TIX</TableCellBold>
                                <TableCellBold align='right'>PRC</TableCellBold>
                                <TableCellBold align='right'>FD</TableCellBold>
                            </TableRow>

                        </TableHead>

                        <TableBody>

                            {data.map((r, index) => (
                                <TableRow key={r.event_id}>
                                    <RankCell width={20} align='center'>{index + 1}</RankCell>
                                    <EventTitleCell><EventTitleText noWrap variant='body2'>{r.event_title}</EventTitleText></EventTitleCell>
                                    <TableCell width={30} align='right'>
                                        <Tooltip color='grey' message={(
                                            <Box>
                                                <Typography>Online: {r.total_tickets_online}</Typography>
                                                <Typography>POS: {r.total_tickets_pos}</Typography>
                                            </Box>
                                        )}>
                                            <Typography variant='body2'>{r.total_tickets}</Typography>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell width={30} align='right'>
                                        <Tooltip color='grey' message={(
                                            <Box>
                                                <Typography>Online: ${abbreviateNumber(r.total_amount_online, 0, 1)}</Typography>
                                                <Typography>POS: ${abbreviateNumber(r.total_amount_pos, 0, 1)}</Typography>
                                                <Typography>Cash: ${abbreviateNumber(r.total_amount_cash, 0, 1)}</Typography>
                                            </Box>
                                        )}>
                                            <Typography variant='body2'>{`$${abbreviateNumber(r.total_amount, 0, 1)}`}</Typography>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell width={30} align='right'>
                                        <Tooltip color='grey' message={(
                                            <Box>
                                                <Typography>Online: ${abbreviateNumber(r.our_fee_total_online, 0, 1)}</Typography>
                                                <Typography>POS: ${abbreviateNumber(r.our_fee_total_pos, 0, 1)}</Typography>
                                            </Box>
                                        )}>
                                            <Typography variant='body2'>{`$${abbreviateNumber(r.our_fee_total, 0, 1)}`}</Typography>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}

                            {data.length === 0 && (
                                <TableRow>
                                    <TableCell align='center' colSpan={5}>
                                        <Typography variant='subtitle2'>No data found</Typography>
                                    </TableCell>
                                </TableRow>
                            )}

                        </TableBody>

                    </Table>

                </TableContainer>

            </CardContent>

        </Root>
    )
}

const Root = styled(Card)({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width :'100%'
});

const Status = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
});

const TableCellBold = styled(TableCell)(({ theme }) => ({
    fontWeight: 'bold'
}));

const RankCell = styled(TableCell)(({ theme }) => ({
    padding: 0,
    fontWeight: 'bold'
}));

const EventTitleCell = styled(TableCell)(({ theme }) => ({
    maxWidth: 320,
    [theme.breakpoints.down('lg')]: {
        maxWidth: 160
    },
    [theme.breakpoints.down('md')]: {
        maxWidth: 100
    },
    [theme.breakpoints.down('sm')]: {
        maxWidth: 220
    },
}));

const EventTitleText = styled(Typography)({
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
});