import React, { useState, useEffect } from 'react';
import { styled, Box, Button, Typography, Grid, Card as _Card, CardContent as _CardContent, CardActions, CircularProgress, Link } from '@material-ui/core';
import { FormControl, FormGroup, FormControlLabel, Checkbox, FormHelperText } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Page, ControlBar, ControlBarHeader } from 'components/ui/layout';
import { ActionButton } from 'components/ui/buttons';
import { useNotification } from 'context/notification';
import ReactGA from 'react-ga';
import { downloadFile } from 'utility/browser';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useAuth } from 'context/auth';
import fetchExpresso from 'utility/fetchExpresso';
import moment from 'moment-timezone';

import AssignmentIcon from '@material-ui/icons/Assignment';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import ReceiptIcon from '@material-ui/icons/Receipt';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import { useEvent } from 'context/event';


export default function Downloads(props) {
    const { auth } = useAuth();
    
    const eventId = props.match.params.id;
    const hostId = auth.hostId;

    return (
        <Page>

            <ControlBar>
                <ControlBarHeader>Reports</ControlBarHeader>
            </ControlBar>

            <Box mb={4}>
                <Alert severity='info'>
                    All files are generated in real-time, providing you with the most up-to-date data available.
                </Alert>
            </Box>

            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <SalesReports
                        eventId={eventId}
                        hostId={hostId}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <PosSalesReports
                        eventId={eventId}
                        hostId={hostId}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CheckinReports
                        eventId={eventId}
                        hostId={hostId}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <GuestList
                        eventId={eventId}
                        hostId={hostId}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <EmailExport
                        eventId={eventId}
                        hostId={hostId}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MobileApps
                        hostId={hostId}
                    />
                </Grid>
            </Grid>

        </Page>
    )
}


function GuestList({ eventId, hostId, alert }) {
    const [loading, setLoading] = useState(false);
    const [format, setFormat] = useState(null); // Shows a confirmation modal when a format is selected

    const { notify } = useNotification();

    async function download(products) {
        const prodFilter = products.toString();

        try {
            if (loading) return;
            setLoading(true);
            ReactGA.event({ category: "Orders", action: 'Download Guest List', label: `${hostId}` });

            const res = await fetchExpresso(`/apiv2/events/${eventId}/guest_list?format=${format}&products=${prodFilter}`);
            
            if (res.status === 200) {
                let file;
                if (format === 'pdf') file = await res.text();
                if (format === 'xlsx') file = await res.blob();
                downloadFile(`Guest-List-${moment().format('YYYY-MM-DD')}.${format}`, file);
            } else if (res.status === 204) {
                notify.warning('No guests found');
            } else {
                throw new Error();
            }
        } catch(e) {
            notify.error(e.errorMsg || 'There was a problem downloading your guest list');
        }
        setLoading(false);
        setFormat(null);
    }

    return (
        <Card>
            <CardHeader>
                <AssignmentIcon />
                <Title>Guest List</Title>
            </CardHeader>
            <CardContent>
                <Typography>Download a comprehensive list of attendees and their ticket purchases. Perfect for creating printed guest lists at your event's entry point. Alternatively, use the <Bold>FrontDoor+ | Guest Check-in</Bold> app for smooth attendee management.</Typography>
            </CardContent>
            <CardActions>
                <ActionButton onClick={() => setFormat('pdf')} startIcon={<GetAppIcon />}>PDF</ActionButton>
                <ActionButton onClick={() => setFormat('xlsx')} startIcon={<GetAppIcon />}>EXCEL</ActionButton>
            </CardActions>

            {format !== null && (
                <ProductPicker
                    title='Guest List'
                    description='Include the following tickets:'
                    loading={loading}
                    eventId={eventId}
                    onCancel={() => setFormat(null)}
                    onConfirm={download}
                />
            )}
        </Card>
    )
}


function EmailExport({ eventId, hostId, alert }) {
    const [loading, setLoading] = useState(false);
    const [format, setFormat] = useState(null);

    const { notify } = useNotification();

    async function download(products) {
        const prodFilter = products.toString();

        if (loading) return;
        setLoading(true);

        ReactGA.event({ category: "Orders", action: 'Download Email Export', label: `${hostId}` });

        try {
            const res = await fetchExpresso(`/apiv2/events/${eventId}/email_list?format=${format}&products=${prodFilter}`);
            
            if (res.status === 200) {
                const file = await res.blob();
                downloadFile(`Mailing-List-${moment().format('YYYY-MM-DD')}.${format}`, file);
            } else if (res.status === 204) {
                notify.warning('No emails found');
            } else {
                throw new Error();
            }
        } catch(e) {
            notify.error(e.errorMsg || 'There was a problem downloading your mailing list');
        }
        setLoading(false);
        setFormat(null);
    }

    return (
        <Card>
            <CardHeader>
                <AlternateEmailIcon />
                <Title>Mailing List</Title>
            </CardHeader>
            <CardContent>
                <Typography>Download your event attendees' names and email addresses in a format optimized for easy import into popular platforms like Mailchimp (newsletters) and Hubspot (crm), enhancing your marketing campaigns.</Typography>
            </CardContent>
            <CardActions>
                <ActionButton onClick={() => setFormat('csv')} startIcon={<GetAppIcon />}>CSV</ActionButton>
                <ActionButton onClick={() => setFormat('xlsx')} startIcon={<GetAppIcon />}>EXCEL</ActionButton>
            </CardActions>

            {format !== null && (
                <ProductPicker
                    title='Mailing List'
                    description='Include people who bought the following tickets:'
                    loading={loading}
                    eventId={eventId}
                    onCancel={() => setFormat(null)}
                    onConfirm={download}
                />
            )}
        </Card>
    )
}


function SalesReports({ eventId, hostId, alert }) {
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [includeRegistration, setIncludeRegistration] = useState(false);

    const event = useEvent();
    const { notify } = useNotification();

    function handleDialogOpen() {
        if (event.checkout_question_mode === 1 || event.checkout_question_mode === 2) {
            setDialogOpen(true);
        } else {
            download();
        }
    }

    function handleDialogConfirm() {
        setDialogOpen(false);
        download();
    }

    async function download() {
        try {
            if (loading) return;
            setLoading(true);
            ReactGA.event({ category: "Orders", action: 'Download Orders', label: `${hostId}` });

            const res = await fetchExpresso(`/apiv2/events/${eventId}/order_list${includeRegistration ? '?include-registration=true' : ''}`);
            
            if (res.status === 200) {
                const excel = await res.blob();
                downloadFile(`Online-Sales-Report-${moment().format('YYYY-MM-DD-HHmm')}.xlsx`, excel);
            } else if (res.status === 204) {
                notify.warning('No sales found');
            } else {
                throw new Error();
            }
        } catch(e) {
            notify.error(e.errorMsg || 'There was a problem downloading your report');
        }
        setLoading(false);
    }

    return (
        <Card>
            <CardHeader>
                <ReceiptIcon />
                <Title>Online Sales Reports</Title>
            </CardHeader>
            <CardContent>
                <Typography>Download detailed sales and order reports. Note: checkout questions are included if your event is using them.</Typography>
            </CardContent>
            <CardActions>
                <ActionButton onClick={handleDialogOpen} startIcon={<GetAppIcon />}>Excel</ActionButton>
            </CardActions>

            {dialogOpen && (
                <Dialog open fullWidth onClose={() => setDialogOpen(false)}>
                    <DialogTitle>Orders</DialogTitle>
                    <DialogContent>
                        <FormControlLabel
                            control={<Checkbox checked={includeRegistration} onChange={e => setIncludeRegistration(e.target.checked)} />}
                            label='Include answers to checkout questions'
                        />
                    </DialogContent>
                    <DialogActions>
                        <ActionButton disabled={loading} onClick={() => setDialogOpen(false)}>Cancel</ActionButton>
                        <ActionButton disabled={loading} onClick={handleDialogConfirm} startIcon={<GetAppIcon />}>
                            {loading ? <CircularProgress size={16} /> : 'Download'}
                        </ActionButton>
                    </DialogActions>
                </Dialog>
            )}
        </Card>
    )
}

function PosSalesReports({ eventId, hostId, alert }) {
    const [loading, setLoading] = useState(false);

    const { notify } = useNotification();

    async function download() {
        try {
            if (loading) return;
            setLoading(true);
            ReactGA.event({ category: "Orders", action: 'Download POS Orders', label: `${hostId}` });

            const res = await fetchExpresso(`/apiv2/events/${eventId}/pos_order_list`);
            
            if (res.status === 200) {
                const excel = await res.blob();
                downloadFile(`In-Person-Sales-Report-${moment().format('YYYY-MM-DD-HHmm')}.xlsx`, excel);
            } else if (res.status === 204) {
                notify.warning('No sales found');
            } else {
                throw new Error();
            }
        } catch(e) {
            notify.error(e.errorMsg || 'There was a problem downloading your report');
        }
        setLoading(false);
    }

    return (
        <Card>
            <CardHeader>
                <ReceiptIcon />
                <Title>In-Person Sales Reports</Title>
            </CardHeader>
            <CardContent>
                <Typography>Download a detailed report on sales you made in-person.</Typography>
            </CardContent>
            <CardActions>
                <ActionButton onClick={download} startIcon={<GetAppIcon />}>Excel</ActionButton>
            </CardActions>
        </Card>
    )
}


function CheckinReports({ eventId, hostId, alert }) {
    const [loading, setLoading] = useState(false);

    const { notify } = useNotification();

    async function download() {
        try {
            if (loading) return;
            setLoading(true);
            ReactGA.event({ category: "Orders", action: 'Download Check-in Report', label: `${hostId}` });

            const res = await fetchExpresso(`/apiv2/events/${eventId}/scan_report`);
            
            if (res.status === 200) {
                const excel = await res.blob();
                downloadFile(`Check-In-Reports-${moment().format('YYYY-MM-DD')}.xlsx`, excel);
            } else if (res.status === 204) {
                notify.warning('No check-ins found');
            } else {
                throw new Error();
            }
        } catch(e) {
            notify.error(e.errorMsg || 'There was a problem downloading your report');
        }
        setLoading(false);
    }

    return (
        <Card>
            <CardHeader>
                <EqualizerIcon />
                <Title>Check-in Reports</Title>
            </CardHeader>
            <CardContent>
                <Typography>Download reports detailing attendee check-in activity to help you evaluate the success of your entry processes.</Typography>
            </CardContent>
            <CardActions>
                <ActionButton onClick={download} startIcon={<GetAppIcon />}>Excel</ActionButton>
            </CardActions>
        </Card>
    )
}


function MobileApps({ hostId }) {
    const [pin, setPin] = useState(null);

    // Get current scanner pin
    useEffect(() => {
        fetchExpresso(`/apiv2/hosts/${hostId}/scanner_pin`)
            .then(async (res) => {
                if (res.status === 200) {
                    const data = await res.json();
                    setPin(data.scannerPin);
                }
            })
            .catch((e) => null)
    }, [hostId]);

    return (
        <Card>
            <CardHeader>
                <PhoneIphoneIcon />
                <Title>Guest Check-in App</Title>
            </CardHeader>
            <CardContent>
                <Typography gutterBottom>This easy-to-use app will assist you in swiftly checking in attendees to your events. Available on Apple and Android devices.</Typography>
                <Typography display='inline'>- Your Login PIN for all your events is: </Typography><Bold>{pin ? pin : ''}</Bold>
                <Typography>- <QuickStartLink href='https://frontdoorplus.s3.ca-central-1.amazonaws.com/docs/Quick-Start-Guide-FrontDoorPlus-App.pdf' target="_blank" rel='noopener'>CLICK HERE</QuickStartLink> to download the Quick Start Guide</Typography>
            </CardContent>
            <CardActions>
                <LinkContainer>
                    <GoogleLink target="_blank" rel='noopener noreferrer' href='https://play.google.com/store/apps/details?id=com.b4tsolutions.eventdaddyAdmin&hl=en&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                        <StoreBadge
                            alt='Get it on Google Play'
                            src='https://frontdoorplus.s3.ca-central-1.amazonaws.com/storefront-images/web/google-play-badge.png'
                        />
                    </GoogleLink>

                    <AppleLink target="_blank" rel='noopener noreferrer' href="https://apps.apple.com/us/app/eventdaddy-manager/id1484720644?mt=8">
                        <StoreBadge
                            alt='Download on the App Store'
                            src='https://frontdoorplus.s3.ca-central-1.amazonaws.com/storefront-images/web/app-store-badge.svg'
                        />
                    </AppleLink>
                </LinkContainer>
            </CardActions>
        </Card>
    )
}


function ProductPicker(props) {
    const { onCancel, onConfirm, title, description, loading, eventId } = props;

    const [state, setState] = useState({
        status: 'loading',
        checklist: null
    });

    const [formError, setFormError] = useState(null);

    useEffect(() => {
        fetchExpresso(`/apiv2/products/?event=${eventId}`)
            .then(async res => {
                if (res.status === 200) {
                    const products = await res.json();
                    const checklist = products.map(p => ({ id: p.product_id, name: p.prod_name, checked: true }))
                    setState({ status: 'success', checklist: checklist })
                }
            })
            .catch(() => {
                setState({ status: 'error', products: null })
            })
    }, [eventId]);

    const handleConfirm = () => {
        const checkedProducts = state.checklist
            .filter(p => p.checked === true)
            .map(p => p.id)
        
        if (checkedProducts.length > 0) {
            onConfirm(checkedProducts);
        } else {
            setFormError('Please select at least one ticket')
        }
    };

    const handleCheckboxToggle = (id) => {
        if (formError) setFormError(null);

        const newChecklist = state.checklist.map(p => {
            if (p.id === id) {
                return { ...p, checked: !p.checked }
            } else {
                return p;
            }
        })
        setState(s => ({ ...s, checklist: newChecklist }));
    };

    return (
        <Dialog open fullWidth onClose={onCancel}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {state.status === 'loading' && (
                    <Box display='flex' width='100%' height={120} justifyContent='center' alignItems='center'>
                        <CircularProgress />
                    </Box>
                )}
                {state.status === 'error' && (
                    <Box display='flex' width='100%' height={120} justifyContent='center' alignItems='center'>
                        <Typography>There was a problem loading your tickets</Typography>
                    </Box>
                )}
                {state.status === 'success' && state.checklist.length === 0 && (
                    <Box display='flex' width='100%' height={120} justifyContent='center' alignItems='center'>
                        <Typography>No tickets found</Typography>
                    </Box>
                )}
                {state.status === 'success' && state.checklist.length > 0 && (
                    <Box style={{ minWidth: '60%'}}>
                        <Typography>{description}</Typography>

                        <FormControl error={Boolean(formError)}>
                            <FormGroup>
                                {state.checklist.map(p => (
                                    <FormControlLabel
                                        key={p.id}
                                        control={<Checkbox checked={p.checked} onChange={() => handleCheckboxToggle(p.id)} />}
                                        label={p.name}
                                    />
                                ))}
                            </FormGroup>
                            {formError && <FormHelperText>{formError}</FormHelperText>}
                        </FormControl>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button color='secondary' disabled={loading} onClick={onCancel}>Cancel</Button>
                <Button color='secondary' disabled={loading} onClick={handleConfirm} startIcon={<GetAppIcon />}>
                    {loading ? <CircularProgress size={16} /> : 'Download'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}


const Card = styled(_Card)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
}));

const CardHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
}));

const Title = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    marginLeft: theme.spacing(1)
}));
Title.defaultProps = { variant: 'h6' }

const CardContent = styled(_CardContent)(({ theme }) => ({
    flex: 1
}));

const LinkContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start'
    },
}));

const GoogleLink = styled('a')(({ theme }) => ({
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
        marginRight: theme.spacing(1)
    },
}));

const AppleLink = styled('a')(({ theme }) => ({
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(1)
    },
}));

const StoreBadge = styled('img')(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        height: '34px'
    },
    height: '54px'
}));

const QuickStartLink = styled(Link)(({ theme }) => ({
    color: theme.palette.info.main,
    fontSize: 14,
    fontWeight: 'bold'
}));

const Bold = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    display: 'inline'
}));