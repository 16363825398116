import React, { useState, useEffect } from 'react';
import { styled, Box, Button, Typography, CircularProgress, Grid, Divider, TextField, Paper } from '@material-ui/core';
import { Card as _Card, CardHeader, CardContent } from '@material-ui/core';
import { Table, TableRow, TableCell as _TableCell, TableBody, TableHead, TableContainer } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Page, ControlBar, ControlBarHeader } from 'components/ui/layout';
import fetchExpresso from 'utility/fetchExpresso';
import moment from 'moment-timezone';
import InfoTooltip from 'components/popups/InfoTooltip';
import PayoutHistory from 'components/payouts/PayoutHistory';
import { useEvent } from 'context/event';


export default function Payouts(props) {
    const eventId = props.match.params.id;

    const [state, setState] = useState({
        status: 'loading',
        sales: null,
        allSales: null,
        payout: null,
        incidental: null,
        event: null
    });


    // **** Allow host to filter results by date range **** //

    const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
    const { startDate, endDate } = dateRange;

    const handleDateRangeUpdate = (start, end) => setDateRange({ startDate: start, endDate: end });


    useEffect(() => {
        const timezone = '&tz=' + moment.tz.guess();
        let range = '';

        if (startDate && endDate) {
            range = `&start=${startDate}&end=${endDate}`;
        }

        fetchExpresso(`/apiv2/events/${eventId}/payments/summary?${timezone}${range}`)
            .then(res => {
                if (res.status !== 200) {
                    throw new Error();
                }
                return res.json();
            })
            .then(data => {
                setState(s => ({
                    ...s,
                    status: 'success',
                    sales: data.sales,
                    allSales: data.allSales,
                    payout: data.payout,
                    incidental: data.incidental,
                    event: data.event
                }));
            })
            .catch(() => {
                setState(s => ({ ...s, status: 'error' }))
            })
    }, [eventId, startDate, endDate]);


    if (state.status === 'loading') {
        return (
            <Page>
                <Box display='flex' justifyContent='center' alignItems='center' height={72}>
                    <CircularProgress />
                </Box>
            </Page>
        )
    }

    if (state.status === 'error') {
        return (
            <Page>
                <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' mt={12}>
                    <Typography variant='h5'>There was a problem finding your payouts</Typography>
                    <Box height={32} />
                    <Button variant='outlined' onClick={() => props.history.goBack()}>Back</Button>
                </Box>
            </Page>
        )
    }


    // ********** Extract Values ********** //

    // Online
    const { gross_sales, disc_order_total, tax_total, fd_fees_absorbed, cc_fees_absorbed } = state.sales.online;

    // POS
    const { pos_gross_sales, pos_card_sales, pos_cash_sales, pos_tax_total, pos_tax_total_card, pos_tax_total_cash, pos_fd_fees_absorbed, pos_cc_fees_absorbed } = state.sales.pos;

    // Event + Payout Info
    const { total_payouts, payouts, connect_id, connect_available_balance, connect_pending_balance } = state.payout;
    const { total_incidentals, incidentals } = state.incidental;


    // ********** Sales Widget Calculations ********** //

    const gross     = formatNumber(gross_sales + pos_gross_sales);
    const online    = formatNumber(gross_sales);
    const posCard   = formatNumber(pos_card_sales);
    const posCash   = formatNumber(pos_cash_sales);
    const discounts = formatNumber(disc_order_total)
    const net       = formatNumber(gross - discounts);

    const tax        = formatNumber(tax_total + pos_tax_total);
    const taxOnline  = formatNumber(tax_total);
    const taxPosCard = formatNumber(pos_tax_total_card);
    const taxPosCash = formatNumber(pos_tax_total_cash);

    const fdFees  = formatNumber(fd_fees_absorbed);
    const ccFees  = formatNumber(cc_fees_absorbed);
    const posFees = formatNumber(pos_fd_fees_absorbed + pos_cc_fees_absorbed);

    const total = formatNumber(
        Number(Number(Number(net + tax).toFixed(2) - fdFees).toFixed(2) - ccFees).toFixed(2) - posFees
    );


    const showOnlineFees = fd_fees_absorbed > 0;
    const showOnlineCCFees = cc_fees_absorbed > 0;
    const showPosFees = pos_fd_fees_absorbed > 0;


    return (
        <Page>

            <Grid container spacing={2} justifyContent='center'>

                <Grid item xs={12} lg={7}>
                    <ControlBar>
                        <ControlBarHeader>Sales Summary</ControlBarHeader>
                    </ControlBar>
                </Grid>

                <Grid item xs={12} lg={7}>
                    <DateRangePicker start={startDate} end={endDate} onChange={handleDateRangeUpdate} />
                </Grid>

                <Grid item xs={12} lg={7}>
                    <Card>
                        <CardHeader title='Sales' />
                        <CardContent>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCellBold>Gross Sales</TableCellBold>
                                        <TableCellBold align='right'>{formatPrice(gross)}</TableCellBold>
                                    </TableRow>

                                    <TableRow>
                                        <TableCellLight>
                                            <Box ml={4}>online</Box>
                                        </TableCellLight>
                                        <TableCellLight align='right'>{formatPrice(online)}</TableCellLight>
                                    </TableRow>
                                    <TableRow>
                                        <TableCellLight>
                                            <Box ml={4}>in-person card</Box>
                                        </TableCellLight>
                                        <TableCellLight align='right'>{formatPrice(posCard)}</TableCellLight>
                                    </TableRow>
                                    <TableRow>
                                        <TableCellLight>
                                            <Box ml={4}>in-person cash</Box>
                                        </TableCellLight>
                                        <TableCellLight align='right'>{formatPrice(posCash)}</TableCellLight>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>Discounts / Comps</TableCell>
                                        <TableCell align='right'>{formatPrice(discounts, true)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCellBold>Net Sales</TableCellBold>
                                        <TableCellBold align='right'>{formatPrice(net)}</TableCellBold>
                                    </TableRow>

                                    <Separator columns={2} />

                                    <TableRow>
                                        <TableCellBold>Taxes</TableCellBold>
                                        <TableCellBold align='right'>{formatPrice(tax)}</TableCellBold>
                                    </TableRow>

                                    {(pos_tax_total_card > 0 || pos_tax_total_cash > 0) ? (<>
                                        <TableRow>
                                            <TableCellLight>
                                                <Box ml={4}>online</Box>
                                            </TableCellLight>
                                            <TableCellLight align='right'>{formatPrice(taxOnline)}</TableCellLight>
                                        </TableRow>
                                        <TableRow>
                                            <TableCellLight>
                                                <Box ml={4}>in-person card</Box>
                                            </TableCellLight>
                                            <TableCellLight align='right'>{formatPrice(taxPosCard)}</TableCellLight>
                                        </TableRow>
                                        <TableRow>
                                            <TableCellLight>
                                                <Box ml={4}>in-person cash</Box>
                                            </TableCellLight>
                                            <TableCellLight align='right'>{formatPrice(taxPosCash)}</TableCellLight>
                                        </TableRow>
                                    </>) : null}

                                    <Separator columns={2} />

                                    {showOnlineFees && (
                                        <TableRow>
                                            <TableCell>Online Fees Absorbed</TableCell>
                                            <TableCell align='right'>{formatPrice(fdFees, true)}</TableCell>
                                        </TableRow>
                                    )}
                                    {showOnlineCCFees && (
                                        <TableRow>
                                            <TableCell>Online CC Fees Absorbed</TableCell>
                                            <TableCell align='right'>{formatPrice(ccFees, true)}</TableCell>
                                        </TableRow>
                                    )}
                                    {showPosFees && (
                                        <TableRow>
                                            <TableCell>In-Person Fees Absorbed</TableCell>
                                            <TableCell align='right'>{formatPrice(posFees, true)}</TableCell>
                                        </TableRow>
                                    )}
                                    {(showOnlineFees || showOnlineCCFees || showPosFees) && (
                                        <Separator columns={2} />
                                    )}

                                    <TableRow>
                                        <TableCellBold>Total Sales</TableCellBold>
                                        <TableCellBold align='right'>{formatPrice(total)}</TableCellBold>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                    <Box height={12} />
                </Grid>

                <Grid item xs={12} lg={7}>
                    <ControlBar>
                        <ControlBarHeader>Payouts</ControlBarHeader>
                    </ControlBar>
                </Grid>

                <Grid item xs={12} lg={7}>
                    <Card>
                        <CardHeader title='Summary' />

                        <CardContent>
                            <PayoutSummary
                                online={state.allSales.online}
                                pos={state.allSales.pos}
                                payout={state.payout}
                                incidental={state.incidental}
                                event={state.event}
                            />

                            <ConnectSummary
                                connect_id={connect_id}
                                available={connect_available_balance}
                                pending={connect_pending_balance}
                            />
                        </CardContent>

                    </Card>
                </Grid>

                <Grid item xs={12} lg={7}>
                    <Card>
                        <CardHeader title='History' />
                        
                        <CardContent>
                            <PayoutHistory
                                payouts={payouts}
                                totalPayouts={total_payouts}
                            />
                        </CardContent>
                    </Card>
                    <Box height={12} />
                </Grid>

                <Grid item xs={12} lg={7}>
                    <ControlBar>
                        <ControlBarHeader>Incidentals</ControlBarHeader>
                    </ControlBar>
                </Grid>

                <Grid item xs={12} lg={7}>
                    <Card>
                        <CardHeader title='Incidentals' />
                        <CardContent>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCellHeader>WHEN</TableCellHeader>
                                            <TableCellHeader>DESC</TableCellHeader>
                                            <TableCellHeader align='right'>AMOUNT</TableCellHeader>
                                            <TableCellHeader align='right'>TAX</TableCellHeader>
                                            <TableCellHeader align='right'>TOTAL</TableCellHeader>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {incidentals.map(i => (
                                            <TableRow key={i.inc_id}>
                                                <TableCell size='medium'>
                                                    {moment(i.inc_date).get('year') === moment().get('year')
                                                        ? moment(i.inc_date).format('MMM Do')
                                                        : moment(i.inc_date).format('MMM Do YYYY')
                                                    }
                                                </TableCell>
                                                <TableCell size='medium'>{i.inc_desc}</TableCell>
                                                <TableCell size='medium' align='right'>{formatPrice(i.sub_total)}</TableCell>
                                                <TableCell size='medium' align='right'>{formatPrice(i.tax_total)}</TableCell>
                                                <TableCell size='medium' align='right'>{formatPrice(i.total)}</TableCell>
                                            </TableRow>
                                        ))}
                                        {incidentals.length > 0 ? (
                                            <TableRow>
                                                <IncTotalCell colSpan={2}>Total Incidentals</IncTotalCell>
                                                <IncTotalCell colSpan={3} align='right'>{formatPrice(total_incidentals)}</IncTotalCell>
                                            </TableRow>
                                        ) : (
                                            <TableRow>
                                                <IncEmptyCell size='medium' align='center' colSpan={5}>No incidentals found</IncEmptyCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Grid>

            </Grid>

        </Page>
    )
}


function DateRangePicker(props) {
    const { onChange, start: currentStart, end: currentEnd } = props;

    const event = useEvent();
    
    const [state, setState] = useState({
        preset: 'allTime',
        start: null,
        end: null,
    });
    
    const { preset, start, end } = state;
    
    const unsavedChanges = start !== currentStart || end !== currentEnd;
    const invalidDates = end < start;

    const handleStartDateChange = (value) => setState({ ...state, start: moment(value).format('YYYY-MM-DDT00:00:00') });
    const handleEndDateChange = (value) => setState({ ...state, end: moment(value).format('YYYY-MM-DDT23:59:59') });

    const handlePresetChange = (e) => {
        switch (e.target.value) {
            case 'allTime':
                setState({ preset: 'allTime', start: null, end: null });
                onChange(null, null);
                break;

            case 'custom':
                setState({
                    preset: 'custom',
                    start: moment(event.start_date).format('YYYY-MM-DDT00:00:00'),
                    end: moment(event.end_date).format('YYYY-MM-DDT23:59:59')
                });
                break;

            default:
                break;
        }
    }

    const handleSubmit = () => {
        onChange(start, end);
    };
    
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <FilterContainer>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                        <TextField fullWidth size='small' select value={preset} onChange={handlePresetChange} label='When' SelectProps={{ native: true }}>
                            <option value='allTime'>All Time</option>
                            <option value='custom'>Custom</option>
                        </TextField>
                    </Grid>
                    {preset === 'custom' && (<>
                        <Grid item xs={6} sm={3}>
                            <DatePicker label='Start' fullWidth size='small' format='yyyy-MM-dd' value={start} onChange={handleStartDateChange} />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <DatePicker label='End' fullWidth size='small' format='yyyy-MM-dd' value={end} onChange={handleEndDateChange} />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <Box display={'flex'} height={'100%'} width={'100%'} alignItems={'center'}>
                                <FilterButton disabled={invalidDates || unsavedChanges === false} onClick={handleSubmit}>Apply</FilterButton>
                            </Box>
                        </Grid>
                    </>)}
                </Grid>
            </FilterContainer>
        </MuiPickersUtilsProvider>
    )
}

function PayoutSummary(props) {

    const { gross_sales, disc_order_total, tax_total, fd_fees_absorbed, cc_fees_absorbed } = props.online;
    const { pos_gross_sales, pos_cash_sales, pos_tax_total, pos_tax_total_cash, pos_fd_fees_absorbed, pos_cc_fees_absorbed } = props.pos;
    const { event_started, scheduled_payout_flag, payout_reserve_percent } = props.event;
    const { total_payouts, payouts } = props.payout;
    const { total_incidentals } = props.incidental;

    const gross     = formatNumber(gross_sales + pos_gross_sales);
    const posCash   = formatNumber(pos_cash_sales);
    const discounts = formatNumber(disc_order_total)
    const net       = formatNumber(gross - discounts);

    const tax        = formatNumber(tax_total + pos_tax_total);
    const taxPosCash = formatNumber(pos_tax_total_cash);

    const fdFees  = formatNumber(fd_fees_absorbed);
    const ccFees  = formatNumber(cc_fees_absorbed);
    const posFees = formatNumber(pos_fd_fees_absorbed + pos_cc_fees_absorbed);

    const total = formatNumber(
        Number(Number(Number(net + tax).toFixed(2) - fdFees).toFixed(2) - ccFees).toFixed(2) - posFees
    );

    const incidentalTotal = formatNumber(total_incidentals);
    const totalPayout = formatNumber(((total - pos_cash_sales).toFixed(2) - pos_tax_total_cash).toFixed(2) - total_incidentals);

    // Reserve amount will be 0 if final payout was issued, or next payout is the last one
    // We know the next payout is the final one if the event has already started
    const finalPayout = payouts.some(p => p.final_payout_flag === 1) || event_started === 1;
    const reservePercentText = Number((payout_reserve_percent * 100).toFixed(2)) + '%';

    const reserve = finalPayout ? 0 : formatNumber(totalPayout * payout_reserve_percent);
    const nextPayout = formatNumber(
        Number(totalPayout - total_payouts).toFixed(2) - reserve
    );
    
    return (
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell>Sales</TableCell>
                    <TableCell align='right'>{formatPrice(total)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>In-Person Cash</TableCell>
                    <TableCell align='right'>{formatPrice(posCash, true)}</TableCell>
                </TableRow>
                {pos_tax_total_cash > 0 && (
                    <TableRow>
                        <TableCell>In-Person Cash (Tax)</TableCell>
                        <TableCell align='right'>{formatPrice(taxPosCash, true)}</TableCell>
                    </TableRow>
                )}
                <TableRow>
                    <TableCell>Incidentals</TableCell>
                    <TableCell align='right'>{formatPrice(incidentalTotal, true)}</TableCell>
                </TableRow>

                {scheduled_payout_flag ? (<>
                    <TableRow>
                        <TableCellBold>Total Payout</TableCellBold>
                        <TableCellBold align='right'>{formatPrice(totalPayout)}</TableCellBold>
                    </TableRow>

                    <Separator columns={2} />

                    <TableRow>
                        <TableCell>Reserve ({reservePercentText}) <InfoTooltip message='A percentage of funds is temporarily held back to mitigate potential financial risks. This amount will be fully released with your final payout.' /></TableCell>
                        <TableCell align='right'>{formatPrice(reserve, true)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Paid Out To Date</TableCell>
                        <TableCell align='right'>{formatPrice(total_payouts, true)}</TableCell>
                    </TableRow>

                    <Separator columns={2} />

                    <TableRow>
                        <TableCellBold>Est. Payout</TableCellBold>
                        <TableCellBold align='right'>{formatPrice(nextPayout)}</TableCellBold>
                    </TableRow>
                </>) : (<>
                    <Separator columns={2} />
                    <TableRow>
                        <TableCellBold>Total Payout</TableCellBold>
                        <TableCellBold align='right'>{formatPrice(totalPayout)}</TableCellBold>
                    </TableRow>
                </>)}

                
            </TableBody>
        </Table>
    )
}

function ConnectSummary(props) {
    const { connect_id, pending } = props;

    // Host must have a valid connect account before we display connect balance information
    if (!connect_id) {
        return null;
    }

    return (
        <Box mt={4}>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>Pending <InfoTooltip message='Transactions take up to 3 business days to process. After which, they will be available to be paid out.' /></TableCell>
                        <TableCell align='right'>{formatPrice(pending)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Box>
    )
}


// Format for calculations
function formatNumber(num) {
    return Number(num.toFixed(2).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
}

// Format for price display
function formatPrice(num, negative) {
    let result = '-';

    if (num > 0) {
        result = '$' + num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

        if (negative) {
            result = '-' + result;
        }
    }

    return result;
}

function Separator(props) {
    return (
        <TableRow>
            <TableCell colSpan={props.columns}>
                <Divider />
            </TableCell>
        </TableRow>
    )
}


const FilterContainer = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2)
}));

export const FilterButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white
}));
FilterButton.defaultProps = { variant: 'contained' }

const Card = styled(_Card)(({ theme }) => ({
    marginBottom: theme.spacing(2)
}));

const TableCell = styled(_TableCell)(({ theme }) => ({
    border: 'none'
}));
TableCell.defaultProps = { size: 'small' }

const TableCellHeader = styled(_TableCell)(({ theme }) => ({
    ...theme.typography.subHeader,
    paddingTop: 0,
    fontWeight: 'bold',
    borderBottom: '0.5px solid'
}));

const TableCellBold = styled(_TableCell)(({ theme }) => ({
    border: 'none',
    fontWeight: 'bold'
}));
TableCellBold.defaultProps = { size: 'small' }

const TableCellLight = styled(_TableCell)(({ theme }) => ({
    border: 'none',
    color: theme.palette.grey[600]
}));
TableCellLight.defaultProps = { size: 'small' }


const IncTotalCell = styled(_TableCell)(({ theme }) => ({
    border: 'none',
    borderTop: '0.5px solid',
    paddingBottom: 0,
    fontWeight: 'bold'
}));

const IncEmptyCell = styled(_TableCell)(({ theme }) => ({
    border: 'none',
    paddingBottom: 0
}));