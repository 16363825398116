import React, { useState, useEffect } from 'react';
import { styled, Grid, Hidden, Box, Divider } from '@material-ui/core';
import { Button, IconButton, Typography, TextField as _TextField, InputAdornment, FormControlLabel } from '@material-ui/core';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { RadioGroup, Radio } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import StoreIcon from '@material-ui/icons/Store';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Alert } from '@material-ui/lab';

// Components
import { TextArea } from 'components/ui/inputs';
import { Section, Header, Content } from 'components/ui/forms';
import ReceiptPreview from 'components/ReceiptPreview';
import Tooltip from 'components/popups/Tooltip';

// Utility
import moment from 'moment-timezone';
import { useNotification } from 'context/notification';
import { useTextInput, useNumberInput, useDatePicker } from 'hooks/forms';
import { formatPrice } from 'utility/numbers';
import { getMomentFromDateAndTime } from 'utility/validation';

// Material Date Picker
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';



export default function BundleForm(props) {
    const { editMode, deletable, products, eventId, hostId, onSubmit, onCancel, onDelete, loading } = props;
    const d = props.defaultValues;


    // Cart State
    const [cart, setCart] = useState(products);
    const [emptyCart, setEmptyCart] = useState(false);

    // Form State
    const name          =   useTextInput({ defaultValue: d.name });
    const description   =   useTextInput({ defaultValue: d.description });
    const price         = useNumberInput({ defaultValue: d.price, format: 'currency' });
    const qtyAvailable  = useNumberInput({ defaultValue: d.qtyAvailable, integerOnly: true });
    const qtyMin        = useNumberInput({ defaultValue: d.qtyMin, integerOnly: true });
    const qtyMax        = useNumberInput({ defaultValue: d.qtyMax, integerOnly: true });
    const saleStartDate =  useDatePicker({ defaultValue: d.saleStartDate });
    const saleStartTime =  useDatePicker({ defaultValue: d.saleStartDate });
    const saleEndDate   =  useDatePicker({ defaultValue: d.saleEndDate });
    const saleEndTime   =  useDatePicker({ defaultValue: d.saleEndDate });
    const accessCode    =   useTextInput({ defaultValue: d.accessCode, format: 'alphanumeric', maxLength: 25 });
    const [audience, setAudience] = useState(d.audience); // visible, hidden, accessCode

    // Error States
    const [negativeSalePeriod, setNegativeSalePeriod] = useState(false);

    // Warnings: Alerts the user of potential issues, but allow them to save the bundle if they accept the warnings
    const [warnings, setWarnings] = useState({
        insufficientProducts: false,
        negativeSavings: false
    });


    // If negativeSalePeriod is true, check on every render if the user fixed the issue, then hide the error message
    useEffect(() => {
        if (negativeSalePeriod === true) {
            if (saleStartDate.value.isValid() && saleStartTime.value.isValid() && saleEndDate.value.isValid() && saleEndTime.value.isValid()) {
                if (moment(saleStartDate.value.format('YYYY-MM-DD') + ' ' + saleStartTime.value.format('HH:mm:ss')) < moment(saleEndDate.value.format('YYYY-MM-DD') + ' ' + saleEndTime.value.format('HH:mm:ss'))) {
                    setNegativeSalePeriod(false);
                }
            }
        }
    }, [negativeSalePeriod, saleStartDate, saleStartTime, saleEndDate, saleEndTime]);


    const { notify } = useNotification();


    // Calculate savings in $ and %
    const totalValue = cart.reduce((p, n) => {
        return p + Number((n.price * n.quantity).toFixed(2));
    }, 0);


    const handleSubmit = (ignoreWarnings) => {
        let formError = false;

        const bundleProducts = cart
            .filter(p => p.quantity > 0)
            .map(p => ({ id: p.id, qty: p.quantity }));

        if (bundleProducts.length === 0) {
            setEmptyCart(true);
            formError = true;
        } else {
            setEmptyCart(false);
        }

        if (name.value.trim() === '') {
            name.setError('This field cannot be blank');
            formError = true;
        }

        if (!price.value) {
            price.setError('This field cannot be blank');
            formError = true;
        }

        if (!qtyAvailable.value) {
            qtyAvailable.setError('This field cannot be blank');
            formError = true;
        }

        if (!qtyMin.value) {
            qtyMin.setError('This field cannot be blank');
            formError = true;
        }

        if (!qtyMax.value) {
            qtyMax.setError('This field cannot be blank');
            formError = true;
        }

        // Check for invalid dates
        if (!saleStartDate.value.isValid() || !saleStartTime.value.isValid() || !saleEndDate.value.isValid() || !saleEndTime.value.isValid()) {
            formError = true;
        }
        // Check that end date is after start date
        else if (moment(saleStartDate.value.format('YYYY-MM-DD') + ' ' + saleStartTime.value.format('HH:mm:ss')) > moment(saleEndDate.value.format('YYYY-MM-DD') + ' ' + saleEndTime.value.format('HH:mm:ss'))) {
            formError = true;
            setNegativeSalePeriod(true);
        } else {
            setNegativeSalePeriod(false);
        }

        // Check for invalid access code (if feature is enabled)
        if (audience === 'accessCode' && accessCode.value === '') {
            accessCode.setError('This field cannot be blank');
            formError = true;
        }

        if (formError) {
            notify.warning('Please ensure all fields are filled out correctly');
            return;
        }

        // Check for potential issues with the bundle, and warn user.
        // If they accepted the warning, ignoreWarnings will be true, and this section is skipped.
        if (ignoreWarnings !== true) {
            let issueWarning = false;
            let insufficientProducts = false;
            let negativeSavings = false;

            // Ensure price is not higher than total value
            if (price.value > totalValue) {
                negativeSavings = true;
                issueWarning = true;
            }

            // Ensure enough products are available
            const productsWithoutEnoughStock = bundleProducts.filter(p => {
                const product = products.find(i => i.id === p.id);
                return (product.total - product.sold) < p.qty;
            });
    
            // If there are insufficient products, show warning. User may still submit form after accepting warning.
            if (productsWithoutEnoughStock.length > 0) {
                insufficientProducts = true;
                issueWarning = true;
            }

            if (issueWarning) {
                setWarnings(w => ({ ...w, insufficientProducts, negativeSavings }));
                return;
            }
        }


        const bundleInfo = {
            name:           name.value.trim(),
            description:    description.value.trim() || null,
            price:          Number(price.value),
            qtyAvailable:   Number(qtyAvailable.value),
            qtyMin:         Number(qtyMin.value),
            qtyMax:         Number(qtyMax.value),
            saleStartDate:  saleStartDate.value.format('YYYY-MM-DD') + ' ' + saleStartTime.value.format('HH:mm:00'),
            saleEndDate:    saleEndDate.value.format('YYYY-MM-DD') + ' ' + saleEndTime.value.format('HH:mm:59'),
            visible:        audience === 'visible' || audience === 'accessCode',
            accessCode:     accessCode.value || null // accessCode input does not allow spaces
        }

        onSubmit(bundleInfo, bundleProducts);
    };

    const handleProductUpdate = (id, value) => {
        const newValue = Number(value);

        if (Number.isInteger(newValue) && newValue >= 0 && newValue <= 99) {
            setCart(
                cart.map(i => {
                    if (i.id === id) {
                        return { ...i, quantity: newValue };
                    } else {
                        return i;
                    }
                })
            )
        }

    };


    const handleAudienceChange = (e) => {
        const audience = e.target.value;

        // Clear access code value
        // Note: calling setValue() also sets the error state to false
        if (audience !== 'accessCode') {
            accessCode.setValue('');
        }

        setAudience(audience);
    };
    
    
    const handleWarningAccept = () => {
        setWarnings(w => ({ ...w, insufficientProducts: false, negativeSavings: false }))
        handleSubmit(true); // Ignore warnings when submitting bundle
    };

    const handleWarningCancel = () => {
        setWarnings(w => ({ ...w, insufficientProducts: false, negativeSavings: false }))
    };

    // # of paid tix in bundle. Used to calculate per ticket fees
    const paidProductCount = cart.reduce((p, n) => {
        let count = p;

        if (n.price > 0) {
            count += n.quantity;
        }

        return count;
    }, 0);


    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Root>

                <Grid container spacing={4}>

                    <Grid item xs={12}>
                        <Alert severity='info'>
                            Create a bundle to offer a curated set of admission and add-on tickets for your event, often at a discounted price. This feature makes it easy for attendees to purchase multiple tickets in a single package, enhancing their event experience and simplifying the process.
                        </Alert>
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <Section>

                            <Header>About</Header>

                            <Content>

                                <TextField
                                    {...name.formProps}
                                    fullWidth
                                    label='Bundle Name'
                                />

                                <TextArea
                                    label='Bundle Description'
                                    maxLength={500}
                                    {...description.formProps}
                                />

                                <PriceInput
                                    {...price.formProps}
                                    label='Price'
                                />

                            </Content>

                        </Section>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Section>
                            <ReceiptPreview
                                hostId={hostId}
                                eventId={eventId}
                                price={price.value}
                                name={name.value || 'Bundle'}
                                ticketCount={paidProductCount}
                            />
                        </Section>
                    </Grid>

                    <Grid item xs={12}>
                        <Section>

                            <Header>Availability</Header>

                            <Typography gutterBottom>Specify the maximum number of this bundle being made available. Additionally, set the minimum and maximum limits for the number of bundles that can be purchased per transaction.</Typography>
                            <Typography variant='caption'>Note: once a ticket in a bundle sells out, the bundle itself won't be available for sale.</Typography>

                            <Content>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4} md={3}>
                                        <TextField 
                                            {...qtyAvailable.formProps}
                                            label='Qty Available'
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={4} md={3}>
                                        <TextField 
                                            {...qtyMin.formProps}
                                            label='Order Minimum'
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={4} md={3}>
                                        <TextField 
                                            {...qtyMax.formProps}
                                            label='Order Maximum'
                                        />
                                    </Grid>
                                </Grid>
                            </Content>

                        </Section>
                    </Grid>

                    <Grid item xs={12}>
                        <Section>

                            <Header>Build Bundle</Header>

                            <Typography>Choose the items you'd like to include in your bundle for a combined offering.</Typography>

                            <Content>

                                <ProductSelect
                                    price={Number(price.value)}
                                    cart={cart}
                                    error={emptyCart}
                                    onUpdate={handleProductUpdate}
                                />

                            </Content>

                        </Section>
                    </Grid>

                    <Grid item xs={12}>
                        <Section>
                            <Header>Bundle Visibility</Header>

                            <Typography>Select who can view and purchase this bundle online. Choose <Bold>Available to Everyone</Bold> for public access, <Bold>Not Available</Bold> to hide the bundle, or <Bold>Access Code Required</Bold> to restrict access to with a unique code.</Typography>

                            <Box mt={2}>

                                <RadioGroup aria-label="audience" value={audience} onChange={handleAudienceChange}>
                                    <FormControlLabel value="visible" control={<Radio />} label="Available to Everyone" />
                                    <FormControlLabel value="hidden" control={<Radio />} label="Not Available to Anyone" />
                                    <FormControlLabel value="accessCode" control={<Radio />} label="Access Code Required" />
                                </RadioGroup>

                                {audience === 'accessCode' && (
                                    <AccessCodeContainer>
                                        <Typography variant='caption'>code has to be entered on the event page to view this bundle (max 25 characters)</Typography>
                                        <AccessCodeInput {...accessCode.formProps} variant='outlined' placeholder='Access Code' />
                                    </AccessCodeContainer>
                                )}

                            </Box>


                        </Section>
                    </Grid>

                    <Grid item xs={12}>
                        <Section>

                            <Header>When is this bundle available for purchase?</Header>

                            <Typography>Set the time frame for when your bundle goes on sale and when it stops.</Typography>

                            <Content>
                                <Grid container spacing={2}>

                                    <Grid item xs={6} md={5}>
                                        <DatePicker
                                            {...saleStartDate.formProps}
                                            autoOk
                                            label="Start Date"
                                            inputVariant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            format="MM/dd/yyyy"
                                            error={saleStartDate.formProps.error || negativeSalePeriod}
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={5}>
                                        <TimePicker
                                            {...saleStartTime.formProps}
                                            label="Start Time"
                                            inputVariant="outlined"
                                            mask="__:__ _M"
                                            InputLabelProps={{ shrink: true }}
                                            error={saleStartTime.formProps.error || negativeSalePeriod}
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={5}>
                                        <DatePicker
                                            {...saleEndDate.formProps}
                                            autoOk
                                            label="End Date"
                                            inputVariant="outlined"
                                            format="MM/dd/yyyy"
                                            InputLabelProps={{ shrink: true }}
                                            error={saleEndDate.formProps.error || negativeSalePeriod}
                                        />
                                    </Grid>

                                    <Grid item xs={6} md={5}>
                                        <TimePicker
                                            {...saleEndTime.formProps}
                                            label="End Time"
                                            inputVariant="outlined"
                                            mask="__:__ _M"
                                            InputLabelProps={{ shrink: true }}
                                            error={saleEndTime.formProps.error || negativeSalePeriod}
                                        />
                                    </Grid>

                                </Grid>

                                {negativeSalePeriod && <ErrorMessage>End date cannot come before start date</ErrorMessage>}

                                <DateSummary
                                    startDate={saleStartDate.value}
                                    startTime={saleStartTime.value}
                                    endDate={saleEndDate.value}
                                    endTime={saleEndTime.value}
                                />
                            </Content>

                        </Section>
                    </Grid>

                </Grid>
            

                <ButtonContainer>
                    {editMode && (
                        <>
                            {deletable ? (
                                <DeleteButton variant='outlined' onClick={onDelete} disabled={loading}>Delete Bundle</DeleteButton>
                            ) : (
                                <Tooltip message='Bundles cannot be deleted after being purchased on the store.'>
                                    <DeleteButtonDisabled variant='outlined'>Delete Bundle</DeleteButtonDisabled>
                                </Tooltip>
                            )}
                        </>
                    )}
                    <CancelButton variant='outlined' disabled={loading} onClick={onCancel}>Cancel</CancelButton>
                    <SubmitButton variant='contained' disabled={loading} onClick={handleSubmit}>{editMode ? 'Update' : 'Create'}</SubmitButton>
                </ButtonContainer>


                <Box display='flex' flexDirection='column'>
                    {d.createdAtMsg && (
                        <Typography align='right' variant='caption'>{d.createdAtMsg}</Typography>
                    )}
                    
                    {d.updatedAtMsg && (
                        <Typography align='right' variant='caption'>{d.updatedAtMsg}</Typography>
                    )}
                </Box>

            </Root>

            <WarningConfirmation
                warnings={warnings}
                onConfirm={handleWarningAccept}
                onCancel={handleWarningCancel}
            />

        </MuiPickersUtilsProvider>
    )
}


function WarningConfirmation(props) {
    const { warnings, onConfirm, onCancel } = props;

    const open = warnings.insufficientProducts || warnings.negativeSavings;



    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogTitle>We found potential issues with this bundle</DialogTitle>
            <DialogContent>
                {warnings.insufficientProducts === true && (
                    <Box fontWeight='bold' mb={1}>• Not enough tickets to fulfill this bundle. It will appear as sold out.</Box>
                )}
                {warnings.negativeSavings === true && (
                    <Box fontWeight='bold' mb={1}>• Cost of bundle is higher than total value of tickets.</Box>
                )}
                <Box mt={4}>Are you sure you want to continue?</Box>
            </DialogContent>
            <DialogActions>
                <Button color='secondary' onClick={onCancel}>Cancel</Button>
                <Button color='secondary' onClick={onConfirm}>Yes</Button>
            </DialogActions>
        </Dialog>
    )
    
}


function ProductSelect(props) {
    const { price, cart, onUpdate, error } = props;

    let totalValue = 0;
    let ticketCount = 0;
    
    for (const p of cart) {
        totalValue = Number((totalValue + Number((p.price * p.quantity).toFixed(2))).toFixed(2));
        ticketCount += p.quantity;
    }

    let savingAmount = 0;
    let savingPercent = 0;

    if (totalValue > 0 && Number(price) > 0) {
        savingAmount = Number((totalValue - price).toFixed(2));
        savingPercent = Number(((savingAmount / totalValue).toFixed(2) * 100).toFixed(2));
    }


    return (
        <>
            <Hidden smDown>
                <TableContainer component={Box}>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Ticket</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell align='right'>Available</TableCell>
                                <TableCell align='right'>Value</TableCell>
                                <TableCell align='center'>Quantity</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cart.map(item => (
                                <TableRow key={item.id}>
                                    <TableCell>
                                        <ProductType
                                            type={item.type}
                                            name={item.name}
                                        />
                                    </TableCell>
                                    <TableCell width={120}>
                                        <Availability icon status={item.status} />
                                    </TableCell>
                                    <TableCell width={60} align='right'>
                                        {item.sold} / {item.total}
                                    </TableCell>
                                    <TableCell width={60} align='right'>
                                        ${item.price}
                                    </TableCell>
                                    <TableCell width={130} align='right'>
                                        <Box display='flex' alignItems='center'>
                                            <IconButton color='secondary' onClick={() => onUpdate(item.id, item.quantity - 1)}>
                                                <RemoveIcon />
                                            </IconButton>
                                            <QuantityField
                                                error={error || item.quantity > (item.total - item.sold)}
                                                value={item.quantity}
                                                onFocus={e => e.target.select()}
                                                onChange={(e) => onUpdate(item.id, e.target.value)}
                                            />
                                            <IconButton onClick={() => onUpdate(item.id, item.quantity + 1)}>
                                                <AddIcon />
                                            </IconButton>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TotalCell>Total Value</TotalCell>
                                <TotalCell align='right' colSpan={3}>{formatPrice(totalValue)}</TotalCell>
                                <TotalCell align='center'>{ticketCount} tickets</TotalCell>
                            </TableRow>
                            {savingAmount >= 0 ? (
                                <TableRow>
                                    <SavingCell>Savings</SavingCell>
                                    <SavingCell align='right' colSpan={3}>{formatPrice(savingAmount)}</SavingCell>
                                    <SavingCell align='center'>({savingPercent}% off)</SavingCell>
                                </TableRow>
                            ) : (
                                <TableRow>
                                    <NegativeSavingCell>Savings</NegativeSavingCell>
                                    <NegativeSavingCell align='right' colSpan={3}>{formatPrice(savingAmount)}</NegativeSavingCell>
                                    <NegativeSavingCell align='center'>({savingPercent}% off)</NegativeSavingCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Hidden>

            <Hidden mdUp>
                {cart.map(item => (
                    <Box key={item.id}>
                        <TicketName>{item.name}</TicketName>
                        <Availability status={item.status} />
                        <Typography display='block' variant='caption'>{item.sold} / {item.total}</Typography>
                        <Typography display='block' variant='caption'>${item.price}</Typography>

                        <Box display='flex' alignItems='center' mt={2}>
                            <IconButton color='secondary' onClick={() => onUpdate(item.id, item.quantity - 1)}>
                                <RemoveIcon fontSize='large' />
                            </IconButton>
                            <QuantityField
                                error={error}
                                value={item.quantity}
                                onFocus={e => e.target.select()}
                                onChange={(e) => onUpdate(item.id, e.target.value)}
                            />
                            <IconButton color='secondary' onClick={() => onUpdate(item.id, item.quantity + 1)}>
                                <AddIcon fontSize='large' />
                            </IconButton>
                        </Box>

                        <Box my={2}>
                            <Divider />
                        </Box>
                    </Box>
                ))}
                <TotalText>Total Value: {formatPrice(totalValue)} ({ticketCount} tickets)</TotalText>
                {savingAmount >= 0
                    ? <SavingText>Savings: {formatPrice(savingAmount)} ({savingPercent}% off)</SavingText>
                    : <NegativeSavingText>Savings: {formatPrice(savingAmount)} ({savingPercent}% off)</NegativeSavingText>
                }
            </Hidden>
        </>
    )
}


function ProductType(props) {
    switch (props.type) {
        case 1:
            return (
                <Box flex={2} display='flex' alignItems='center'>
                    <ConfirmationNumberIcon fontSize='small' color='secondary' />
                    <Box ml={1}>{props.name}</Box>
                </Box>
            )
        case 2:
            return (
                <Box flex={1} display='flex' alignItems='center'>
                    <AddShoppingCartIcon fontSize='small' color='secondary' />
                    <Box ml={1}>{props.name}</Box>
                </Box>
            )
        default:
            return null;
    }
}


function Availability(props) {

    if (props.icon === true) {
        switch (props.status) {
            case 'on_sale':
                return (
                    <Box flex={1} display='flex' alignItems='center'>
                        <StoreActiveIcon fontSize='small' />
                        <Typography variant='caption'>On Sale</Typography>
                    </Box>
                )
            case 'sale_not_started':
                return (
                    <Box flex={1} display='flex' alignItems='center'>
                        <StoreInactiveIcon fontSize='small' />
                        <Typography variant='caption'>Sale not Started</Typography>
                    </Box>
                )
            case 'sale_concluded':
                return (
                    <Box flex={1} display='flex' alignItems='center'>
                        <StoreInactiveIcon fontSize='small' />
                        <Typography variant='caption'>Sale Concluded</Typography>
                    </Box>
                )
            case 'sold_out':
                return (
                    <Box flex={1} display='flex' alignItems='center'>
                        <StoreInactiveIcon fontSize='small' />
                        <Typography variant='caption'>Sold Out</Typography>
                    </Box>
                )
            case 'hidden':
                return (
                    <Box flex={1} display='flex' alignItems='center'>
                        <TicketHiddenIcon fontSize='small' />
                        <Typography variant='caption'>Hidden</Typography>
                    </Box>
                )
            default:
                return (
                    <Typography>-</Typography>
                )
        }
    } else {
        switch (props.status) {
            case 'on_sale':
                return <Typography variant='caption'>On Sale</Typography>
            case 'sale_not_started':
                return <Typography variant='caption'>Sale not Started</Typography>
            case 'sale_concluded':
                return <Typography variant='caption'>Sale Concluded</Typography>
            case 'sold_out':
                return <Typography variant='caption'>Sold Out</Typography>
            case 'hidden':
                return <Typography variant='caption'>Hidden</Typography>
            default:
                return <Typography>-</Typography>
        }
    }
}


function DateSummary(props) {
    const { startDate, startTime, endDate, endTime } = props;

    if (
        moment(startDate).isValid() === false ||
        moment(endDate).isValid()   === false ||
        moment(startTime).isValid() === false ||
        moment(endTime).isValid()   === false
    ) {
        return null;
    }

    const start = getMomentFromDateAndTime(startDate, startTime);
    const end = getMomentFromDateAndTime(endDate, endTime);
    const text = `${start.format('dddd, MMM Do @ h:mma')} and ${end.format('dddd, MMM Do @ h:mma')}`;

    return (
        <Box mt={2}>
            <DateSummaryText>This bundle can be purchased between:</DateSummaryText>
            {start > end
                ? <DateSummaryTextError>{text}</DateSummaryTextError>
                : <DateSummaryTextBold>{text}</DateSummaryTextBold>
            }
        </Box>
    )

}


const Root = styled(Box)(({ theme }) => ({
    padding: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
        maxWidth: theme.breakpoints.values.md,
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2)
    }
}));

const TicketName = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold'
}));

const QuantityField = styled(_TextField)(({ theme }) => ({
    width: 56
}));
QuantityField.defaultProps = {
    variant: 'outlined',
    size: 'small',
    inputProps: {
        style: { textAlign: 'center' }
    }
}

const AddIcon = styled(AddCircleOutlineIcon)(({ theme }) => ({
    color: theme.palette.success.main
}));

const RemoveIcon = styled(RemoveCircleOutlineIcon)(({ theme }) => ({
    color: theme.palette.error.main
}));

const TextField = styled(_TextField)(({ theme }) => ({
    marginBottom: theme.spacing(2)
}));
TextField.defaultProps = { fullWidth: true, variant: 'outlined' };

const PriceInput = styled(_TextField)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    width: '50%',
    [theme.breakpoints.down('xs')]: {
        width: '100%'
    }
}));
PriceInput.defaultProps = { variant: 'outlined', InputProps: { startAdornment: <InputAdornment position="start">$</InputAdornment> } };

const ButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
}));

const CancelButton = styled(Button)(({ theme }) => ({
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
    marginLeft: theme.spacing(2),
}));
CancelButton.defaultProps = { variant: 'outlined' }

const SubmitButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(2),
}));
SubmitButton.defaultProps = { variant: 'contained' }

const DeleteButton = styled(Button)(({ theme }) => ({
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    marginRight: 'auto'
}));
DeleteButton.defaultProps = { variant: 'contained' }

const DeleteButtonDisabled = styled(Button)(({ theme }) => ({
    color: theme.palette.grey[400],
    borderColor: theme.palette.grey[400],
    marginRight: 'auto',
    '&:hover': {
        cursor: 'default',
        backgroundColor: 'transparent'
    }
}));
DeleteButtonDisabled.defaultProps = { variant: 'contained', disableRipple: true }

const ErrorMessage = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main,
    marginTop: theme.spacing(2)
}));

const TotalText = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold'
}));

const SavingText = styled(Typography)(({ theme }) => ({
    color: theme.palette.success.dark
}));

const NegativeSavingText = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.dark
}));

const TotalCell = styled(TableCell)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: 20,
    paddingTop: theme.spacing(2),
    borderBottom: 0
}));

const SavingCell = styled(TableCell)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: 20,
    paddingTop: theme.spacing(2),
    borderBottom: 0,
    color: theme.palette.success.dark
}));

const NegativeSavingCell = styled(TableCell)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: 20,
    paddingTop: theme.spacing(2),
    borderBottom: 0,
    color: theme.palette.error.dark
}));

const DatePicker = styled(KeyboardDatePicker)(({ theme }) => ({
    width: '100%'
}));

const TimePicker = styled(KeyboardTimePicker)(({ theme }) => ({
    width: '100%'
}));

const StoreActiveIcon = styled(StoreIcon)(({ theme }) => ({
    color: theme.palette.success.main,
    marginRight: theme.spacing(1)
}));

const StoreInactiveIcon = styled(StoreIcon)(({ theme }) => ({
    color: theme.palette.grey.A200,
    marginRight: theme.spacing(1)
}));

const TicketHiddenIcon = styled(VisibilityOffIcon)(({ theme }) => ({
    color: theme.palette.grey.A200,
    marginRight: theme.spacing(1)
}));

const AccessCodeContainer = styled(Box)(({ theme }) => ({
    marginLeft: 32,
    display: 'flex',
    flexDirection: 'column'
}));

const AccessCodeInput = styled(_TextField)(({ theme }) => ({
    maxWidth: 400,
    marginTop: theme.spacing(1)
}));
AccessCodeInput.defaultProps = { size: 'small' };

const Bold = styled(Typography)(({ theme }) => ({
    display: 'inline',
    fontWeight: 'bold'
}));


const DateSummaryText = styled(Typography)(({ theme }) => ({
    color: theme.palette.info.dark
}));

const DateSummaryTextBold = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.palette.info.dark
}));

const DateSummaryTextError = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    color: theme.palette.error.main
}));