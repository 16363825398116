import React from 'react';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';

// Material UI
import { styled, Toolbar, Box, Drawer, Divider as _Divider } from '@material-ui/core';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import InfoIcon from '@material-ui/icons/Info';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ReceiptIcon from '@material-ui/icons/Receipt';
import BallotIcon from '@material-ui/icons/Ballot';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import StoreIcon from '@material-ui/icons/Store';
import ShareIcon from '@material-ui/icons/Share';
import { GetApp } from '@material-ui/icons';


/**
 * Desktop Only
 * See EventTabs.js for the mobile version of this menu
 */

export default function EventDrawer() {
    const history = useHistory();
    const { id }  = useParams();

    const path = '/events/' + id;

    const dashboard     = useRouteMatch({ path: '/events/:id/', exact: true })
    const details       = useRouteMatch({ path: '/events/:id/details'})
    const checkout      = useRouteMatch({ path: '/events/:id/checkout'})
    const checkoutForm  = useRouteMatch({ path: '/events/:id/checkout-form'})
    const tickets       = useRouteMatch({ path: '/events/:id/tickets'})
    const discounts     = useRouteMatch({ path: '/events/:id/discounts'})
    const publish       = useRouteMatch({ path: '/events/:id/publish'})
    const payouts       = useRouteMatch({ path: '/events/:id/payouts'})
    const orders        = useRouteMatch({ path: '/events/:id/orders'})
    const onsite        = useRouteMatch({ path: '/events/:id/onsite'})
    const registration  = useRouteMatch({ path: '/events/:id/registration'})
    const downloads     = useRouteMatch({ path: '/events/:id/downloads'})
    const promote       = useRouteMatch({ path: '/events/:id/promote'})

    return (
        <Box displayPrint='none'>

            <DesktopDrawer variant="permanent">

                <Toolbar />

                <DrawerContainer>

                    <List>

                        <MenuItem
                            title='Dashboard'
                            icon={<DashboardIcon />}
                            active={Boolean(dashboard)}
                            onClick={() => history.push(`${path}/`)}
                        />

                        <Divider />

                        <MenuItem
                            title='Event Details'
                            icon={<InfoIcon />}
                            active={Boolean(details)}
                            onClick={() => history.push(`${path}/details`)}
                        />

                        <MenuItem
                            title='Tickets'
                            icon={<ConfirmationNumberIcon />}
                            active={Boolean(tickets)}
                            onClick={() => history.push(`${path}/tickets`)}
                        />

                        <MenuItem
                            title='Discounts'
                            icon={<LocalOfferIcon />}
                            active={Boolean(discounts)}
                            onClick={() => history.push(`${path}/discounts`)}
                        />

                        <MenuItem
                            title='Customize Checkout'
                            icon={<ShoppingCartIcon />}
                            active={Boolean(checkout)}
                            onClick={() => history.push(`${path}/checkout`)}
                        />

                        <MenuItem
                            title='Checkout Form'
                            icon={<BallotIcon />}
                            active={Boolean(checkoutForm)}
                            onClick={() => history.push(`${path}/checkout-form`)}
                        />

                        <Divider />

                        <MenuItem
                            title='Review & Publish'
                            icon={<StoreIcon />}
                            active={Boolean(publish)}
                            onClick={() => history.push(`${path}/publish`)}
                        />

                        <Divider />

                        <MenuItem
                            title='Sales Summary'
                            icon={<AccountBalanceIcon />}
                            active={Boolean(payouts)}
                            onClick={() => history.push(`${path}/payouts`)}
                        />

                        <MenuItem
                            title='Online Orders'
                            icon={<ReceiptIcon />}
                            active={Boolean(orders)}
                            onClick={() => history.push(`${path}/orders`)}
                        />
                        
                        <MenuItem
                            title='In-Person Orders'
                            icon={<ReceiptIcon />}
                            active={Boolean(onsite)}
                            onClick={() => history.push(`${path}/onsite`)}
                        />

                        <MenuItem
                            title='Checkout Answers'
                            icon={<BallotIcon />}
                            active={Boolean(registration)}
                            onClick={() => history.push(`${path}/registration`)}
                        />

                        <MenuItem
                            title='Reports'
                            icon={<GetApp />}
                            active={Boolean(downloads)}
                            onClick={() => history.push(`${path}/downloads`)}
                        />

                        <Divider />

                        <MenuItem
                            title='Promote'
                            icon={<ShareIcon />}
                            active={Boolean(promote)}
                            onClick={() => history.push(`${path}/promote`)}
                        />

                    </List>

                </DrawerContainer>

            </DesktopDrawer>

        </Box>
    )
}


function MenuItem(props) {

    if (props.active) {
        return (
            <ListButton button>
                <ListItemIconActive>{props.icon}</ListItemIconActive>
                <ListItemTextActive primary={props.title} />
            </ListButton>
        )
    } else {
        return (
            <ListButton button onClick={props.onClick}>
                <ListItemIconInactive>{props.icon}</ListItemIconInactive>
                <ListItemTextInactive primary={props.title} />
            </ListButton>
        )
    }
}


const DrawerContainer = styled('div')(({ theme }) => ({
    overflow: 'auto',
    backgroundColor: theme.palette.background.light,
    height: '100%',
    width: '100%',
}));

const ListButton = styled(ListItem)(({ theme }) => ({
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(5),
    '&:hover': {
        backgroundColor: theme.palette.grey[700]
    }
}));

const ListItemIconActive = styled(ListItemIcon)(({ theme }) => ({
    color: theme.palette.primary.main
}));

const ListItemTextActive = styled(ListItemText)(({ theme }) => ({
    color: theme.palette.primary.main
}));

const ListItemIconInactive = styled(ListItemIcon)(({ theme }) => ({
    color: theme.palette.common.white
}));

const ListItemTextInactive = styled(ListItemText)(({ theme }) => ({
    color: theme.palette.common.white
}));

const DesktopDrawer = styled(Drawer)(({ theme }) => ({
    width: 280,
    flexShrink: 0
}));

const Divider = styled(_Divider)(({ theme }) => ({
    backgroundColor: theme.palette.grey[100],
}));