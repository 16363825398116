/**
 * Takes a number and abbreviates it when it is large enough
 * @param {number} num number to be formatted
 * @param {number} fixedDecimal number of decimal places on un-abbreviated numbers
 * @param {number} fixedAbbr number of decimal places to show when number is abbreviated
 * @returns {string} Formatted number. Returns '-1' if a valid number was not provided
 */
export const abbreviateNumber = (num, fixedDecimal, fixedAbbr) => {
	if (num === null || num === undefined) { return '-1' } // terminate early
	if (num === 0) { return '0' } // terminate early
	if (num < 0) { return '-1' } // terminate early

	let fixed = (!fixedDecimal || fixedDecimal < 0) ? 0 : fixedDecimal;

	if (typeof fixedAbbr === 'number' && num >= 1000) {
		fixed = fixedAbbr;
	}

	let b = (num).toPrecision(2).split("e"), // get power
		k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
		c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
		d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
		e = d.toFixed(0 + fixed) + ['', 'K', 'M', 'B', 'T'][k] // append power

	return e
}

// Add leading zeros to a number
export const padStart = (num, digits) => {
	let number = num.toString();
	if (number.length < digits) {
		for (let i = 0; i < (digits - num.toString.length); i++) {
			number = '0' + number;
		}
	}
	return number;
}

// Format prices
export const formatPrice = (num) => {
	return '$' + Number(num).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
}

// Format phone numbers
export const formatPhoneWithDashes = (phone) => {
	if (phone) {
		if (phone.length === 10) {
			return phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
		} else if (phone.length === 11) {
			return phone.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1-$2-$3-$4');
		} else {
			return phone;
		}
	} else {
		return '';
	}
}