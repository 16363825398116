import React, { useState, useEffect } from 'react';

// Material UI
import { styled, Grid, Typography, Hidden, Box, Paper } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CheckIcon from '@material-ui/icons/Check';
import GetAppIcon from '@material-ui/icons/GetApp';

// Components
import { Page } from 'components/ui/layout';
import { ControlBar, ControlBarHeader } from 'components/ui/layout';
import { ActionButton } from 'components/ui/buttons';

// Utility
import { useNotification } from 'context/notification';
import { downloadFile } from 'utility/browser';
import fetchExpresso from 'utility/fetchExpresso';

// Context
import { useAuth } from 'context/auth';


export default function Resources() {
    return (
        <Page>

            <Grid container spacing={4}>

                <Grid item xs={12}>
                    <ControlBar>
                        <ControlBarHeader>Organizer Resources</ControlBarHeader>
                    </ControlBar>
                    <Typography gutterBottom>
                        Welcome to your hub for essential resources designed to assist you and your volunteer staff in managing ticket scanning and in-person payments at your events. Whether you're new to event organizing or looking to streamline your processes, this page provides guides, practice materials, and tips to ensure a smooth experience for both your team and attendees.
                    </Typography>
                </Grid>
                
                <Grid item xs={12}>
                    <Resource
                        type='article'
                        title='GUIDE: Scanning Tickets & In-Person Payments'
                        description='This guide on our blog is designed to be shared with your volunteers and staff who will be working at your gates. It covers how to scan tickets and handle in-person payments, including tracking cash transactions. The guide includes short walkthrough videos, links to download both the Apple and Android versions of the app, and accompanying text for easy understanding.'
                        link='https://hub.frontdoor.plus/blog/master-the-gate-your-quick-guide-to-scanning-tickets-and-using-our-pos-devices'
                    />
                </Grid>

                <Grid item xs={12}>
                    <TestTicketDownloader />
                </Grid>

                <Grid item xs={12}>
                    <Resource
                        type='article'
                        title='GUIDE: Secure your Facebook Page against Scammers'
                        description='This blog guide provides simple adjustments to your Facebook Page settings to help limit spam and scam comments. Implementing these steps can enhance the security of your page and protect your followers.'
                        link='https://hub.frontdoor.plus/blog/secure-your-event-essential-facebook-settings-to-prevent-ticket-scams'
                    />
                </Grid>
                
                <Grid item xs={12}>
                    <Resource
                        type='video'
                        title='GUIDE: Protect your Facebook Followers from Scams'
                        description='This short YouTube video is directed at your Facebook followers, offering them tips on how to identify and avoid potential ticket scams in the comments on your posts.'
                        link=' https://www.youtube.com/watch?v=I9Lm76aD0ZY'
                    />
                </Grid>

            </Grid>

        </Page>
    )
}


function Resource(props) {
    const { type, title, description, link } = props;

    const [copied, setCopied] = useState(false);

    const { notify } = useNotification();

    const copyLink = () => {
        navigator.clipboard.writeText(link)
            .then(() => {
                setCopied(true);
                notify.info('Copied to clipboard!')
            })
            .catch(() => notify.error('Unable to copy to clipboard'))
    }

    return (
        <ResourceCard>
            <ResourceTitle>{title}</ResourceTitle>
            <ResourceBody>
                {typeof description === 'string' && (
                    <Typography>{description}</Typography>
                )}
                {typeof description === 'object' && description}
            </ResourceBody>
            <ResourceFooter>
                <Hidden smUp>
                    <Grid container spacing={1}>
                        <Grid xs={6} item>
                            <ActionButton fullWidth startIcon={<LaunchIcon />} href={link} target='_blank' rel='noopener noreferrer'>Read Article</ActionButton>
                        </Grid>
                        <Grid xs={6} item>
                            <ActionButton fullWidth startIcon={copied ? <CheckIcon /> : <FileCopyIcon />} onClick={copyLink}>Copy Link</ActionButton>
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden xsDown>
                    <ActionButton startIcon={<LaunchIcon />} href={link} target='_blank' rel='noopener noreferrer'>
                        {type === 'article' && 'Read Article'}
                        {type === 'video' && 'Watch Video'}
                    </ActionButton>
                    <Box width={8} />
                    <ActionButton startIcon={copied ? <CheckIcon /> : <FileCopyIcon />} onClick={copyLink}>Copy Link</ActionButton>
                </Hidden>
            </ResourceFooter>
        </ResourceCard>
    )
}


function TestTicketDownloader() {
    const [loading, setLoading] = useState(false);
    const [pin, setPin] = useState(null);

    const { notify } = useNotification();
    
    const { auth } = useAuth();
    const hostId = auth.hostId;


    // Get Login PIN for FD+ Scanner/POS apps
    useEffect(() => {
        fetchExpresso(`/apiv2/hosts/${hostId}/scanner_pin`)
            .then(async (res) => {
                if (res.status === 200) {
                    const data = await res.json();
                    setPin(data.scannerPin);
                }
            })
            .catch(() => null)
    }, [hostId]);


    const handleDownload = () => {
        setLoading(true);

        fetchExpresso(`/apiv2/hosts/${hostId}/resources/test-tickets`)
            .then(async res => {
                if (res.status === 200) {
                    const pdf = await res.text();
                    await downloadFile(`test-tickets`, pdf);
                    setLoading(false);
                } else {
                    throw new Error();
                }
            })
            .catch(() => {
                notify.error('Unable to download tickets');
                setLoading(false);
            });
    }


    return (
        <ResourceCard>
            <ResourceTitle>PRACTICE: Test Tickets for Scanning Training</ResourceTitle>
            <ResourceBody>

                <Typography>This PDF file contains test tickets that your volunteers and staff can use during training and practice sessions. These are not real tickets and can be scanned an unlimited number of times.</Typography>

                <Box mt={2}>
                    <Typography><u>Included:</u></Typography>
                </Box>

                <Typography component='ul'>
                    <Typography component={'li'}>Test Ticket that always scans as “Successful”</Typography>
                    <Typography component={'li'}>Test Ticket that always scans as “Failed”</Typography>
                </Typography>

                <Box mt={2}>
                    <Typography><u>How To Use:</u></Typography>
                </Box>

                <Typography component={'ul'}>
                    <Typography component='li'>Download the FrontDoor+ | Guest Check-In app:</Typography>
                    <Typography component='ul'>
                        <Typography component='li'>Apple Devices: <Link href='https://tinyurl.com/AppleAppFD' target="_blank" rel='noopener noreferrer'>https://tinyurl.com/AppleAppFD</Link></Typography>
	                    <Typography component='li'>Android Devices: <Link href='https://tinyurl.com/GoogleAppFD' target="_blank" rel='noopener noreferrer'>https://tinyurl.com/GoogleAppFD</Link></Typography>
                    </Typography>
                    {typeof pin === 'string'
                        ? <Typography component='li'>Log in to the app using the PIN: <b>{pin}</b></Typography>
                        : <Typography component='li'>Log in to the app using your PIN</Typography>
                    }
	                <Typography component='li'>Scan the Test Tickets</Typography>
                    <Typography component={'ul'}>
	                    <Typography component='li'>With the test tickets either printed or displayed on a screen, use the scanner app to scan them.</Typography>
	                    <Typography component='li'>The test tickets can be scanned unlimited times and will not affect your event statistics.</Typography>
                    </Typography>
                </Typography>

            </ResourceBody>
            <ResourceFooter>
                <Hidden smUp>
                    <Grid container spacing={1} justifyContent='flex-end'>
                        <Grid xs={6} item>
                            <ActionButton disabled={loading} onClick={handleDownload} startIcon={<GetAppIcon />}>{loading ? 'Downloading...' : 'Download Tickets'}</ActionButton>
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden xsDown>
                    <ActionButton disabled={loading} onClick={handleDownload} startIcon={<GetAppIcon />}>{loading ? 'Downloading...' : 'Download Tickets'}</ActionButton>
                </Hidden>
            </ResourceFooter>
        </ResourceCard>
    )
}


const ResourceCard = styled(Paper)(({ theme }) => ({
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
}));

const ResourceTitle = styled(Typography)(({ theme }) => ({
    margin: theme.spacing(2),
    fontWeight: 'bold',
    display: 'inline'
}));
ResourceTitle.defaultProps = { variant: 'h6' }

const ResourceBody = styled(Box)(({ theme }) => ({
    margin: theme.spacing(2),
    flex: 1
}));

const ResourceFooter = styled(Box)(({ theme }) => ({
    margin: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end'
}));

const Link = styled('a')(({ theme }) => ({
    color: theme.palette.info.dark,
    display: 'inline'
}));