import React, { useState, useEffect } from 'react';

import { styled, Typography, CircularProgress, Box } from '@material-ui/core';
import { Card, CardHeader, CardContent, CardActions } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import CommentList from 'components/orders/CommentList';
import ConfirmAction from 'components/popups/ConfirmAction';
import { ActionButton } from 'components/ui/buttons';

import fetchExpresso from 'utility/fetchExpresso';
import TextareaDialog from 'components/popups/TextareaDialog';
import { useNotification } from 'context/notification';


export default function Comments({ tranId }) {
    const [comments, setComments] = useState({
        values: [],
        loading: true,
        error: false,
        refresh: 0
    });

    const [createDialog, setCreateDialog] = useState({
        open: false,
        loading: false
    });

    const [editDialog, setEditDialog] = useState({
        open: false,
        loading: false,
        id: null, // Comment to edit
        comment: null // Comment to edit
    });

    const [deleteDialog, setDeleteDialog] = useState({
        open: false,
        id: null
    });

    
    const { notify } = useNotification();


    useEffect(() => {
        fetchExpresso(`/apiv2/orders/${tranId}/comments`)
            .then(async res => {
                if (res.status !== 200) throw new Error();
                const comments = await res.json();

                setComments(n => ({ ...n, values: comments, loading: false, error: false }));
            })
            .catch(e => {
                setComments(n => ({ ...n, values: [], loading: false, error: true }));
            })
    }, [tranId, comments.refresh]);


    const handleCommentCreate = (comment) => {
        setCreateDialog(n => ({ ...n, loading: true }));

        fetchExpresso(`/apiv2/orders/${tranId}/comments`, {
            method: 'POST',
            body: { comment: comment }
        })
            .then(res => {
                if (res.status === 200) {
                    // Comment created successfully
                    setCreateDialog((n) => ({ ...n, open: false, loading: false }));
                    setComments(s => ({ ...s, refresh: s.refresh + 1 }));
                } else {
                    throw new Error();
                }
            })
            .catch(e => {
                // Unable to create comment
                setCreateDialog((n) => ({ ...n, loading: false }));
                notify.error('Unable to save comment');
            })
    };

    const openEditDialog = (id) => {
        const comment = comments.values.find(c => c.comment_id === id).comment;
        setEditDialog(state => ({ ...state, open: true, id, comment }));
    };

    const handleCommentEdit = (comment) => {
        setEditDialog(n => ({ ...n, loading: true }));

        fetchExpresso(`/apiv2/orders/${tranId}/comments/${editDialog.id}`, {
            method: 'PUT',
            body: { comment: comment }
        })
            .then(res => {
                if (res.status === 200) {
                    // Comment updated successfully
                    setEditDialog((n) => ({ ...n, open: false, loading: false, id: null, comment: null }));
                    setComments(s => ({ ...s, refresh: s.refresh + 1 }));
                } else {
                    throw new Error();
                }
            })
            .catch(e => {
                // Unable to update comment
                setEditDialog((n) => ({ ...n, loading: false }));
                notify.error('Unable to update comment');
            })
    };


    const openDeleteDialog = (id) => {
        setDeleteDialog(state => ({ ...state, open: true, id: id }));
    };

    const handleCommentDelete = () => {
        setDeleteDialog(state => ({ ...state, open: false }));

        fetchExpresso(`/apiv2/orders/${tranId}/comments/${deleteDialog.id}`, { method: 'DELETE' })
            .then(res => {
                if (res.status === 200) {
                    // Comment deleted - refresh list
                    setComments(s => ({ ...s, refresh: s.refresh + 1 }));
                    notify.success('Comment deleted');
                } else {
                    throw new Error();
                }
            })
            .catch(e => {
                notify.error('Unable to delete comment');
            })
    };



    if (comments.loading) {
        return (
            <CommentsCard>
                <CardHeader title='Comments' />
                <CardContent>
                    <CircularProgress />
                </CardContent>
            </CommentsCard>    
        )
    }
    
    if (comments.error) {
        return (
            <CommentsCard>
                <CardHeader title='Comments' />
                <CardContent>
                    <Typography>Unable to find comments</Typography>
                </CardContent>
            </CommentsCard>    
        )
    }


    return (
        <>
            <CommentsCard>

                <CardHeader title='Comments' />

                <CardContent>
                    <CommentList
                        comments={comments.values}
                        onEdit={openEditDialog}
                        onDelete={openDeleteDialog}
                    />
                </CardContent>

                <CardActions>
                    <ActionButton startIcon={<EditIcon fontSize='small' />} onClick={() => setCreateDialog((n) => ({ ...n, open: true }))}>Add</ActionButton>
                </CardActions>

                {createDialog.open === true && (
                    <TextareaDialog
                        open
                        title='Add Comment'
                        disabled={createDialog.loading}
                        onConfirm={handleCommentCreate}
                        onClose={() => setCreateDialog((n) => ({ ...n, open: false }))}
                    />
                )}

                {editDialog.open === true && (
                    <TextareaDialog
                        open
                        title='Edit Comment'
                        defaultValue={editDialog.comment}
                        disabled={editDialog.loading}
                        onConfirm={handleCommentEdit}
                        onClose={() => setEditDialog((n) => ({ ...n, open: false, id: null, comment: null }))}
                    />
                )}
                
                {deleteDialog.open === true && (
                    <ConfirmAction
                        open
                        destructive
                        variant='destructive'
                        title='Delete Comment'
                        description='Are you sure you want to delete this comment? This action cannot be undone.'
                        confirmText='Delete'
                        onConfirm={handleCommentDelete}
                        onCancel={() => setDeleteDialog({ open: false })}
                    />
                )}

            </CommentsCard>

            <Box mt={1} ml={1}>
                <Typography variant='caption'>* Only you and the FrontDoor+ staff can see these comments</Typography>
            </Box>
        </>
    );
}


const CommentsCard = styled(Card)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        width: 420
    }
}));