import React, { useState } from 'react';

// Material UI
import { styled, Box, Button, TextField, Grid, Typography, Hidden } from '@material-ui/core';
import { Card, CardHeader, CardContent, CardActions } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import LaunchIcon from '@material-ui/icons/Launch';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import GetAppIcon from '@material-ui/icons/GetApp';

// Components
import { Page } from 'components/ui/layout';
import SocialMediaLinks from 'components/widgets/SocialMediaLinks';

// Utility
import { useAuth } from 'context/auth';
import { useNotification } from 'context/notification';
import fetchExpresso from 'utility/fetchExpresso';
import { downloadFile } from 'utility/browser';


export default function Promote(props) {

    const [hostCopied, setHostCopied] = useState(false);
    const [eventCopied, setEventCopied] = useState(false);

    const [hostQrDownloading, setHostQrDownloading] = useState(false);
    const [eventQrDownloading, setEventQrDownloading] = useState(false);

    const { auth } = useAuth();
    const { notify } = useNotification();

    const hostId = auth.hostId;
    const eventId = props.match.params.id;


    // Generate Storefront Link
    const hostLink = `${process.env.REACT_APP_STORE_URL}/host/${hostId}`;
    const eventLink = `${process.env.REACT_APP_STORE_URL}/event/${eventId}`;




    const copyHost = async () => {
        setHostCopied(true);
        setEventCopied(false);
        try {
            await navigator.clipboard.writeText(hostLink);
        } catch(e) {
            notify.error('Unable to copy to clipboard')
        }
    }

    const copyEvent = async () => {
        setEventCopied(true);
        setHostCopied(false);
        try {
            await navigator.clipboard.writeText(eventLink);
        } catch(e) {
            notify.error('Unable to copy to clipboard')
        }
    }

    const downloadHostQR = async () => {
        try {
            if (hostQrDownloading) return;
            setHostQrDownloading(true);

            const res = await fetchExpresso(`/apiv2/hosts/${hostId}/qr-code`);
            
            if (res.status === 200) {
                const file = await res.text();
                downloadFile(`qr-code-all-events-${hostId}.png`, file);
            } else {
                throw new Error();
            }
            setHostQrDownloading(false);
        } catch(e) {
            notify.error('Unable to download qr code');
            setHostQrDownloading(false);
        }
    }

    const downloadEventQR = async () => {
        try {
            if (eventQrDownloading) return;
            setEventQrDownloading(true);

            const res = await fetchExpresso(`/apiv2/events/${eventId}/qr-code`);
            
            if (res.status === 200) {
                const file = await res.text();
                downloadFile(`qr-code-event-${eventId}.png`, file);
            } else {
                throw new Error();
            }
            setEventQrDownloading(false);
        } catch(e) {
            notify.error('Unable to download qr code');
            setEventQrDownloading(false);
        }
    }


    return (
        <Page>

            <Grid container spacing={4} justifyContent='center'>

                <Grid item xs={12} lg={8}>
                    <Alert severity='info'>
                        The links and QR codes below are great to use on your website, Facebook, Instagram, posters and other marketing materials.
                    </Alert>
                </Grid>
                
                <Grid item xs={12} lg={8}>
                    <Card>
                        <CardHeader title='"All Events" Link' />
                        <CardContent>
                            <Typography>This link directs the public to a list of all events you currently have on sale.  Note: Events set as Unlisted are not included in the list.</Typography>
                            <Box mt={4}>
                                <LinkContainer
                                    helperText={hostCopied ? 'Copied to clipboard' : ' '}
                                    value={hostLink}
                                />
                            </Box>
                        </CardContent>
                        <CardActions>
                            <Hidden smUp>
                                <Grid container spacing={1}>
                                    <Grid xs={6} item>
                                        <LinkButton fullWidth startIcon={<LaunchIcon />} href={hostLink} target='_blank' rel='noopener noreferrer'>Visit Link</LinkButton>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <LinkButton fullWidth startIcon={<FileCopyIcon />} onClick={copyHost}>Copy Link</LinkButton>
                                    </Grid>
                                    <Grid xs={12} item>
                                        <LinkButton fullWidth startIcon={<GetAppIcon />} onClick={downloadHostQR} disabled={hostQrDownloading}>Download QR Code</LinkButton>
                                    </Grid>
                                </Grid>
                            </Hidden>
                            <Hidden xsDown>
                                <LinkButton startIcon={<LaunchIcon />} href={hostLink} target='_blank' rel='noopener noreferrer'>Visit Link</LinkButton>
                                <LinkButton startIcon={<FileCopyIcon />} onClick={copyHost}>Copy Link</LinkButton>
                                <LinkButton startIcon={<GetAppIcon />} onClick={downloadHostQR} disabled={hostQrDownloading}>Download QR Code</LinkButton>
                            </Hidden>
                        </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={12} lg={8}>
                    <Card>
                        <CardHeader title='"This Event" Link' />
                        <CardContent>
                            <Typography>This link directs the public to the Purchase Page for this specific event.</Typography>
                            <Box mt={4}>
                                <LinkContainer
                                    helperText={eventCopied ? 'Copied to clipboard' : ' '}
                                    value={eventLink}
                                />
                            </Box>
                        </CardContent>
                        <CardActions>
                            <Hidden smUp>
                                <Grid container spacing={1}>
                                    <Grid xs={6} item>
                                        <LinkButton fullWidth startIcon={<LaunchIcon />} href={eventLink} target='_blank' rel='noopener noreferrer'>Visit Link</LinkButton>
                                    </Grid>
                                    <Grid xs={6} item>
                                        <LinkButton fullWidth startIcon={<FileCopyIcon />} onClick={copyEvent}>Copy Link</LinkButton>
                                    </Grid>
                                    <Grid xs={12} item>
                                        <LinkButton fullWidth startIcon={<GetAppIcon />} onClick={downloadEventQR} disabled={eventQrDownloading}>Download QR Code</LinkButton>
                                    </Grid>
                                </Grid>
                            </Hidden>
                            <Hidden xsDown>
                                <LinkButton startIcon={<LaunchIcon />} href={eventLink} target='_blank' rel='noopener noreferrer'>Visit Link</LinkButton>
                                <LinkButton startIcon={<FileCopyIcon />} onClick={copyEvent}>Copy Link</LinkButton>
                                <LinkButton startIcon={<GetAppIcon />} onClick={downloadEventQR} disabled={eventQrDownloading}>Download QR Code</LinkButton>
                            </Hidden>
                        </CardActions>
                    </Card>
                </Grid>
            
                <Grid item xs={12} lg={8}>
                    <SocialMediaLinks hostId={auth.hostId} />
                </Grid>

            </Grid>

        </Page>
    )
}

const LinkContainer = styled(TextField)(({ theme }) => ({
    borderColor: theme.palette.secondary.main,
    marginHorizontal: theme.spacing(2),
}));
LinkContainer.defaultProps = {
    variant: 'outlined',
    fullWidth: true,
    onFocus: (e) => e.target.select()
}

const LinkButton = styled(Button)(({ theme }) => ({}));
LinkButton.defaultProps = { variant: 'outlined', color: 'secondary' };