import React, { useState, useEffect } from 'react';
import { styled, Box, CircularProgress, Typography, Button, TextField, FormControlLabel, Switch, Grid } from '@material-ui/core';

import { TextArea } from 'components/ui/inputs';
import { Page } from 'components/ui/layout';
import FormSubmitBar from 'components/forms/FormSubmitBar';
import { Section, Header, Content } from 'components/ui/forms';

import { useNotification } from 'context/notification';
import { useAuth } from 'context/auth';
import { useTextInput } from 'hooks/forms';
import fetchExpresso from 'utility/fetchExpresso';
import { validateURL } from 'utility/validation';



export default function Checkout(props) {
    const eventId = props.match.params.id;

    const { auth } = useAuth();
    const hostId = auth.hostId;

    const [status, setStatus] = useState('loading');
    const [updating, setUpdating] = useState(false);

    const [defaultValues, setDefaultValues] = useState(null);

    const [unsavedChanges, setUnsavedChanges] = useState(false);

    const { notify } = useNotification();


    useEffect(() => {
        fetchExpresso(`/apiv2/events/${eventId}?fields=fine_print,cta_desc,cta_btn_text,cta_btn_url,host_note,meta_pixel_id`)
            .then(res => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    throw new Error();
                }
            })
            .then(data => {
                setStatus('success');
                setDefaultValues({
                    fpEnabled:        Boolean(data.fine_print),
                    finePrint:        data.fine_print || '',
                    ctaEnabled:       Boolean(data.cta_btn_url),
                    ctaDesc:          data.cta_desc || '',
                    ctaBtnText:       data.cta_btn_text || '',
                    ctaBtnUrl:        data.cta_btn_url || '',
                    hostNoteEnabled:  Boolean(data.host_note),
                    hostNote:         data.host_note || '',
                    metaPixelEnabled: Boolean(data.meta_pixel_id),
                    metaPixel:        data.meta_pixel_id || '',
                })
                setUnsavedChanges(false);
            })
            .catch(() => setStatus('error'));
    }, [eventId]);

    
    const handleEdit = (form) => {
        if (updating) return;
        setUpdating(true);

        fetchExpresso(`/apiv2/events/${eventId}`, {
            method: 'PUT',
            body: {
                finePrint:      form.finePrint,
                ctaDescription: form.ctaDesc,
                ctaButtonText:  form.ctaBtnText,
                ctaButtonURL:   form.ctaBtnUrl,
                hostNote:       form.hostNote,
                metaPixel:      form.metaPixel,
                hostId:         hostId
            }
        })
            .then(res => {
                if (res.status === 200) {
                    notify.success('Event Updated');
                    setDefaultValues({
                        fpEnabled:        Boolean(form.finePrint),
                        finePrint:        form.finePrint || '',
                        ctaEnabled:       Boolean(form.ctaBtnUrl),
                        ctaDesc:          form.ctaDesc || '',
                        ctaBtnText:       form.ctaBtnText || '',
                        ctaBtnUrl:        form.ctaBtnUrl || '',
                        hostNoteEnabled:  Boolean(form.hostNote),
                        hostNote:         form.hostNote || '',
                        metaPixelEnabled: Boolean(form.metaPixel),
                        metaPixel:        form.metaPixel || '',
                    })
                    setUnsavedChanges(false);
                } else {
                    notify.error('There was a problem updating your event');
                }
            })
            .catch(() => notify.error('There was a problem updating your event'))
            .finally(() => setUpdating(false))
    }

    // Waiting for default values to load
    if (status === 'loading' || defaultValues === null ) {
        return (
            <Box display='flex' justifyContent='center' alignItems='center' width={1} height={1}>
                <CircularProgress />
            </Box>
        )
    }

    if (status === 'error') {
        return (
            <Page>
                <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' mt={12}>
                    <Typography variant='h5'>There was a problem finding your event</Typography>
                    <Box height={32} />
                    <Button variant='outlined' onClick={() => props.history.push('/events')}>Back to Events</Button>
                </Box>
            </Page>
        )
    }


    return (
        <Page width='md'>

            <CheckoutForm
                defaultValues={defaultValues}
                unsavedChanges={unsavedChanges}
                updating={updating}
                onUnsavedStateChange={(value) => setUnsavedChanges(value)}
                onSubmit={handleEdit}
            />

        </Page>
    )
}



function CheckoutForm(props) {
    const { defaultValues, unsavedChanges, updating, onUnsavedStateChange, onSubmit } = props;

    const [fpEnabled,        setFpEnabled]        = useState(defaultValues.fpEnabled);
    const [ctaEnabled,       setCtaEnabled]       = useState(defaultValues.ctaEnabled);
    const [hostNoteEnabled,  setHostNoteEnabled]  = useState(defaultValues.hostNoteEnabled);
    const [metaPixelEnabled, setMetaPixelEnabled] = useState(defaultValues.metaPixelEnabled);

    const finePrint   = useTextInput({ defaultValue: defaultValues.finePrint,  disabled: !defaultValues.fpEnabled  });
    const ctaDesc     = useTextInput({ defaultValue: defaultValues.ctaDesc,    disabled: !defaultValues.ctaEnabled });
    const ctaBtnText  = useTextInput({ defaultValue: defaultValues.ctaBtnText, disabled: !defaultValues.ctaEnabled });
    const ctaBtnUrl   = useTextInput({ defaultValue: defaultValues.ctaBtnUrl,  disabled: !defaultValues.ctaEnabled });
    const hostNote    = useTextInput({ defaultValue: defaultValues.hostNote,   disabled: !defaultValues.hostNoteEnabled  });
    const metaPixel   = useTextInput({ defaultValue: defaultValues.metaPixel,  disabled: !defaultValues.metaPixelEnabled, format: 'numeric'  });


    const handleFpToggle = (e) => {
        setFpEnabled(e.target.checked);
        finePrint.setDisabled(!e.target.checked);
    };

    const handleCtaToggle = (e) => {
        setCtaEnabled(e.target.checked);
        ctaDesc.setDisabled(!e.target.checked);
        ctaBtnText.setDisabled(!e.target.checked);
        ctaBtnUrl.setDisabled(!e.target.checked);
    };

    const handleHostNoteToggle = (e) => {
        setHostNoteEnabled(e.target.checked);
        hostNote.setDisabled(!e.target.checked);
    };

    const handleMetaPixelToggle = (e) => {
        setMetaPixelEnabled(e.target.checked);
        metaPixel.setDisabled(!e.target.checked);
    };

    // Compare current/default form values; The save + reset buttons are activated when unsaved changes exist
    useEffect(() => {
        if (fpEnabled         === defaultValues.fpEnabled       &&
            ctaEnabled        === defaultValues.ctaEnabled      &&
            hostNoteEnabled   === defaultValues.hostNoteEnabled &&
            finePrint.value   === defaultValues.finePrint       &&
            ctaDesc.value     === defaultValues.ctaDesc         &&
            ctaBtnText.value  === defaultValues.ctaBtnText      &&
            ctaBtnUrl.value   === defaultValues.ctaBtnUrl       &&
            hostNote.value    === defaultValues.hostNote        &&
            metaPixel.value   === defaultValues.metaPixel
        ) {
            // Form values have not changed
            if (unsavedChanges === true) {
                onUnsavedStateChange(false)
            }

        } else {
            // Form values have changed
            if (unsavedChanges === false) {
                onUnsavedStateChange(true)
            }
        }
    });


    const handleReset = () => {
        setFpEnabled(defaultValues.fpEnabled);
        setCtaEnabled(defaultValues.ctaEnabled);
        setHostNoteEnabled(defaultValues.hostNoteEnabled);
        setMetaPixelEnabled(defaultValues.metaPixelEnabled);
        
        finePrint.setDisabled(!defaultValues.fpEnabled);
        hostNote.setDisabled(!defaultValues.hostNoteEnabled)
        ctaDesc.setDisabled(!defaultValues.ctaEnabled);
        ctaBtnText.setDisabled(!defaultValues.ctaEnabled);
        ctaBtnUrl.setDisabled(!defaultValues.ctaEnabled);
        metaPixel.setDisabled(!defaultValues.metaPixelEnabled);


        finePrint.setValue(defaultValues.finePrint);
        ctaDesc.setValue(defaultValues.ctaDesc);
        ctaBtnText.setValue(defaultValues.ctaBtnText);
        ctaBtnUrl.setValue(defaultValues.ctaBtnUrl);
        hostNote.setValue(defaultValues.hostNote);
        metaPixel.setValue(defaultValues.metaPixel);
    };


    const handleSubmit = () => {
        let formError = false;

        if (fpEnabled) {
            if (finePrint.value.trim() === '') {
                finePrint.setError('This field cannot be blank');
                formError = true;
            }
        }

        if (ctaEnabled) {
            if (ctaDesc.value.trim() === '')    { ctaDesc.setError('This field cannot be blank');    formError = true; }
            if (ctaBtnText.value.trim() === '') { ctaBtnText.setError('This field cannot be blank'); formError = true; }
            
            if (ctaBtnUrl.value.trim() === '') {
                ctaBtnUrl.setError('This field cannot be blank'); 
                formError = true;
            } else if (validateURL(ctaBtnUrl.value.trim()) === false) {
                ctaBtnUrl.setError('Make sure your link is properly formatted. Example: https://example.com'); 
                formError = true;
            }
        }

        if (hostNoteEnabled) {
            if (hostNote.value.trim() === '') {
                hostNote.setError('This field cannot be blank');
                formError = true;
            }
        }
        
        if (metaPixelEnabled) {
            if (metaPixel.value === '') {
                metaPixel.setError('This field cannot be blank');
                formError = true;
            }
            else if (metaPixel.value.length < 15) {
                metaPixel.setError('Meta Pixels should be between 15 and 16 characters');
                formError = true;
            }
        }

        if (formError) return;

        onSubmit({
            finePrint:   finePrint.value.trim()  || null,
            ctaDesc:     ctaDesc.value.trim()    || null,
            ctaBtnText:  ctaBtnText.value.trim() || null,
            ctaBtnUrl:   ctaBtnUrl.value.trim()  || null,
            hostNote:    hostNote.value.trim()   || null,
            metaPixel:   metaPixel.value.trim()  || null,
        })
    };


    return (
        <FormContainer>

            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Section>

                        <Header>Fine Print</Header>

                        <Typography>You can include custom fine print, such as terms and conditions or important notes, at the bottom of the ticket checkout screen. Just type your message below (up to 2,000 characters).</Typography>

                        <Content>

                            <Box width='100%' mb={2}>
                                <FormControlLabel
                                    label="Add fine print"
                                    control={<Switch checked={fpEnabled} onChange={handleFpToggle} />}
                                />
                            </Box>

                            <SdTextArea
                                label='Fine Print'
                                maxLength={2000}
                                minRows={6}
                                maxRows={6}
                                {...finePrint.formProps}
                            />

                        </Content>

                    </Section>
                </Grid>

                <Grid item xs={12}>
                    <Section>

                        <Header>After Checkout Button</Header>

                        <Typography>Would you like to guide your attendees to a specific website once they've finished buying their ticket? You can easily add a personalized button to the confirmation screen by entering the details below.</Typography>

                        <Content>

                            <Box width='100%' mb={2}>
                                <FormControlLabel
                                    label="Add button"
                                    control={<Switch checked={ctaEnabled} onChange={handleCtaToggle} />}
                                />
                            </Box>

                            <SdTextField
                                {...ctaDesc.formProps}
                                label='Short Description'
                                placeholder='Ex. Ask customer to take a survey'
                            />

                            <SdTextField
                                {...ctaBtnText.formProps}
                                label='Button Label'
                                inputProps={{ maxLength: 25 }}
                            />

                            <SdTextField
                                {...ctaBtnUrl.formProps}
                                label='Button URL'
                            />

                        </Content>

                    </Section>
                </Grid>

                <Grid item xs={12}>
                    <Section>

                        <Header>Personalized Ticket Delivery Email</Header>

                        <Typography>Enable this option to add a personalized message in the email that delivers tickets to your customers.</Typography>

                        <Content>

                            <Box width='100%' mb={2}>
                                <FormControlLabel
                                    label="Add message"
                                    control={<Switch checked={hostNoteEnabled} onChange={handleHostNoteToggle} />}
                                />
                            </Box>

                            <SdTextArea
                                maxLength={2000}
                                label='Personalized Message'
                                minRows={6}
                                maxRows={6}
                                {...hostNote.formProps}
                            />

                        </Content>

                    </Section>
                </Grid>

                <Grid item xs={12}>
                    <Section>

                        <Header>Tracking and Analytics</Header>

                        <Typography>We support adding a Meta Pixel to your event page. Other tracking and analytics solutions are on the way.</Typography>

                        <Content>

                            <Box width='100%' mb={2}>
                                <FormControlLabel
                                    label="Add Meta Pixel"
                                    control={<Switch checked={metaPixelEnabled} onChange={handleMetaPixelToggle} />}
                                />
                            </Box>

                            <SdTextField
                                {...metaPixel.formProps}
                                label='Meta Pixel ID'
                                placeholder='Your 15-16 digit code'
                                inputProps={{ maxLength: 16 }}
                            />

                        </Content>

                    </Section>
                </Grid>
            </Grid>


            <FormSubmitBar
                unsavedChanges={unsavedChanges}
                loading={updating}
                onCancel={handleReset}
                onSubmit={handleSubmit}
            />

        </FormContainer>

    )
}


const FormContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(4),
        paddingBottom: theme.spacing(8)
    }
}));

const SdTextField = styled(TextField)({
    marginBottom: '24px',
    width: '100%'
})
SdTextField.defaultProps = { variant: 'outlined' };

const SdTextArea = styled(TextArea)({
    marginBottom: '24px',
    width: '100%'
})